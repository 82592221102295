import React, { useCallback, useState, useEffect } from "react"
import {
    Box,
    Button,
    Flex,
    HStack,
    Tooltip,
    useDisclosure
} from "@chakra-ui/react"
import { BiBulb, BiLoaderAlt, BiUserCircle, BiStar,BiReplyAll, BiPrinter } from "react-icons/bi"
import ChatReassign from "./chatReassign"
import { IHeaderPropsType } from "../types/liveChatTypes"
export const Header = ({
    userFullName,
    name,
    incrementer,
    loadTranscript,
    agentConversationId,
    handleDisconnect,
    handleLoadTranscript,
    refetchTranscript,
    t,
    permissions,
    loginUser,
    handleChatAssigment,
    chatUserConversationId,
    onlyHistory,
    starRating,
    userIncomingChatRequestId,
    botUserSessionId,
    currentAgentId,
    onPrint,
    isLoading,
    agentId,
    onShowConversation,
    transcriptLoaded
}: IHeaderPropsType) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const handleChatReassign = () => {
        onOpen()
    }
    const [isDisconnecting, setIsDisconnecting] = useState(false)
    const [isLoadingTranscript, setIsLoadingTranscript]=useState(false)
    const starRatings: Array<number> = [1, 2, 3, 4, 5]
    const chatLabel = agentConversationId
        ? "Chat Reassignment"
        : "Chat Assignment"
    const disconnectChat = useCallback(() => {
        setIsDisconnecting(true)
        handleDisconnect()
    }, [handleDisconnect])

    const onLoadTranscript = useCallback(() => {
      setIsLoadingTranscript(true)
    //   refetchTranscript()
      
      setTimeout(() => {
            handleLoadTranscript(agentConversationId)
            setIsLoadingTranscript(false)
      }, 1000)
    }, [handleLoadTranscript, agentConversationId,refetchTranscript])
    
    useEffect(() => {
        isDisconnecting &&
            setTimeout(() => {
                setIsDisconnecting(false)
            }, 2000)
    }, [isDisconnecting])
    return (
        <Flex
            className="chat-header"
            justifyContent="space-between"
            p={1}
            alignItems="center"
            mb={0}
            borderBottom="1px solid #f4f4f4"
        >
            <HStack
                spacing="3px"
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box className="bot-name">
                    
                    <Button
                        bg="transparent"
                        size="xs"
                        leftIcon={<BiUserCircle fontSize="16px" />}
                    >
                         
                        {name && name != "Anonymous user"
                            ? name
                            : t("Chat session") + `#${incrementer}`}
                    </Button>
                </Box>
                {loadTranscript && (
                    <Box>
                        <Button
                            leftIcon={<BiLoaderAlt fontSize="14px" />}
                            size="xs"
                            variant="solid"
                            colorScheme="teal"
                            onClick={handleLoadTranscript}
                        >
                            Load Transcript
                        </Button>
                    </Box>
                )}
                {permissions?.reasignChat && !onlyHistory && (
                    <Box>
                        <Tooltip hasArrow label={chatLabel} bg="teal">
                            <Button
                                leftIcon={<BiUserCircle fontSize="14px" />}
                                size="xs"
                                variant="solid"
                                colorScheme="teal"
                                onClick={handleChatReassign}
                            >
                                {chatLabel}
                            </Button>
                        </Tooltip>
                    </Box>
                )}
                {agentConversationId && !permissions?.reasignChat && (
                    <Box className="restart-icon live-chat">
                        <Button
                            leftIcon={<BiBulb fontSize="14px" color="white" />}
                            m={1}
                            size="xs"
                            fontSize="12"
                            colorScheme="teal"
                            onClick={disconnectChat}
                            variant="solid"
                            isLoading={isDisconnecting}
                            loadingText="disconnecting..."
                            spinnerPlacement="start"
                        >
                            Disconnect
                        </Button>

                        <Button
                            leftIcon={<BiReplyAll fontSize="14px" color="white" />}
                            m={1}
                            size="xs"
                            fontSize="12"
                            colorScheme="teal"
                            onClick={()=>{
                               setTimeout(() => {
                                refetchTranscript()
                                onLoadTranscript()
                               }, 500)
                            }}
                            variant="solid"
                            isLoading={isLoadingTranscript}
                            loadingText="loading transcript..."
                            spinnerPlacement="start"
                            isDisabled={transcriptLoaded}
                        >
                            Load Transcript
                        </Button>
                    </Box>
                )}
            </HStack>
            {onlyHistory && (
                <HStack spacing="3px">
                    {starRatings &&
                        starRatings.map((n, i) => (
                            <BiStar
                                key={i}
                                fontSize="14px"
                                color={
                                    i < Number(starRating) ? "orange" : "gray"
                                }
                            />
                        ))}
                </HStack>
            )}

{onPrint && (
                <HStack spacing="3px" ml={5}>
                   <Tooltip label="Print Conversation" aria-label='PrintConversation'><Button
                        variant="outline"
                        size="xs"
                        colorScheme={"blue"}
                        onClick={onPrint}
                        loadingText="Loading..."
                        isLoading={isLoading ?? false}
                        mr={1}
                        borderRadius={"4px"}
                    >
                        <BiPrinter fontSize={'16px'}/>
                    </Button>
                    </Tooltip> 
                </HStack>
            )}

            <ChatReassign
                isOpen={isOpen}
                onClose={onClose}
                loginUser={loginUser}
                permissions={permissions}
                agentConversationId={agentConversationId}
                handleChatAssigment={handleChatAssigment}
                chatUserConversationId={chatUserConversationId}
                userIncomingChatRequestId={userIncomingChatRequestId}
                botUserSessionId={botUserSessionId}
                currentAgentId={currentAgentId}
                chatAgentId={agentId}
                onShowConversation={onShowConversation}
            ></ChatReassign>
        </Flex>
    )
}
