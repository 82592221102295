import { io } from 'socket.io-client'
import { getTenantUris } from '../../../common/helpers/utils'
import config from '../../../config'
class socketModule {
    constructor(tenantId, queueListSuccessBySocket,agentQueueList, incomingUserHook, connectUserHook, disconnectUserHook, reassignUserHook, removeUserHook, agentavailabilityHook, agentUnavailabilityHook, userChatUpdateHook, idleagentHook, disconnectSocketHook) {
        this.socketBaseUrl = getTenantUris().socketUrl || config?.socketUrl
        this.sockets = {}
        this.initSocket({ tenantId, incomingUserHook, connectUserHook, disconnectUserHook, reassignUserHook, removeUserHook, agentavailabilityHook, agentUnavailabilityHook, userChatUpdateHook, idleagentHook, disconnectSocketHook })
        this.initSocketsWithQueue({
            tenantId,
            queueList: agentQueueList.length ? agentQueueList : queueListSuccessBySocket,
            incomingUserHook,
            connectUserHook,
            disconnectUserHook,
            reassignUserHook,
            removeUserHook, agentavailabilityHook, agentUnavailabilityHook, userChatUpdateHook, idleagentHook, disconnectSocketHook
        })
    }

    initSocket = ({ tenantId, incomingUserHook, connectUserHook, disconnectUserHook, reassignUserHook, removeUserHook, agentavailabilityHook, agentUnavailabilityHook, userChatUpdateHook, idleagentHook, disconnectSocketHook }) => {
        if (this.sockets[tenantId]) return true
        let socketUrl = tenantId ? `${this.socketBaseUrl}/${tenantId}` : this.socketBaseUrl
        const socket = io(socketUrl, {
            // transports: ['polling'],
            path: '/api/v1/ws/socket.io',
        })
        this.sockets[tenantId] = socket
        if (tenantId && !this.sockets[tenantId]) {
            this.sockets[tenantId] = socket
            this.sockets[tenantId].on('INCOMING_USER', incomingUserHook)

            this.sockets[tenantId].on('CONNECT_AGENT_TO_USER', connectUserHook)

            this.sockets[tenantId].on('DISCONNECT_USER', disconnectUserHook)

            this.sockets[tenantId].on('REASSIGN_TO_AGENT', reassignUserHook)

            this.sockets[tenantId].on('REMOVE_INCOMING_USER', removeUserHook)

            this.sockets[tenantId].on('AGENT_AVAILABLE', agentavailabilityHook)

            this.sockets[tenantId].on('AGENT_UNAVAILABLE', agentUnavailabilityHook)

            this.sockets[tenantId].on('USER_CHAT_UPDATE', userChatUpdateHook)

            this.sockets[tenantId].on('IDLE_AGENT_UNAVIALABILITY', idleagentHook)

            this.sockets[tenantId].on('disconnect', disconnectSocketHook)

            this.sockets[tenantId].on('connect_error', (error) => {
                console.log(error)
                // this.sockets[tenantId] && this.sockets[tenantId].connect()
            })

        } else this.sockets.default = socket
        
    }

    initSocketsWithQueue = ({ tenantId, queueList, incomingUserHook, connectUserHook, disconnectUserHook, reassignUserHook, removeUserHook, agentavailabilityHook, agentUnavailabilityHook, userChatUpdateHook, idleagentHook, disconnectSocketHook }) => {

        if (queueList && Array.isArray(queueList) && queueList.length > 0) {
            queueList.forEach(queue => {
                if (queue.queueName) {
                    let socketUrlWithQueue = tenantId ? `${this.socketBaseUrl}/${tenantId}/${queue.queueName}` : this.socketBaseUrl
                    const socketWithQueue = io(socketUrlWithQueue, {
                        // transports: ["polling"],
                        path: '/api/v1/ws/socket.io',
                    })
                    if (tenantId && !this.sockets[`${tenantId}-${queue.queueName}`]) {
                        this.sockets[`${tenantId}-${queue.queueName}`] = socketWithQueue
                        this.sockets[`${tenantId}-${queue.queueName}`].on('INCOMING_USER', incomingUserHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('CONNECT_AGENT_TO_USER', connectUserHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('DISCONNECT_USER', disconnectUserHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('REASSIGN_TO_AGENT', reassignUserHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('REMOVE_INCOMING_USER', removeUserHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('AGENT_AVAILABLE', agentavailabilityHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('AGENT_UNAVAILABLE', agentUnavailabilityHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('USER_CHAT_UPDATE', userChatUpdateHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('IDLE_AGENT_UNAVIALABILITY', idleagentHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('disconnect', disconnectSocketHook)

                        this.sockets[`${tenantId}-${queue.queueName}`].on('connect_error', (error) => {
                            console.log(error)
                            // this.sockets[`${tenantId}-${queue.queueName}`] && this.sockets[`${tenantId}-${queue.queueName}`].connect()
                        })


                    }

                }
            })
        }
    }



    getSocketByTenantId = ({ tenantId }) => {
        let socketInst = this.sockets[tenantId]
        if (socketInst) return socketInst
        return this.sockets.default
    }

    getQueueSockets = ({ tenantId, queueName }) => { }
}

export default socketModule
