import { Box, Menu, MenuButton, Input, MenuList, MenuItem, Flex, Spacer, Button, Textarea,useToast,Tooltip  } from '@chakra-ui/react'
import { RxMagicWand , RxText} from "react-icons/rx"
import { RiInformationLine , RiArrowDownSLine, RiUserVoiceLine,RiSendPlaneLine} from "react-icons/ri"
import { updateItem} from "../apiHooks/agentLiveChat"
import React, { useState, useEffect,useCallback } from 'react'
import { QueryClient, useMutation, useQueryClient } from 'react-query'

const AutoGeneration = ({botUserSessionId,inputText,handleSentAiText,handleShowEditor,handleTextArea}) => {
    const [showEditor, setShowEditor] = useState(false)
    const [label1, setLabel1] = useState('Formal')
    const [label2, setLabel2] = useState('Crisp')
    const [aiResponse,setAiResponse] = useState('')
    const toast = useToast()
    const [aiPayload, setAiPayload] = useState({
        temperature: 0.3,
        valSelected: "Crisp",
        userIntent: ""
    })


    let aiGeneratedResponsePostRequest = useMutation(updateItem, {
   
        onSuccess: (data) => {
            if(data &&  data?.hasOwnProperty("content")){
              let sentences = data['content'].replace(/[\n\t]+/g, ' ').trim()
              // if (!sentences[sentences.length - 1].match(/[\.?]$/)) {
              //   sentences.pop()
              // }
              // var cleanedParagraph = sentences.length > 0 ? sentences.join('. ') + '.' : data['content']
              // var regex = /\s*\d+\.\s*$/
              // cleanedParagraph = cleanedParagraph.replace(regex,'')
              handleTextArea({text:sentences})
            }
            else {
                toast({
                    title: "",
                    description: "Opps!  Currently We Are not able to generate any response! Please try again",
                    status: "warning",
                    position: "top-right",
                    duration: 9000,
                    isClosable: true,
                  })
            }
          
        },
        onError: (err, data) => {
          toast({
            title: "Error!",
            description: `${err?.message}`,
            status: "warning",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          })
        },
      })

    const {
        mutateAsync: airesponseWatcher,
        isLoading: loading,
        isSuccess: result,
        isError: error
      } = aiGeneratedResponsePostRequest

    let getAiResponse = async (aiPayload) => {
        await airesponseWatcher({
          entityUrlPaths: {
            url: `active_agents/getAgentAutocompleteMsg/${botUserSessionId}`,
            method: "POST",
          },
          payload: aiPayload,
        })
      }
  
    const handleEditor = useCallback(() => {
      setAiResponse('')
      setLabel1('Formal')
      setLabel2('Crisp')
      setShowEditor(!showEditor)
      handleShowEditor()
    },[showEditor])
  
    const getItemFromDropDown1 = (value, label1) => {
        let aiPayloadNew = {...aiPayload}
        aiPayloadNew.temperature = value
        setLabel1(label1)
        setAiPayload(aiPayloadNew)
    }
  
    const getItemFromDropDown2 = (value, label2) => {
        let aiPayloadNew = {...aiPayload}
        aiPayloadNew.valSelected = value
        setLabel2(label2)   
        setAiPayload(aiPayloadNew)
    }

    const generateAutoResponse =  async() =>{
        aiPayload.userIntent = inputText
        let aiPayloadNew = {...aiPayload}
        getAiResponse(aiPayloadNew)
    }

    const sendAiText = ()=>{
      inputText = inputText.trim()
      if(!inputText){
        setShowEditor(true)
        handleSentAiText({suggestedText: inputText})
      }else{
        setShowEditor(false)
        handleSentAiText({suggestedText: inputText})
      }
    }

    const handleInputText = (e)=>{
      handleTextArea({text: e.target.value.replace(/[\n\t]+/g, ' ')})
    }
  return (
        <>
          <Box style={{zIndex:'100',position:'absolute',left:'80%',bottom:'8px',display: ((!showEditor && inputText.split(/[\s\W]+/).filter(Boolean).length > 6) ) ? 'block':'none'}} > 
            <Tooltip label="Auto Generation" placement="top">
              <Box color={"#FFAD09"} fontSize={"25px"} cursor="pointer" onClick={handleEditor.bind(this)}><RxMagicWand /></Box>
            </Tooltip>
          </Box>
          {(showEditor) ? (
            <Box border={'1px solid #eaeaea'} p={'10px 15px'} borderRadius={'10px'} marginLeft={'3px'} bg={'white'} width={'99%'} style={{zIndex:'100', position:'absolute',transition: 'top 0.1s ease-in-out 0.5s',top:'83%'}}>
                {/* Render your text editor here */}
                <Textarea
                  // defaultValue = {inputText}
                  placeholder="Type something for Auto Complete.."
                  height='-10px'
                  fontSize = '14px'
                  fontFamily="'Calibri', 'Helvetica Neue', 'Arial', sans-serif"
                  value={inputText}
                  resize={'none'}
                  onChange = {handleInputText.bind(this)}
                />

                {/* Render dropdowns */}
                <Box >
                    <Flex alignItems={"center"}>
                      <Box >
                        <Flex alignItems={'center'} >
                          <Box >
                            <Flex alignItems={'center'} >
                              <Menu autoSelect={false}  placement="top">
                                  <Box marginRight={"5px"} ></Box>
                                      <MenuButton isDisabled={inputText.split(/\s+/).filter(Boolean).length < 7} fontWeight={"300"} leftIcon={<RiUserVoiceLine  />} paddingLeft={"5px"} paddingRight={"10px"} _hover={{bgColor: 'white'}} background={'white'} padding={"0"} as={Button} marginRight={"20px"} cursor={"pointer"} fontSize={"10px"} rightIcon={<RiArrowDownSLine />}>{label1}</MenuButton>
                                        <MenuList  bg="white" fontSize='12px' minWidth='auto'>
                                          <MenuItem className={label1 == "Formal" && "listItm"} onClick={() => getItemFromDropDown1(0.3, "Formal")}>Formal</MenuItem>
                                          <MenuItem className={label1 == "Casual" && "listItm"} onClick={() => getItemFromDropDown1(0.6, "Casual")}>Casual</MenuItem>
                                        </MenuList>
                                </Menu>
                            </Flex>
                          </Box>
                          <Box >
                            <Flex alignItems={'center'} >
                              <Menu autoSelect={false} placement="top">
                                <Box marginRight={"5px"}  fontSize={"16px"}></Box>
                                  <MenuButton isDisabled={inputText.split(/\s+/).filter(Boolean).length < 7} fontWeight={"300"}  leftIcon={<RxText  />} paddingLeft={"5px"} paddingRight={"10px"}  _hover={{bgColor: 'white'}} as={Button} padding={"0"}  background={'white'} fontSize={"10px"} cursor={"pointer"} rightIcon={<RiArrowDownSLine />}>{label2}</MenuButton>
                                    <MenuList  bg="white" fontSize='12px' minWidth='auto'>
                                      <MenuItem className={label2 == "Crisp" && "listItm"}  onClick={() => getItemFromDropDown2("Crisp", "Crisp")}>Crisp</MenuItem>
                                      <MenuItem className={label2 == "Elaborate" && "listItm"} onClick={() => getItemFromDropDown2("Elaborate", "Elaborate")}>Elaborate</MenuItem>
                                      <MenuItem className={label2 == "Average" && "listItm"} onClick={() => getItemFromDropDown2("Average", "Average")}>Average</MenuItem>
                                    </MenuList>
                              </Menu>
                            </Flex>
                          </Box>
                                          
                        </Flex>
                      </Box>
                      <Spacer />
                      <Box >
                        <Flex alignItems={'center'} >
                          <Box color={"#FFAD09"}  fontSize={"16px"}><RxMagicWand /></Box>
                            <Box><Button isDisabled={inputText.split(/\s+/).filter(Boolean).length < 7} isLoading={loading}  _hover= {{bgColor:'white'}} bgColor={'white'} cursor={"pointer"} fontSize={"13px"} paddingLeft={"7px"}  paddingRight={"10px"} color={"#FFAD09"} onClick={ generateAutoResponse.bind(this)}> Auto-Complete</Button></Box>
                              <Box width={"auto"}>
                                <Button isDisabled={inputText.split(/\s+/).filter(Boolean).length < 7} background={'transparent'} fontSize={"16px"} onClick={sendAiText.bind(this)}><RiSendPlaneLine/></Button>
                              </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box> 
            </Box>
            ):<></>}
        </>
  )
}

export default AutoGeneration
