import React, { useCallback, useState } from 'react'
import DataTable from './data-table/DataTable'
// import _ from 'lodash'
import { Flex } from '@chakra-ui/react'
import Panel from '../../common/components/Panel'
import useEntities from '../entityHooks/useEntities'

const ListLayout = props => {
  const [filter, setFilter] = useState(null)
  const { meta } = props
  console.log('Meta:::', meta)

  let { data, isLoading } = useEntities({ entityUrlPaths: meta.entityUrlPaths, resourceName: meta.name, options: { filter: filter || [] } })

  const listItems = meta.screens && meta.screens.list && meta.screens.list.items
  const fieldsMeta = meta.fieldsMeta
  let headers = []
  if (listItems && meta.fieldsMeta) {
    headers = listItems.map(f => {
      let field = fieldsMeta[f.name]
      if (!field.key) field.key = f.name
      return field
    })
  }

  const getRowActions = useCallback(row => [{ text: 'View', color: 'gray', variant: 'outline', onClick: () => console.log('Clicked: View', row) }], [])

  const records = (data && data.rows) || []
  const recordsCount = Number(data && data.count) || 0
  const placeholder = 'Search for content by type, specific phrases, tags, etc...'
  console.log('calling....', records)

  const onFetchData = filter => {
    setFilter(filter)
  }

  return (
    <Flex direction="column" gridRowGap={4} pb={12}>
      <Panel>
        {!isLoading && (
          <DataTable
            records={records}
            totalCount={recordsCount}
            headers={headers}
            getRowActions={getRowActions}
            searchPlaceholder={placeholder}
            tableHeight={500}
            rowHeight={80}
            onFetchData={onFetchData}
          />
        )}
      </Panel>
    </Flex>
  )
}

export default ListLayout
