import { ChakraProvider } from '@chakra-ui/react'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import ascTheme from '@asc/ui-theme'
import colors from '@asc/ui-theme/theme/colors'

// Import global css overrides
import './common/css/global.css'
import  './services/style.css'

import useTraceUpdate from './common/util/useTraceUpdate'

import { createRoutes } from './routes'
import { useUpdateEntity } from './services/commonBase.services'
import { RolesMeta } from './containers/agentlivechat/inbox/helpers/fnRolesMeta'
import AppContext from './AppContext'
import { Logout, NotFound, Unauthorized } from './common/containers'
import MainLayout from './MainLayout'

import { LoadingSpinner } from './common/ui'

const App = props => {
    const DEBUG = false

    const log = useCallback(
        (...msg) => {
            if (DEBUG) {
                console.log('APP:', ...msg)
            }
        },
        [DEBUG]
    )

    log('PROPS', props)
    useTraceUpdate({ props, log })
    const dispatch = useDispatch()

    const { config, history, tenant, identity, getPublicTenantData, ensureAuthentication, logout, appData, getServiceConfigData } = props

    const [hasAuthed, setHasAuthed] = useState(false)
    const [isWaitingForAuth, setIsWaitingForAuth] = useState(false)
    log('--------------------------------- HAS AUTHED? ', hasAuthed)
    const isLogoutRoute = useRouteMatch('/logout') ?? false
    const isErrorRoute = useRouteMatch('/error') ?? false
    const isUnauthRoute = useRouteMatch('/unauthorized') ?? false

    const isAlternateRoute = useMemo(() => isLogoutRoute || isErrorRoute || isUnauthRoute, [isLogoutRoute, isErrorRoute, isUnauthRoute])
    const permissions = RolesMeta({ userRoles: identity?.profile?.roles || [] })
    // Run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getPublicTenantData(), [])
    useEffect(
        () => !isAlternateRoute && !tenant.loading && !hasAuthed && identity?.exp && getServiceConfigData(),
        [tenant, identity, isAlternateRoute, hasAuthed, getServiceConfigData]
    )
    useEffect(() => {
        if (!isAlternateRoute && !hasAuthed && !tenant.loading && !tenant.error && !isWaitingForAuth) {
            log('ensureAuth...')
            ensureAuthentication()
            setIsWaitingForAuth(true)
            log('ensureAuth...Done')
        } else {
            log('LOGIN? ----- NOPE')
            log(`isAltRoute? ${!!isAlternateRoute}`, isAlternateRoute)
            log(`hasAuthed?  ${hasAuthed}`)
            log(`isLoading?  ${tenant.loading}`)
        }
    }, [isAlternateRoute, isWaitingForAuth, tenant, identity, ensureAuthentication, dispatch, hasAuthed, log])

    // Set hasAuth true, when ensureAuth is called, and:
    //      - identity token has expired, so after new one is created
    //      - token is valid, so call immediately
    useEffect(() => {
        const now = new Date().getTime()
        if (!isAlternateRoute && !tenant.loading && !hasAuthed && identity?.exp && now < identity.exp * 1000) {
            setHasAuthed(true)
            setIsWaitingForAuth(false)
        }
    }, [identity, hasAuthed, setHasAuthed, isAlternateRoute, tenant.loading])

    // Inject teams data into routes as it becomes available
    const { teams } = appData
    const routes = useMemo(() => {
        if (teams) {
            const groupedLinksData = { teams }
            return createRoutes({ groupedLinksData })
        } else {
            return null
        }
    }, [teams])

    // Theming
    const lightTheme = useMemo(() => colors.app.light, [])
    const darkTheme = useMemo(() => colors.app.dark, [])

    const { cdnUri } = config

    // Computed State
    //
    const hasLoaded = useMemo(() => {
        return hasAuthed && routes && identity.isAuthenticated
    }, [hasAuthed, identity, routes])

    const postLogoutHook = () => {
        if (localStorage.getItem('activeTabs')) localStorage.removeItem('activeTabs')
    }

    const Loader = useMemo(() => <LoadingSpinner title="Loading..." theme={lightTheme} />, [lightTheme])
    return (
        <ChakraProvider theme={ascTheme}>
            <AppContext.Provider value={{ tenant, identity, appData }}>
                <Suspense fallback={Loader}>
                    {!isAlternateRoute && hasLoaded && (
                        <MainLayout
                            config={config}
                            lightTheme={lightTheme}
                            darkTheme={darkTheme}
                            routes={routes}
                            logout={logout}
                            appData={appData}
                            identity={identity}
                            tenant={tenant}
                            useUpdateEntity={useUpdateEntity}
                            permissions={permissions}
                            isFeedback={true}
                        />
                    )}
                    {!isAlternateRoute && !hasLoaded && Loader}
                    {isLogoutRoute && <Logout cdnUri={cdnUri} history={history} logout={logout} postLogoutHook={postLogoutHook} />}
                    {isErrorRoute && <NotFound cdnUri={cdnUri} />}
                    {isUnauthRoute && <Unauthorized cdnUri={cdnUri} />}
                </Suspense>
            </AppContext.Provider>
        </ChakraProvider>
    )
}

export default App
