import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	Flex,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Spinner,
	Tooltip,
	Wrap,
	WrapItem,
	useBoolean,
	useColorMode,
	useColorModeValue,
	useDisclosure
} from '@chakra-ui/react'
import AppContext from 'AppContext'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { BiRefresh } from 'react-icons/bi'
import { RiCloseLine } from 'react-icons/ri'
import { focusManager } from 'react-query'
import { Rnd } from 'react-rnd'
import strFormat from 'strformat'
import Listing from '../../../common/components/Listing'
import ToggleIcon from '../../../common/components/sidebar/sub-components/ToggleIcon'
import { ThemeContext } from '../../../common/ui'
import { useEntity, useListScrollEntity } from './apiHooks/agentLiveChat'
import { AgentAvailability } from './component'
import NotificationBellValume from './component/notificationBellValume'
import DataProcessor from './helpers/DataProcessor'
import InitialState from './helpers/InitialState'
import { UserSettingMeta } from './helpers/userSettingMeta'

const InboxComponent = props => {
	const { identity, appData, tenant } = useContext(AppContext)
	const [selectedItem, setSelectedItem] = useState({})
	const [listing, setListing] = useState([])
	//const [newIncomingChat, setNewIncomingChat] = useState({})
	const [agentData, setAgentData] = useState({})
	const [filter, setFilter] = useState('')
	const [flag, setFlag] = useBoolean()
	const [queryFilter, setQueryFilter] = useState('')
	const [querySort, setQuerySort] = useState('')
	const [quickSearchText, setQuickSearchText] = useState('')
	const [reftechInterval, setRefetchInterval] = useState(30 * 1000)
	const { QueryKeys } = InitialState
	const { onRowSelect, t, permissions, reset, agentAvailability, incomingChat, selectedTabId, onCheckboxChange, updateNotificationStatus, updatedRow, dynamicWidth } = props
	const [tabData, setTabData] = useState('completedConversations')
	const { profile, userInfo } = identity

	const userTeams = useMemo(() => {
		if (userInfo?.team && userInfo?.team?.length > 0) {
			return userInfo?.team
		} else if (userInfo?.defaultTeam) {
			return [userInfo?.defaultTeam]
		} else {
			return [tenant?.parentTenant?.toLowerCase()]
		}
	}, [userInfo, tenant?.parentTenant])

	const [selectedFilter, setSelectedFilter] = useState(t('Filter Result'))
	const [dropDownValue, setDropDownValue] = useState(InitialState.InboxFilterList)
	const [quickFilter, setQuickFilter] = useState([])
	const [expandVal, setExpandVal] = useState([dropDownValue])
	const [fields, setFields] = useState([])
	const [selectedDropVal, setSelectedDropVal] = useState([])
	const [dropCount, setDropCount] = useState(0)
	const [searchBar, setSearchBar] = useState(true)
	const [firstInit, setFirstInit] = useState(0)
	const colorMode = useColorMode()
	let listingConfig = InitialState.defaultListConfig({
		UseColorModeValue: useColorModeValue,
		permission: permissions,
		roles: profile?.roles,
		t: t,
	})
	useEffect(() => {
		permissions && !permissions.ticketList && !permissions.ticketUserList && setTabData('completedConversations')
	}, [permissions, QueryKeys])

	listingConfig.tabs = listingConfig.tabs && listingConfig.tabs.filter(x => x.display)
	listingConfig.tabs = useMemo(
		() =>
			listingConfig?.tabs.map(x => {
				if (x.name && x.name === 'queryFilterData')
					x.title = _.truncate(selectedFilter, {
						length: 15,
						omission: '...',
					})
				return x
			}),
		[selectedFilter, listingConfig]
	)
	const [notificationMute, setNotificationMute] = useState(false)
	const [notificationVolumeOff, setNotificationVolumeOff] = useState(false)
	let defaultFilter = InitialState.defaultFilters({ identity })
	const { listLimit } = listingConfig

	const isFocused = focusManager.isFocused()

	const debounce = (func, wait) => {
		let timeout

		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout)
				func(...args)
			}

			clearTimeout(timeout)
			timeout = setTimeout(later, wait)
		}
	}
	const searchTextHandle = debounce(e => {
		if (!e.target?.value || e.target?.value?.trim() == '') setFilter(null)
		else onlivechatListSearch(e)
	}, 1000)

	const onlivechatListSearch = useCallback(
		e => {
			let textSearchValue = e.target.value
		},
		[defaultFilter, queryFilter]
	)
	const completedConversations = useListScrollEntity({
		reqPath: `conversations/conversationByUserName`,
		key: QueryKeys?.COMPLETED_CONVERSATION,
		limit: listLimit,
		filter: defaultFilter?.completedConversations,
		oDataFilter: false,
		options: {
			enabled: !!permissions.livechatUsers || !!permissions?.liveChatuserReadOnly,
			refetchInterval: tabData === 'completedConversations' && isFocused ? reftechInterval : false,
		},
	})
	let dataList = {
		completedConversations,
	}
	const dbData = dataList[tabData]
	const { data, isFetching, isFetched, isError, hasNextPage, fetchNextPage } = dbData

	listingConfig = DataProcessor.handleCount({
		listing: listingConfig,
		completedConversations: completedConversations?.data,
	})

	const [resetWidth, setResetWidth] = useState(false)
	const rowData = useMemo(
		() =>
			data &&
			data?.pages &&
			DataProcessor.formatLivechatListData({
				data: data.pages,
				type: 'completedConversations',
				t,
				colorMode,
				queueList:props?.agentQueueList || null
			}),
		[data, tabData, updatedRow, resetWidth, profile, t]
	)
	useEffect(() => {
		setListing(rowData)
	}, [rowData])
	listingConfig = useMemo(() => listingConfig && { ...listingConfig }, [listingConfig])
	//set Filter Fields list

	//On Listing Row Select Event
	const onSelect = useCallback(
		item => {
			let currentItem = item
			setSelectedItem(currentItem)
			let listItemData = _.cloneDeep(currentItem?.rowItem)
			if (agentData) listItemData.agentData = agentData
			onRowSelect(listItemData || {})
		},
		[onRowSelect, agentData]
	)
	//On Listing Row Select Event

	// Scrolling down of List
	const onListScroll = useCallback(async () => {
		let fetching = false
		if (!fetching) {
			fetching = true
			if (hasNextPage) fetchNextPage()
			fetching = false
		}
	}, [hasNextPage, fetchNextPage])
	// Scrolling down of List

	// Text Search Filter for Tickets
 
 

	const handleQueryFilter = useCallback((queryString, selectedFilter) => {
		if (selectedFilter === 'Filter Result') {
			setSelectedDropVal([
				{
					label: t ? t('Filter Result') : 'Filter Result',
				},
			])
		} else {
			setSelectedDropVal([
				{
					label: selectedFilter,
					name: selectedFilter,
				},
			])
		}
		setQueryFilter(queryString)
		setTabData('queryFilterData')
		setSelectedFilter(selectedFilter)
	}, [])

	const handleQuerySort = useCallback(sort => {
		setQuerySort(sort)
	}, [])

	const handleResetFilterSort = useCallback(() => {
		setFlag.toggle()
		// setQueryFilter('')
		// setQuerySort('')
		// setFilter('')
		// setQuickSearchText('')
		// setTabData('myAssignedTickets')
		setSelectedFilter('Filter Results')
		setFirstInit(0)
	}, [])

	const { theme } = useContext(ThemeContext)

	const handleAgentData = useCallback(item => {
		item && setAgentData(item)
	}, [])

	const handleNotificationMute = useCallback(() => {
		let notifyMute = notificationMute
		setNotificationMute(!notifyMute)
		updateNotificationStatus(!notificationMute, notificationVolumeOff)
	}, [notificationMute, notificationVolumeOff, updateNotificationStatus])

	const handleNotificationVolumnOff = useCallback(() => {
		let notifyValumeOff = notificationVolumeOff
		setNotificationVolumeOff(!notifyValumeOff)
		updateNotificationStatus(notificationMute, !notificationVolumeOff)
	}, [notificationVolumeOff, notificationMute, updateNotificationStatus])

	const incomingChatList = useMemo(() => incomingChat && _.uniq(incomingChat, 'id'), [incomingChat])
	const { handleToggleChange, toggleExpended } = props
	const handleToggleExpend = useCallback(() => {
		handleToggleChange()
	}, [handleToggleChange])

	const onResize = (e, direction, ref, delta, position) => {
		setTimeout(() => setResetWidth(ref?.offsetWidth), 1000)

		props.handleExpendCollapse()
		//    setResetWidth(false)
	}
	const clearQuickText = useCallback(() => {
		setQuickSearchText('')
		setFilter(null)
	}, [])

	const style = {
		background: 'transparent',
		borderRadius: '10px',
		position: 'initial',
		display: 'flex',
		zIndex: 3,

		// width:{ base: `100%`, md: `${30- dynamicWidth}%` }
	}
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [item, setItem] = useState({})
	const openCustomModal = useCallback(
		item => {
			onOpen()
			setItem(item)
		},
		[onOpen, item]
	)

	const {
		data: userSetting,
		isFetched: isFetchUserSetting,
		refetch: fetchUserSetting,
	} = useEntity({ reqPath: `ticketing/UserSetting?$filter=entity eq '${UserSettingMeta.filterEntity}' and status eq 'Published'&$orderby=filterName` }) //for now fetch all tenant filter with default(userId eq '${profile?.userId}' and )
	let dataItems = userSetting && userSetting.filter(item => !item.isDefault && item.userId === profile?.userId)
	dataItems =
		dataItems &&
		dataItems.map(u => {
			return { name: u.filterName, label: u.filterName, id: u.id, isDefault: u.isDefault }
		})
	if (permissions.ticketUserList && !permissions.ticketList) {
		dataItems = dataItems?.filter(item => !item.isDefault)
	}
	let finalArr = []
	if (dataItems && dataItems?.length > 0) {
		setQuickFilter(dataItems)
		finalArr = [...dropDownValue, ...quickFilter]
	}

	const handleApplyFilterNow = useCallback(
		selectedOption => {
			if (!selectedOption) return
			let currentFilter = userSetting && _.find(userSetting, i => i.filterName === selectedOption && i.userId === profile?.userId)
			if (!currentFilter) currentFilter = userSetting && _.find(userSetting, i => i.filterName === selectedOption && i.isDefault)
			let filterEntity = currentFilter && currentFilter.properties && currentFilter.properties[UserSettingMeta.filterEntity]
			// //check is dynamic value for login user need to set
			if (filterEntity) {
				filterEntity = filterEntity.map(item => {
					let itemRef = item
					if (typeof item.value === 'string') itemRef.value = strFormat(item.value, { username: profile?.preferred_username })
					return itemRef
				})
			}
			if (filterEntity) {
				let queryFilter = DataProcessor.queryFilter({ meta: filterEntity, fields })
				handleQueryFilter(queryFilter, selectedOption)
			}
		},
		[handleQueryFilter, userSetting, fields, profile, permissions]
	)

	useEffect(() => {
		if (userSetting && (userSetting?.length > 0) & (fields?.length > 0) & (firstInit === 0)) {
			const setDefault = userSetting?.filter(el => {
				if (!el?.isDefault && el?.properties?.myDefault && el?.userId === profile?.userId) {
					return el
				}
			})
			if (setDefault && setDefault?.length > 0) {
				setSelectedDropVal([
					{
						label: setDefault[0].filterName,
						name: setDefault[0].filterName,
					},
				])
				handleApplyFilterNow(setDefault[0].filterName)
			} else {
				setSelectedDropVal([
					{
						label: t ? t('My Assigned Tickets') : 'My Assigned Tickets',
						name: 'myAssignedTickets',
						wasTab: true,
					},
				])
			}
			setFirstInit(1)
		}
	}, [userSetting, fields, firstInit, profile])

	const menuItemClickHandler = item => {
		if (item?.wasTab) {
			setTabData(item?.name)
			setSelectedDropVal([item])
			setDropCount(item.count)
		} else {
			handleApplyFilterNow(item?.name)
			setSelectedDropVal([item])
		}
	}

	return (
		<Rnd
			disableDragging={true}
			enableResizing={{ right: true }}
			style={style}
			onResize={onResize}
			default={{
				x: 0,
				y: 0,
				width: props.isMobile ? '100%' : '25%',
				//   minWidth:'30%',
			}}
			minWidth={`${!props.toggleExpended ? '5%' : '12%'}`}
			size={!props.toggleExpended && { width: `${30 - dynamicWidth}%` }}
			maxWidth={props.isMobile ? '100%' : '40%'}
			resizeHandleWrapperStyle={{ background: 'darkred', borderRadius: '10px' }}
			resizeHandleWrapperClass="resizer-elm">
			<Flex key={flag} direction="column" p={2} className="my-box" borderRadius="10px" h={'calc(100vh - 40px) !important'} bg={theme.bg2} w={'100%'} overflow={'hidden'}>
				<Flex justifyContent="space-between" marginBottom={props.isMobile ? '15px' : ''}>
					{props.toggleExpended && (
						<Heading fontSize="14px" color="#4A5568">
							{t('Live Chat')}
						</Heading>
					)}

					{(permissions.ticketList || permissions.ticketUserList) && (
						<>
							<Box w="100%" d="flex" justifyContent={'right'}></Box>
							{!props.isMobile && (
								<Box onClick={handleToggleExpend} mt={'-3px'} mr={'-10px'}>
									<ToggleIcon size={32} isSidebarOpen={toggleExpended} transitionTime={props.transitionTime} color={theme.activeColor} bg={theme.bg1} />
								</Box>
							)}
						</>
					)}
				</Flex>
				{props.toggleExpended && (
					<>
						<Wrap style={{ width: '100% !important' }}>
							<WrapItem justifyContent={'left'} alignItems={'center'}>
								{permissions.valumeBellIcon && (
									<NotificationBellValume
										handleNotificationMute={handleNotificationMute}
										handleNotificationVolumnOff={handleNotificationVolumnOff}
										notificationVolumeOff={notificationVolumeOff}
										notificationMute={notificationMute}
										t={t}
									/>
								)}
							</WrapItem>
							<WrapItem justifyContent={'right'} width={'81%'}>
							
								{permissions.agentAvailable && (
									<AgentAvailability agentQueueList={props.agentQueueList} incomingChatList={incomingChatList} agentAvailability={agentAvailability} handleAgentData={handleAgentData} identity={identity} t={t} />
								)}
							</WrapItem>
						</Wrap>

						<Flex justifyContent="space-between" marginBottom={props.isMobile ? '15px' : ''}>
							<Box w="90%" d="block">
								{/* {listingConfig.tabs && listingConfig.tabs.length!=0 && <Select style={{ minHeight: '30px', borderColor: 'inher/;,it', width: '250px', fontSize: '12px'  }} options={dropVal} value={filterVal} onChange={selectedVal} tabData ={tabData} selectedTabId={selectedTabId} isFetching={isFetching} placeholder={`${filterVal}`} formatOptionLabel={testOptions}/>} */}

								{/* <InputGroup size='md'>
                    <Input size="xs" placeholder={t?t('Search'):"Search"} onInput={searchTextHandle} value={quickSearchText}  onChange={(e)=>setQuickSearchText(e.target.value)} onKeyUp={(e)=>{if(e.key==="Enter")searchTextHandle(e)}}   />
                    <Tooltip label={t?t('Clear Text'):'Clear Text'}>
                    <InputRightElement top={'-8px'} right={'-8px'} onClick={clearQuickText} style={{cursor:'pointer'}}>
                     <RiCloseLine fontSize="23px"  color="gray"/>
                    </InputRightElement>
                    </Tooltip>
                 </InputGroup> */}

								 
							</Box>
							<Box d="flex" justifyContent={'space-between'} p={'2px'}>
								{/* { permissions.ticketList && <Filter ticketField={ticketField} handleQueryFilter={handleQueryFilter} permissions={permissions} t={t}/>}
                { (permissions.ticketList || permissions.ticketUserList) && 
                <Sort t={t} ticketField={ticketField} handleQuerySort={handleQuerySort}/>
                } */}
								<Button
									size={'xs'}
									ml={'2px'}
									p={0}
									borderRadius={'2px'}
									color="gray"
									variant="outline"
									onClick={handleResetFilterSort}
									title={t ? t('Reset') : 'Reset'}
									mt={'-2.5px'}>
									<BiRefresh color="inherit" fontSize="20px" />
								</Button>
							</Box>
						</Flex>

						{searchBar && (
							<InputGroup size="md" zIndex={'0'}>
								<Input
									size="xs"
									placeholder={t ? t('Search') : 'Search'}
									onInput={searchTextHandle}
									value={quickSearchText}
									onChange={e => setQuickSearchText(e.target.value)}
									onKeyUp={e => {
										if (e.key === 'Enter') searchTextHandle(e)
									}}
								/>
								<Tooltip label={t ? t('Clear Text') : 'Clear Text'}>
									<InputRightElement top={'-8px'} right={'-8px'} onClick={clearQuickText} style={{ cursor: 'pointer' }}>
										<RiCloseLine fontSize="23px" color="gray" />
									</InputRightElement>
								</Tooltip>
							</InputGroup>
						)}
						<Listing
							{...listingConfig}
							previousItemList={incomingChatList} 
							onSel={onSelect}
							onListScroll={onListScroll} 
							selectedTabId={selectedTabId}
							items={listing}
							height={'calc(100vh - 195px)'}
							isFetching={isFetching}
							onCheckboxChange={onCheckboxChange} 
							theme={theme}
							tabData={tabData}
							selectedFilter={selectedFilter} 
							t={t}
						/>

						{isError && (
							<Alert status="error" padding="5px" mt="5px">
								<AlertIcon />
								<AlertDescription mr={2} fontSize="12px">
									{t ? t('Something wrong, please reload again') : 'Something wrong, please reload again'}.
								</AlertDescription>
							</Alert>
						)}
					</>
				)}
				{isFetching && (
					<Box bg={theme.bg1} align="center" p={1} justifyContent="space-between" fontSize="10px">
						<Spinner colorScheme="teal" align="center" size="xs" label="Loading..." variant="solid" ml={1} mr={1} />
						Loading....
					</Box>
				)}
			</Flex>
		</Rnd>
	)
}

 

export default withTranslation()(InboxComponent)
