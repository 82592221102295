import { Box, Button, Tag, TagLabel, TagRightIcon, Tooltip, useClipboard, useToast } from '@chakra-ui/react'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { MdArrowBack, MdClose } from 'react-icons/md'
import { ThemeContext } from '../../../common/ui'
import TabWrapper from './component/tabWrapper'
import { Conversation } from './conversation'

const Tabs = props => {
	const {
		t, 
		tabs,
		fnTabChange, 
		fnRemoveTab,
		permissions,
		ticketConfig,
		onTicketSubmit,
		onItemTypeChange, 
		handleBackToList,
		isMobile,
		isAgentAvailable,
		closeAllTabs,
		onShowConversation
	} = props
	let currentTab = useMemo(() => tabs.find(tab => tab.isSelectedTab), [tabs])
	const RenderItem = useMemo(() => {
		switch (currentTab && currentTab.component) {
			case 'agentChatbot':
				return <Conversation t={t} isAgentAvailable={isAgentAvailable} userData={currentTab.data} permissions={permissions} onShowConversation={onShowConversation} />
			case 'conversationChat':
				return <Conversation t={t} isAgentAvailable={isAgentAvailable} userData={currentTab.data} permissions={permissions} />
			default:
				return <></>
		}
	}, [currentTab, t, permissions, ticketConfig, onTicketSubmit, onItemTypeChange, isAgentAvailable])

	//  NEXT AND PREVIOUS SLIDER PART
	const ref = useRef(null)
	const [copyEnabled, setCopyEnabled] = useState(false)
	const [caseId, setCaseId] = useState('')
	const slide = shift => {
		if (shift === 'right') {
			ref.current.scrollLeft = +10000
		} else if (shift === 'left') {
			ref.current.scrollLeft = -50
		}
	}

	const handleMouseOver = useCallback(caseId => {
		setCaseId(caseId)
		setCopyEnabled(true)
	}, [])
	//theme
	const { theme } = useContext(ThemeContext)

	//COPY URL
	const { hasCopied, onCopy } = useClipboard(`${window.location.hostname}/agentlivechat/mychats/${caseId}`)
	const toast = useToast()
	useEffect(() => {
		hasCopied &&
			toast({
				title: t('Text Copied'),
				description: `${window.location.hostname}/agentlivechat/mychats/${caseId}`,
				status: 'info',
				duration: 7000,
				isClosable: true,
				position: 'top-right',
			})
	}, [hasCopied, caseId, toast])

	useEffect(() => {
		if (!currentTab?.isTabClick) {
			ref.current.scrollLeft = 0
		}
	}, [currentTab])

	return (
		<>
			<Box>
				{isMobile && (
					<Button colorScheme="facebook" leftIcon={<MdArrowBack />} ml="16px" size={'xs'} top={'3px'} onClick={handleBackToList}>
						{t ? t('Back') : 'Back'}
					</Button>
				)}
				{/* TABWRAPPER COMPONENT FOR MENU, LEFT AND RIGHT SCROLL BUTTONS */}
				<TabWrapper slider={slide} closeAllTabs={closeAllTabs} t={t}>
					<Box
						ref={ref}
						overflow="auto"
						width={'100%'}
						p="7px 7px"
						display={isMobile ? 'none' : 'flex'}
						alignItems="center"
						// bg="#fff"
						borderBottom="1px solid #f4f4f4"
						>
						<Box width="max-content" display="flex">
							{tabs.map(tab => {
								return (
									<Tag
										key={tab.name || tab.title}
										borderRadius="5px"
										// m={1}
										//fontSize={12}
										variant="outline"
										bg={tab.isSelectedTab ? '#034FBD' : ''}
										color={tab.isSelectedTab ? '#fff' : ''}
										cursor="pointer"
										minHeight={'24px'}
										boxShadow={'none'}
										fontSize={'10px'}
										fontWeight={'500'}
										onMouseOver={handleMouseOver.bind(this, tab.name)}
										onMouseLeave={() => {
											setCopyEnabled(false)
										}}
										mr="2px"
										width={'5.4rem'}
										justifyContent={'center'}
										border={tab.isSelectedTab ? `1px solid ${theme.isLight ? theme.fg2 : theme.fg1}` : `1px solid ${theme.isLight ? theme.fg2 : theme.fg1}`}>
										{/* COPY ICON */}
										{/* {copyEnabled && caseId == tab.name && (
                                            <Tooltip
                                                hasArrow
                                                label="Copy Ticket Link"
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                                shouldWrapChildren
                                                fontSize={12}>
                                                <TagLeftIcon as={MdFileCopy} onClick={onCopy}  mr={'2px'} ml={'2px'} />
                                            </Tooltip>
                                        )} */}
										{/* COPY URL TAG/ICON  ENDS*/}
										<Tooltip hasArrow label={tab.title || tab.name} bg="gray.300" color="black" placement="top" shouldWrapChildren fontSize={12}>
											<TagLabel
												onClick={() => {
													fnTabChange({
														component: tab.component,
														tabId: tab.name,
														isTabClick: true,
													})
												}}>
												{(tab.title || tab.name).length < 8 ? tab.title || tab.name : `..${(tab.title || tab.name).slice(-6)}`}
											</TagLabel>
										</Tooltip>

										<TagRightIcon
											as={MdClose}
											onClick={fnRemoveTab.bind(this, {
												component: tab.component,
												tabId: tab.name,
											})}>
											{/* <TagCloseButton onClick={fnRemoveTab.bind(this, { component: tab.component, tabId: tab.name })} /> */}
										</TagRightIcon>
									</Tag>
								)
							})}
						</Box>
					</Box>
				</TabWrapper>
				{/* TABWRAPPER ENDS */}
			</Box>
			<Box d={{ base: 'block', sm: 'flex' }} marginTop={isMobile ? '10px' : ''}>
				<Box w={{ base: '98%', sm: '100%' }} borderRight={'1px solid #f4f4f4'}>
					{currentTab && RenderItem}
				</Box>
			</Box>
		</>
	)
}
export default Tabs
