import React, { } from 'react'
import './style.css'

export const SkeletonComponent = (props) => {
  return (
    <section className="box">
      <div className="skeleton-placeholder" style={{PaddingLeft:"10px"}} >
        {props.loadingText}
    </div>
    </section>
  )
}
