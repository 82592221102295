import { useQuery, queryClient } from 'react-query'
import {ServiceHelper} from '../../../../common/helpers/ServiceHelper'

const useEntity = ({ resourceName, reqPath }) => {
  return useQuery([resourceName, reqPath], () => {
    return ServiceHelper.prepAndAsyncCall({ method: 'GET', reqPath: reqPath }).then(res=>res.data)
  }, {refetchOnWindowFocus: false })
}

export default useEntity
