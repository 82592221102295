import React from 'react'
import {
    Box,
    Link
} from '@chakra-ui/react'
const Footer = (props) =>{
    const {theme} = props
    return (
        <Box textAlign="right" mt={1} fontSize="13px" p={3} bg={theme.isLight ? theme.bg2 : theme.bg1}
        color={theme.isLight ? '#111827' : theme.fg1}
        paddingRight={'15px'}
        className="footer"
        >
            <Link href='https://www.rezolve.ai/' isExternal>
             copyrights@rezolve.ai
            </Link>
        </Box>
    )
}

export default Footer