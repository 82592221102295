import { Box, Button, Flex, HStack, Heading, useColorMode, useMediaQuery, useToast } from '@chakra-ui/react'
import ReactWebChat, { createDirectLine, createStore } from 'botframework-webchat'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { BiChat } from 'react-icons/bi'
import { useQueryClient } from "react-query"

import { useReactToPrint } from 'react-to-print'
import { ThemeContext } from '../../../../common/ui'
import { useUpdateEntityWebBot } from '../apiHooks/agentLiveChat'
import { LiveChatTypes } from '../types/liveChatTypes'
import { ActivityMiddleware } from './activityMiddleware'
import AutoSuggestion from './autoSuggestion'
import { Header } from './header'
import styleSet from './styleSet'
import AutoGeneration from './AutoGeneration'
import '../../../../assets/scss/v4.css'
export const Webchat = (props: LiveChatTypes) => {
    const {
        token,
        commandToBotReassign,
        assignedFromAgent,
        prevAgentUsername,
        uiFeature,
        conversation,
        t,
        botAvatar,
        userAvatar,
        cannedResponsesData,
        permissions,
        loginUser,
        onlyHistory,
        startRating,
        isAgentAvailable,
        onLoadTranscript,
        refetchTranscript,
        onShowConversation,
        transcriptLoaded
    } = props

    const [user, setUser] = useState(props.user)
    const [additionalData, setAdditionalData] = useState(props.additionalData)
    const [hideDisconnect, setHideDisconnect] = useState(false)
    const [hideUploadButton, sethideUploadButton] = useState(false)
    const [loadTranscript, setloadTranscript] = useState(false)
    const [titleText, setTitleText] = useState(null)
    const [inputText, setInputText] = useState('')
    const [chatToken, setChatToken] = useState('')
    const [hideSendBox, setHideSendBox] = useState(user.agentConversationId ? false : true)
    const [agentConversationId, setAgentConversationId] = useState(user.agentConversationId || null)
    const [reAssignToAgentId, setReAssignToAgentId] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [rootHeight,setRootHeight] = useState(false)
    const queryCache = useQueryClient()
    // const errorCb = (data) => {
        
    // }

    // const errorCb = useCallback(() => {
    //     alert('sajbdkjasbkdb')
 
    // }, [mutate])
    const { mutate } = useUpdateEntityWebBot({ resourceName: 'handOverToBot'})
    const toast = useToast()
    const setDirectLine = useMemo(() => createDirectLine({ secret: chatToken, user: user, conversationId: agentConversationId }), [chatToken, user]) //conversationId: agentConversationId,
    const componentRef = useRef()
    const { colorMode } = useColorMode()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    const printDetail = useCallback(() => {
        handlePrint()
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 1500)
    }, [handlePrint])

    useEffect(() => {
        let userInfo = { ...user }
        if (commandToBotReassign && reAssignToAgentId) {
            userInfo.reAssignToAgentId = reAssignToAgentId
            userInfo.assignedFromAgent = assignedFromAgent
            userInfo.prevAgentUsername = prevAgentUsername
        }
        if (agentConversationId) {
            setHideDisconnect(false)
            sethideUploadButton(false)
        }
        setUser(userInfo)
    }, [])

    useEffect(() => {
        permissions.reasignChat && setHideSendBox(true)
    }, [permissions])

    const openToast = useCallback(
        ({title,description,status}) => {
            toast({
                title: t?t(title) : t('Live Chat'),
                description: t(description) || t?t(`Update Successfully!`):`Update Successfully!`,
                status: status || 'success',
                duration: 3000,
                position: "top-right",
                isClosable: true,
            })
        },
        [toast],
    )

    const store = useMemo(
        () =>
            createStore(
                {
                    activities: conversation,
                },
                ({ dispatch, getState }: any) =>
                    (next: any) =>
                    (action: any) => {
                        let { activities } = getState()
                        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
                            let conversationId = action.payload && action.payload.directLine && action.payload.directLine.conversationId
                            if (!agentConversationId) {
                                setAgentConversationId(conversationId)
                                let params = additionalData
                                let userData = user
                                mutate({
                                    entityUrlPaths: {
                                        url: `/active_agents/handOverFromBot/${reAssignToAgentId || params.agentId}/${userData.agentId || conversationId}/${
                                            params.endUserId
                                        }/${params.botUserSessionId}/${userData.chatUserConversationId}/${userData.name}`,
                                        method: 'GET',
                                    },
                                    payload: {},
                                }) 
                            }

                            if (reAssignToAgentId) {
                                dispatch({
                                    type: 'WEB_CHAT/SEND_MESSAGE',
                                    payload: {
                                        text: 'connect',
                                        channelData: user,
                                        from: { name: user.name, role: 'agent', id: user.userId, reassignStatus: 'OneTOAnother' },
                                    },
                                })
                            } else {
                                !agentConversationId &&
                                    dispatch({
                                        type: 'WEB_CHAT/SEND_MESSAGE',
                                        payload: {
                                            text: 'connect',
                                            channelData: user,
                                            from: { name: user.name, role: 'agent', id: user.userId },
                                        },
                                    })
                            }
                        }
                        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
                            // setReAssignToAgentId(false)

                            if (action.payload && action.payload.activity && action.payload.activity.text) {
                                const {
                                    text,
                                    from: { role },
                                } = action.payload.activity
                                // setReAssignToAgentId(false)

                                if ((text.indexOf(t('You are connected to')) != -1 || text.indexOf('Merci de fournir les détails.') != -1) && role === 'bot') {
                                    let agentAttachment = (uiFeature && uiFeature.liveChat && uiFeature.liveChat.agentAttachIcon) || false
                                    setHideDisconnect(false)
                                    sethideUploadButton(agentAttachment)
                                }
                                if (
                                    hideUploadButton &&
                                    loadTranscript &&
                                    !(uiFeature && uiFeature.liveChat && uiFeature.liveChat.agentAttachIcon) &&
                                    (!hideDisconnect || agentConversationId)
                                ) {
                                    sethideUploadButton(false)
                                }
                            }
                            //hideAdaptiveCardButtons(activities)
                        }
                        if (action.type === 'WEB_CHAT/SET_SEND_BOX') {
                            let value = action.payload.text
                            setTitleText(value)
                            setInputText(value)
                            return next(action)
                        }
                        if (action.type == 'DIRECT_LINE/CONNECT_REJECTED') {
                        }
                        if (action?.payload?.activity && action?.payload?.activity?.from?.id && action?.payload?.activity?.from?.id.startsWith('r_')) {
                            action.payload.activity.from.id = user.id
                        }
                        if (action?.payload?.text?.toLowerCase() !== 'disconnect' && action?.payload?.activity && !action?.payload?.activity?.channelData?.agentCheck) {
                            action.payload.activity = { ...action.payload.activity, channelData: user }
                        }
                        let inputRef: any = document.querySelector('[aria-label="Sendbox"]')
                        if (inputRef) inputRef.focus()
                        return next(action)
                    }
            ),
        [reAssignToAgentId, user, chatToken]
    )
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const { theme } = useContext(ThemeContext)
    const styleOptions = {
        botAvatarImage: botAvatar,
        avatarSize: 35,
        // botAvatarInitials: botName || '',
        userAvatarImage: userAvatar || '',
        // userAvatarInitials: username || 'User',
        hideUploadButton: hideUploadButton,
        // hideSendBox: hideSendBox,
    }
    const activityMiddleware = () => (next: any) => (card: any) => {
        return ActivityMiddleware({ t, next, card })
    }

    const attachmentMiddleware = () => (next: any) => (card: any) => {
        const { attachment } = card
        // if(attachment && attachment.contentType && attachment.contentType==='application/vnd.microsoft.card.hero'){
        //   if(attachment?.content?.buttons){
        //     attachment.content.buttons.map(x=>x.disabled=true)
        //   }
        // }
        return next(card)
    }

    const handleConnectToUser = () => {
        if (!isAgentAvailable) {
            toast({
                title: t(`Agent Availability`),
                description: t(`Please make your self available to initiate chat`),
                status: `warning`,
                duration: 5000,
                position: 'top-right',
                isClosable: true,
            })
            return
        }
        setChatToken(token)
        setHideSendBox(false)
        
    }

    // useEffect(() => {
    //   let params = additionalData
    //   let userData = user
    //   const handOverToBot: any = () =>
    //     mutate({
    //       entityUrlPaths: {
    //         url: `/active_agents/handOverFromBot/${params.agentId}/${agentConversationId}/${params.endUserId}/${params.botUserSessionId}/${userData.chatUserConversationId}/${userData.name}`,
    //         method: 'GET',
    //       },
    //       payload: {},
    //     })
    //   agentConversationId && handOverToBot()
    // }, [agentConversationId])

 

    

    const handleChatAssigment = useCallback(
        ({ chatData, isAssignment }: any) => {
            let userData = user
            let botUserSessionId = chatData.botUserSessionId
            if (chatData) {
                let newUser = {
                    id: chatData.id,
                    userId: chatData.agentId,
                    name: chatData.name ? chatData.name : loginUser.name,
                    userName: chatData.userFullName,
                    agentFullName: chatData.agentFullName || chatData.userName,
                    agentConversationId: userData.agentBotConversationId,
                    incommingChatUserId: null,
                    agentId: chatData.agentId,
                    agentCheck: `Agent-${chatData.agentId}`,
                    chatUserConversationId: chatData.chatUserConversationId || userData.chatUserConversationId,
                    tenantId: loginUser.tenantUid || chatData.tenantId,
                    loadTranscript: true,
                    reAssignToAgentId: chatData.agentDbId,
                    assignedFromAgent: user.userName,
                    prevAgentUsername: user.userName,
                }
                userData = newUser
                if (isAssignment) {
                    userData = {
                        ...userData,
                        reAssignToAgentId: chatData.id || chatData.agentId,
                        assignedFromAgent: user.userName,
                        prevAgentUsername: user.userName,
                    }
                }
                setUser(userData)
                setReAssignToAgentId(chatData.id || chatData.agentId)
                setChatToken(token)
                store && store.dispatch({
                  type: 'WEB_CHAT/SEND_MESSAGE',
                  payload: {
                    text: 'connect',
                    channelData: userData,
                    from: { name: userData.name, role: 'agent', id: userData.userId, reassignStatus: 'OneTOAnother' },
                  },
                })
            }
        },
        [user, token, loginUser]
    )

    const headerProps = {
        transcriptLoaded: transcriptLoaded,
        userFullName: user.username || user.name || user.agentFullName || user.userName,
        name: user.userName,
        incrementer: 1,
        loadTranscript: false,
        allowDisconnect: false,
        agentConversationId: agentConversationId,
        hideDisconnect: false,
        handleDisconnect: () => {
            if (store) {
                toast({
                    description: t(`${user?.userName || user?.name || user?.username || "User"} has been disconnected`),
                    status: `success`,
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                })
                store.dispatch({
                    type: 'WEB_CHAT/SEND_MESSAGE',
                    payload: {
                        text: t('Disconnect'),
                        // channelData: user,
                    },
                })
            }
        },
        handleLoadTranscript: onLoadTranscript,
        refetchTranscript:refetchTranscript,
        t,
        permissions,
        loginUser,
        handleChatAssigment: handleChatAssigment.bind(this),
        chatUserConversationId: user.chatUserConversationId,
        onlyHistory: onlyHistory,
        starRating: startRating,
        userIncomingChatRequestId: additionalData.endUserId,
        botUserSessionId: additionalData.botUserSessionId,
        currentAgentId: additionalData.currentAgentId || user.userId || user.id,
        onPrint: printDetail.bind(this),
        isLoading: isLoading,
        agentId: additionalData.currentChatAgentId,
        onShowConversation:onShowConversation
    }
    
    useEffect(() => {
        agentConversationId && setChatToken(token)
    }, [agentConversationId, token])

    const handleSendText = ({ suggestedText }: any) => {
        if (!suggestedText) return
        let inputValue: string = inputText && inputText.substring(0, inputText.lastIndexOf('#'))
        store.dispatch({
            type: 'WEB_CHAT/SET_SEND_BOX',
            payload: {
                text: inputValue + suggestedText,
            },
        })
        setTitleText(null)
        setInputText('')
        let inputRef: any = document.querySelector('[aria-label="Sendbox"]')
        if (inputRef) inputRef.focus()
    }

    const handleSentAiText = ({suggestedText}:any) => {
        if (!suggestedText) return setInputText('')
        if(suggestedText){
            store.dispatch({
                type: 'WEB_CHAT/SEND_MESSAGE',
                payload: {
                    text: `${suggestedText}`|| '',
                },
            })
            store.dispatch({
                type: 'WEB_CHAT/SET_SEND_BOX',
                payload: {
                    text: '',
                },
            })
            setHideSendBox(false)
            setRootHeight(false)
            let inputRef: any = document.querySelector('[aria-label="Sendbox"]')
            if (inputRef) inputRef.focus()
        }
    }

    const handleShowEditor = () =>{
        setRootHeight(!rootHeight)
        setHideSendBox(!hideSendBox)
    }

    const handleTextArea = ({text}:any) =>{
        if(hideSendBox){
            setInputText(text)
        }
    }

    useEffect(()=>{
        if(!hideSendBox){
            let attachmentIcon: any = document.querySelector("div[role='complementary'] .main div:first-child")
            let textInput: any = document.querySelector("div[role='complementary'] .main form input[type='text'] ")
            attachmentIcon.style.width = '28px'
            textInput.style.padding = '0 100px 0 36px'
        }
    },[hideSendBox])

    return (
        <Box>
            <Header {...headerProps} />
            {onlyHistory && conversation.length == 0 && (
                <Heading
                    as="h6"
                    size="xs"
                    bg={theme.bg1}
                    position="relative"
                    padding="5px"
                    textAlign="center"
                    color="#787878"
                    marginTop="60px"
                    display="flex"
                    justifyContent="center">
                    No chat available
                </Heading>
            )}
            {/* {winHeight - (!onlyHistory ? 295 : 290)} */}
            <Flex height={`calc(100vh - ${isMobile ? '81px' : '170px'})`} position={'relative'}>
                <Box ref={componentRef} width="calc(100% - 20px)">
                     
                        <ReactWebChat
                            directLine={setDirectLine}
                            userID={user?.userId}
                            username={user?.agentFullName}
                            styleOptions={styleOptions}
                            store={store}
                            disabled={false}
                            groupTimestamp={false}
                            activityMiddleware={activityMiddleware}
                            attachmentMiddleware={attachmentMiddleware}
                            user={user}
                            styleSet={styleSet({
                                bg: theme.isLight ? theme.bg2 : theme.bg1,
                                color: theme.fg1,
                                hideInput: hideSendBox,
                                bbBgColor: theme.isLight ? '#f9f9f9' : theme.bg2,
                                bbUserBgColor: theme.isLight ? '#2A8FF7' : theme.bg2,
                                height: rootHeight ? '83%':'100%'
                            })}
                        />    
                    {agentConversationId &&
                        <AutoGeneration
                            botUserSessionId={additionalData.botUserSessionId}
                            inputText = {inputText}
                            handleSentAiText = {handleSentAiText}
                            handleShowEditor = {handleShowEditor}
                            handleTextArea = {handleTextArea}
                        />
                    }
                </Box>
                <AutoSuggestion searchText={titleText} handleSendText={handleSendText} userInfo={user}></AutoSuggestion>
            </Flex>

            {!agentConversationId && !onlyHistory && (
                <Flex
                    height="47px"
                    // top="-38px"
                    display="flex"
                    alignItems={'center'}
                    justifyContent={'center'}
                    position="relative"
                    background={colorMode === 'dark' ? 'black' : 'white'}
                    border="0px"
                    borderTop={'1px solid #f4f4f4'}>
                    <HStack justifyContent="center">
                        {!permissions?.reasignChat &&  (
                            <Button
                                colorScheme="teal"
                                fontSize="13px"
                                width={'100%'}
                                variant="none"
                                size="sm"
                                leftIcon={<BiChat fontSize="20px" />}
                                onClick={handleConnectToUser}>
                                Click to initiate chat
                            </Button>
                        )}
                    </HStack>
                </Flex>
            )}
        </Box>
    )
}
