import {lazy} from 'react'

// Extend ReactLazy to import component other than default export for lazy Loading 

const lazyLoad = (resolver, name = 'default') => {
  return lazy(async () => {
    const resolved = await resolver()
    return {default: resolved[name]}
  })
}
export default lazyLoad

//const ExampleDefault = lazy(() => import('./Example.js'))
// const ExampleOne = lazy(() => import('./Example.js'), 'Example')
// const ExampleTwo = lazy(() => import('./Example.js'), 'Example2')