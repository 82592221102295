import { InfoIcon } from '@chakra-ui/icons'
import {
    Avatar,
    Badge,
    Box,
    Checkbox,
    Flex,
    Heading,
    List,
    ListItem,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Skeleton,
    Stack,
    Stat,
    StatArrow,
    StatGroup,
    Text,
    Tooltip,
    Wrap,
    WrapItem,
    Button,
    Grid,
    GridItem,
    Spacer,
    PopoverCloseButton,
    useDisclosure,
    InputGroup,
    Input,
    InputRightElement,
    SkeletonCircle,
    Progress
} from '@chakra-ui/react'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState, useContext,useMemo } from 'react'
import { BsFillExclamationCircleFill, BsSearch, BsX } from 'react-icons/bs'
import { BiUserPlus } from 'react-icons/bi'
import { SkeletonComponent } from './SkeletonComponent'
import AppContext from 'AppContext'
import { priorityStyles, statusStyles } from 
    '../helpers/data'
const DefaultItem = props => {
    const { appData } = useContext(AppContext)
    const { bulkAction = false, theme = false, t, isCheckedItems, hideBadges, permissions, setAssigneeConfig, isLoadingAssigneeList, assigneeList, dynamicWidth, handleAssigneeUpdate, isFetching } = props
    let item = props.item
    const { responseSLA, resolutionSLA } = item
    const [rowStyle, setRowStyle] = useState(null)
    const formatTime = (timeWithMessage) => {
        if (!timeWithMessage || typeof timeWithMessage !== 'string') {
            return ''
        }

        // Regular expression to match "Due in h:m", "HH:MM", and "overdue h:m" formats
        const timeRegex = /^(Due in |overdue )?(-?\d+):(\d+)$/

        const timeMatch = timeWithMessage.match(timeRegex)
        if (!timeMatch) {
            return timeWithMessage // Return the original input if time is not in the expected formats
        }

        const [, message, hours, minutes] = timeMatch
        const formattedHours = Math.abs(parseInt(hours)).toString().padStart(2, '0')
        const formattedMinutes = parseInt(minutes).toString().padStart(2, '0')

        if (message) {
            // Handle "Due in h:m" and "overdue h:m" formats
            return `${message.trim()} ${hours < 0 ? '-' : ''}${formattedHours}:${formattedMinutes}`
        } else {
            // Handle "HH:MM" format
            return `${formattedHours}:${formattedMinutes}`
        }
    }

    const checkBoxChange = useCallback(
        evt => {
            item.rowItem && props?.onCheckboxChange(item.rowItem, evt.target.checked)
        },
        [props, item]
    )
    useEffect(() => {
        if (item && item?.updateFlag?.isEscalated && item?.updateFlag?.updatedStyle) {
            setRowStyle(item?.updateFlag?.updatedStyle)
        } else if (item && item?.updateFlag?.isUpdated && item?.updateFlag?.updatedStyle) {
            setRowStyle(item?.updateFlag?.updatedStyle)
        } else {
            setRowStyle(null)
        }
    }, [item])

    const onRowClick = useCallback(
        val => {
            setTimeout(() => {
                setRowStyle(null)
            }, 500)
            props?.onSelect(val)
        },
        [props]
    )

    const getTicketBorderColor = (currentTicket) => {
        if (currentTicket?.type !== 'chat'){
            let { teams } = appData
            for (let ele of teams) {
                let res = ele.itemtTypes?.find(val => val.itemType === currentTicket?.rowItem?.lookupProps?.itemType)
                if (res) {
                    return res?.color
                }
                else {
                    return "grey"
                }
            }
        }
    }

    const getDynamicWidth = useCallback(() => {
        let width = document.getElementById('divScroll')?.clientWidth
        return `${width}px`
    }, [])

    const getDynamicHeight = useCallback(() => {
        let height = document.getElementById('ticket_detail_container')?.offsetHeight
        return `${height}px`
    }, [])

    const firstFieldRef = useRef()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [queueAgents, setQueueAgents] = useState([])
    const [queryStr, setQueryStr] = useState('')

    useEffect(()=>{
        assigneeList && Array.isArray(assigneeList) && setQueueAgents(assigneeList)
    }, [assigneeList])

    const onPopoverOpen = useCallback((caseVal, e)=>{
        if(!caseVal) return
        let queue = caseVal.data && caseVal.data.queue || null
        let itemType = caseVal.lookupProps && caseVal.lookupProps.itemType
        let usecase = caseVal.lookupProps && caseVal.lookupProps.usecase
        setAssigneeConfig({ queue, itemType, usecase })
        onOpen()
        e.stopPropagation()
    }, [setAssigneeConfig, onOpen])

    const onAssigneeSearch = useCallback(e=>{
        setQueryStr(e.target.value)
        let filtered = assigneeList.filter(assignee => assignee.username.toLowerCase().includes(e.target.value.toLowerCase().trim()) || 
        `${assignee.firstName} ${assignee.lastName}`.toLowerCase().includes(e.target.value.toLowerCase().trim()))
        setQueueAgents(filtered)
    }, [queueAgents, queryStr])

    useEffect(()=>{
        setTimeout(()=>{
            setQueryStr('')
            setQueueAgents(assigneeList)
        }, [100])
    }, [isOpen])

    const [isLoading, setIsLoading] = useState(false)

    const assignTicket = useCallback(async agent=>{
        setIsLoading(true)
        onClose()
        await handleAssigneeUpdate(item.title, agent.username)
        setIsLoading(false)
    }, [item, isLoading])


    return (
        <ListItem
            bg={
                rowStyle && rowStyle.background
                    ? rowStyle.background
                    : props.selectedTabId && item.title && item.title === props.selectedTabId
                        ? theme && theme.isDark
                            ? 'rgba(240,246,255,.2)'
                            : 'rgba(240,246,255,1)'
                        : ''
            }

        >
            <Box display="flex" justifyContent="start" minW={getDynamicWidth()} className='ticket_detail_container' onMouseOver={() => { getDynamicWidth() }} p="2px 0px 5px 0" pr={'10px'} borderBottom="2px" borderBottomColor="#e2e2e2" cursor="pointer">
                <Text as="span" display="block" height={getDynamicHeight()} width="4px" bg={getTicketBorderColor(item)} ></Text>
                <Flex className="ticket_detail" w='full' pt='2' gap='2' alignItems="start">
                    <Box ml="1.5" justifyContent="center" display="flex" flexDirection="column" alignItems="center" w="35px">
                        <Tooltip label={item.avatarName || item.userName} placement="auto-start" fontSize={'12px'}>
                            <Avatar className="avatar" name={item.avatarName || item.userName} size="sm" src={item.avatar ? item.avatar : ''} />
                        </Tooltip>
                        <Box h={5} mb={2}>
                            {item.id && bulkAction && (
                                <Checkbox className={!isCheckedItems ? 'listCheck' : ''} mt={3} colorScheme="green" onChange={checkBoxChange} isChecked={item && item.isChecked ? item.isChecked : false} />
                            )}
                        </Box>
                    </Box>
                    <Flex flexDir='column' w='full' onClick={onRowClick.bind(this, item)}>
                        <Flex justifyContent='space-between'>
                            <Box fontSize="13px" fontWeight={'bold'} w={'40%'} {...rowStyle}>
                                {item.type === 'chat' ? item.avatarName : ''}
                                <Text className="title"> {item.title} </Text>
                                {rowStyle && item?.updateFlag?.isUpdated && (
                                    <Badge borderRadius={'100px'} fontSize="10px" ml={'5px'} color="white" bg={'#444444'}>
                                        Unread
                                    </Badge>
                                )}
                                {rowStyle && item?.updateFlag?.isEscalated && (
                                    <Badge d="inline-flex" alignItems={'center'} borderRadius={'100px'} fontSize="10px" ml={'5px'} color="white" bg={'#444444'}>
                                        Unread <BsFillExclamationCircleFill fontSize={'12px'} />
                                    </Badge>
                                )}
                            </Box>
                            <Box {...rowStyle}>
                                <Text fontSize='x-small'> {item.dateTime} </Text>
                            </Box>
                        </Flex>
                        <Flex {...rowStyle} alignItems="center" pb='2' justifyContent="space-between">
                            <Tooltip fontSize='11px' label={item?.rowItem?.data?.subject || ''}>
                                <Text fontSize='xs' pt='1'>
                                    {item.desc}
                                </Text>
                            </Tooltip>
                        </Flex>
                        <Flex justifyContent='space-between' alignItems='center' flexWrap='wrap'>
                            <Flex alignItems='center' gap='1'>
                                {permissions?.showAssignee && (
                                    <Flex w='full'>
                                        {item && item.rowItem && item.rowItem.data && item.rowItem.data.assignedTo ? (
                                            <Tooltip fontSize='11px' label={item.rowItem.data.assignedTo.username}>
                                                <Badge 
                                                    fontSize='11px'
                                                    borderRadius='4'
                                                    textTransform='none'
                                                    color={theme?.isDark ? 'gray.300' : 'gray.500'}>
                                                        {_.truncate(item.rowItem.data.assignedTo.username, {length: dynamicWidth / 31 || 12})}
                                                    </Badge>
                                            </Tooltip>
                                        ): (
                                            <Popover
                                                isOpen={isOpen}
                                                initialFocusRef={firstFieldRef}
                                                onOpen={onOpen}
                                                onClose={onClose}
                                                placement='bottom-end'
                                                closeOnBlur
                                                pos='absolute'
                                            >
                                            <PopoverTrigger>
                                                <Button 
                                                    h='1.2rem' 
                                                    borderRadius='4' 
                                                    size='xs' 
                                                    fontSize='x-small' 
                                                    isLoading={isLoading}
                                                    isDisabled={isFetching}
                                                    onClick={(e)=>onPopoverOpen(item?.rowItem, e)} 
                                                    leftIcon={<BiUserPlus style={{marginRight:'-.3rem'}} fontSize='.6rem' />}
                                                    colorScheme='messenger'>
                                                        <Text>{t?t('Assign now'): 'Assign now'}</Text>
                                                    </Button>
                                            </PopoverTrigger>
                                            <PopoverContent py='5' px='2' onClick={e=>e.stopPropagation()}>
                                                <Flex flexDir='column' gap='2'>
                                                    <InputGroup>
                                                        <Input 
                                                            onChange={onAssigneeSearch} 
                                                            value={queryStr} 
                                                            borderRadius='6' 
                                                            size='sm' 
                                                            placeholder={t?t('Search'): 'Search'} 
                                                        />
                                                            {
                                                                <InputRightElement pb='2'>
                                                                    {!queryStr ?
                                                                        <BsSearch /> :
                                                                        <BsX onClick={()=>{
                                                                            setQueryStr('')
                                                                            setQueueAgents(assigneeList)
                                                                        }} />
                                                                    }
                                                                </InputRightElement>
                                                            }
                                                    </InputGroup>
                                                    <Flex flexDir='column' gap='1' h='14rem' py='2' className='divScroll' overflowY='auto'>
                                                        {isLoadingAssigneeList && (
                                                            <Flex flexDir='column' gap='3' px='2' pr='2'>
                                                                {Array.from({length: 6}, (index)=>(
                                                                    <Flex key={index} alignItems='center' justifyContent='space-between'>
                                                                        <SkeletonCircle w='1.5rem' h='1.5rem' />
                                                                        <Skeleton h='5' w='87%' />
                                                                    </Flex>
                                                                ))}
                                                            </Flex>
                                                        )}
                                                        {!isLoadingAssigneeList && queueAgents && 
                                                            Array.isArray(queueAgents) && 
                                                            !_.isEmpty(queueAgents) ?
                                                            _.uniqBy(queueAgents, 'username').map(agent => (
                                                            <Flex onClick={()=>assignTicket(agent)} gap='2' p='2' borderRadius='4' px='4' _hover={{boxShadow: 'lg'}}>
                                                                <Box>
                                                                    <Avatar 
                                                                        name={`${agent.firstName} ${agent.lastName}`} 
                                                                        size='xs' 
                                                                        bgColor='gray' 
                                                                        color='white' 
                                                                    />
                                                                </Box>
                                                                <Flex color={theme?.isDark ? 'gray.300' : 'gray.500'} fontSize='xs' flexDir='column'>
                                                                    <Text fontWeight='bold'>{`${agent.firstName} ${agent.lastName}`} </Text>
                                                                    <Text>{agent.username}</Text>
                                                                </Flex>
                                                            </Flex>
                                                        )) : !isLoadingAssigneeList && (
                                                            <Flex h='12rem' justifyContent='center' alignItems='center'>
                                                                <Text fontSize='xs' fontWeight='bold'>
                                                                    {t?t('No records found'): 'No records found'}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </PopoverContent>
                                            </Popover>
                                        )}
                                    </Flex>
                                )}
                                {permissions?.showQueueBadge && <Box>
                                    <Tooltip fontSize='11px' label={item?.rowItem?.data?.queue || item?.rowItem?.data?.assignedQueueTo}>
                                        <Badge 
                                            textTransform='none' 
                                            fontSize='.6rem'
                                            borderRadius='4'
                                            color={theme?.isDark ? 'gray.300' : '#41A1A4'}
                                            fontWeight='light'
                                            colorScheme='green'>
                                                {_.truncate(item?.rowItem?.data?.queue || item?.rowItem?.data?.assignedQueueTo, 
                                                    {length: permissions?.showAssignee ?  (dynamicWidth / 31 || 9) : (dynamicWidth / 25 || 15)}
                                                )}
                                        </Badge>
                                    </Tooltip>
                                </Box>}
                            </Flex>
                            <Flex alignItems='center' gap='1'>
                                {item.status && 
                                    <Tooltip fontSize='11px' label={item.status}>
                                        <Badge 
                                            textTransform='capitalize' 
                                            borderRadius='4'
                                            fontSize='.6rem'
                                            fontWeight='light' 
                                            {...statusStyles[item.status]} 
                                            color={theme?.isDark ? 'gray.100' : 'gray.500'}>{_.truncate(item.status, {length: dynamicWidth / 31 || 9})}
                                        </Badge>
                                    </Tooltip>
                                }
                                {item.priority && 
                                    <Tooltip fontSize='11px' label={item.priority}>
                                        <Badge 
                                            textTransform='capitalize' 
                                            borderRadius='4'
                                            fontSize='.6rem'
                                            fontWeight='light' 
                                            {...priorityStyles[item.rowItem.data?.priority?.toLowerCase()]}>
                                                {item.priority}
                                            </Badge>
                                    </Tooltip>
                                }
                            </Flex>
                        </Flex>
                        <Box d="flex" fontSize="12px" pb={2} justifyContent="space-between">
                            <Stack direction="row" alignItems={'start'} justifyContent={'right'} flexWrap={'wrap-reverse'}>
                                {!hideBadges && item.badges &&
                                    _.map(item.badges, (elem, idx) => (
                                        <Badge fontSize={elem.fontSize ?? '11px'} key={idx} {...elem.style}>
                                            <Tooltip
                                                placement="right"
                                                hasArrow
                                                label={`${elem.label ?? elem.type} ${elem?.hideTitle ? '' : ' : ' + elem.title}`}>
                                                {_.truncate(elem.title, {
                                                    length: elem.maxLen || 10,
                                                })}
                                            </Tooltip>
                                        </Badge>
                                    ))}
                                {item && item.showPulse && <Text className="pulse"></Text>}
                            </Stack>
                        </Box>
                        {(item.respProgress || item?.respProgress === 0) && item.showResponseSla && (
                            <Tooltip fontSize='11px' label={`Response SLA: ${item?.responseSLA?.status}`}>
                                <Box>
                                    <Progress value={item.respProgress || 0} colorScheme='blue' h='2px' max='60' />
                                </Box>
                            </Tooltip>
                        )}
                        {(item.resolProgress || item?.resolProgress === 0) && item.showResolutionSla && (
                            <Tooltip fontSize='11px' label={`Resolution SLA: ${item?.resolutionSLA?.status}`}>
                                <Box>
                                    <Progress mt='1.5' value={item.resolProgress} colorScheme='red' h='2px' max='60' />
                                </Box>
                            </Tooltip>
                        )}
                    </Flex>
                </Flex>
            </Box>
            {/* } */}
        </ListItem>
    )
}
const Listing = props => {
    const {
        width,
        items,
        Item,
        ListIcon,
        onSel,
        tabs = [],
        previousItemList,
        tabSelect = null,
        onListScroll = null,
        isFetching,
        selectedTabId,
        onCheckboxChange,
        bulkAction,
        theme,
        tabData,
        t,
        cancelTicket,
        permissions,
        hideBadges,
        setAssigneeConfig,
        isLoadingAssigneeList,
        handleAssigneeUpdate,
        dynamicWidth,
        assigneeList
    } = props || {}
    const Icon = ListIcon || InfoIcon
    const listItems = items || []
    const ItemComp = Item || DefaultItem
    const listInnerRef = useRef()
    const [activeTab, setActiveTab] = useState(0)
    useEffect(() => {
        let currentTab = tabs && tabs.find(f => f.name == tabData)
        if (currentTab) {
            tabSelect && tabSelect(currentTab)
            setActiveTab(currentTab)
        }
    }, [tabData, tabSelect, tabs])
    const onScroll = useCallback(() => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
            let st = scrollTop + 250
            if (st + clientHeight > scrollHeight) {
                onListScroll && onListScroll(true)
            }
        }
    }, [onListScroll])
    const onTabSelect = useCallback(
        item => {
            tabSelect && tabSelect(item)
            setActiveTab(item)
            document.getElementById('divScroll').scrollTop = 0
        },
        [tabSelect]
    )

    const checkBoxChangeAll = useCallback(
        evt => {
            props.cancelTicket()
            props?.onCheckboxChange(listItems, evt.target.checked)
        },
        [props, listItems]
    )

    const [ticketInfoModal, setTicketInfoModal] = useState(-1)
    const [dynamicHeight, setDynamicHeight] = useState("80px")

    // const showTicketInfoOnModal = (index) => {
    //     let divElement = document.querySelector(".ticket_detail_container")
    //     let elemHeight = divElement.offsetHeight
    //     setDynamicHeight(`${elemHeight}px`)
    //     setTicketInfoModal(index)
    // }


    // useEffect(() => {
    //      const closeTicketInfoModal = (e) => {
    //     if (!myRef.current.contains(e.target)) {
    //         setTicketInfoModal(-1)
    //     }
    // }
    // document.addEventListener('mousedown', closeTicketInfoModal)
    // }, )

const isCheckedItems=useMemo(()=>{
    let findChecked=listItems && Array.isArray(listItems) && listItems?.find(x=>x?.isChecked)
    if(findChecked?.title || findChecked?.id)
        return true
    else
        return false
},[listItems])
    return (
        <Box width={width || '100%'}>
            <Flex>
                <Wrap mb="20px" w="100%">
                    <WrapItem w="100%">
                        <Box w="100%">
                            <Flex
                                w="100%"
                                justifyContent="start"
                                borderBottom="1px"
                                borderColor="#e2e2e2"
                                p="1px"
                                pt="10px"
                                pb="10px"
                                flexDirection="row-reverse"
                                alignItems="start">
                                {/* <Wrap>
                                    {tabs &&
                                        !_.isEmpty(tabs) &&
                                        _.map(tabs, (item, idx) => (
                                            <WrapItem key={idx} d="flex" mr={2} onClick={onTabSelect.bind(this, item)} cursor="pointer" alignItems="center">
                                                {item.Icon && (
                                                    <Box d="flex" alignItems="center" justifyContent="center" w="25px" h="25px" borderRadius="4px" mr="5px">
                                                        <item.Icon />
                                                    </Box>
                                                )}
                                                <Heading
                                                    as="h5"
                                                    fontSize="12px"
                                                    {...item.style}
                                                    color={activeTab && activeTab.name === item.name ? `blue.500` : 'inherit'}>
                                                    {item?.title || 'List Title'}
                                                </Heading>
                                                <Badge borderRadius="20%" size="50px" m={1} fontSize="11px" color="white" {...(item?.countStyle || null)}>
                                                    {item?.count ?? 0}
                                                </Badge>
                                            </WrapItem>
                                        ))}
                                </Wrap> */}
                                {bulkAction && (
                                    <Box w="33px" pt="4px" display="flex" justifyContent="start" pl="3px" mr="9px">
                                        <Checkbox onChange={checkBoxChangeAll.bind(this)} colorScheme="green" />
                                    </Box>
                                )}

                                {/* <Box d="flex">
									{filter && <Filter fields={searchFields}/>}
									{sort && <Sort fields = {searchFields}/>}
								</Box> */}
                            </Flex>

                            <Box>

                                <List className="divScroll" overflowX='hidden' overflowY="auto" onScroll={() => onScroll()} ref={listInnerRef} height={`${props.height}`}>
                                    {!_.isEmpty(previousItemList) &&
                                        previousItemList.map((item, i) => {
                                            return (
                                                <Box key={i}>
                                                    <ItemComp
                                                        isCheckedItems={isCheckedItems}
                                                        selectedTabId={selectedTabId}
                                                        key={`item${i + 1}`}
                                                        onSelect={onSel}
                                                        item={item}
                                                        theme={theme}
                                                        t={t}
                                                        onCheckboxChange={onCheckboxChange}
                                                        index={i}
                                                        listItems={listItems}
                                                        hideBadges={hideBadges}
                                                        permissions={permissions}
                                                        setAssigneeConfig={setAssigneeConfig}
                                                        dynamicWidth={dynamicWidth}
                                                        assigneeList={assigneeList}
                                                        isFetching={isFetching}
                                                        isLoadingAssigneeList={isLoadingAssigneeList}
                                                        handleAssigneeUpdate={handleAssigneeUpdate}
                                                    //showTicketInfoOnModal={showTicketInfoOnModal}
                                                    />
                                                </Box>
                                            )
                                        })}

                                    {!_.isEmpty(listItems) &&
                                        listItems.map((item, i) => {
                                            return (
                                                <Box key={i}>

                                                    <ItemComp
                                                        selectedTabId={selectedTabId}
                                                        isCheckedItems={isCheckedItems}
                                                        key={`item${i + 1}`}
                                                        onSelect={onSel}
                                                        item={item}
                                                        theme={theme}
                                                        onCheckboxChange={onCheckboxChange}
                                                        bulkAction={bulkAction}
                                                        t={t}
                                                        index={i}
                                                        listItems={listItems}
                                                        hideBadges={hideBadges}
                                                        permissions={permissions}
                                                        setAssigneeConfig={setAssigneeConfig}
                                                        dynamicWidth={dynamicWidth}
                                                        assigneeList={assigneeList}
                                                        isLoadingAssigneeList={isLoadingAssigneeList}
                                                        handleAssigneeUpdate={handleAssigneeUpdate}
                                                        isFetching={isFetching}
                                                    //showTicketInfoOnModal={showTicketInfoOnModal}
                                                    />
                                                    {/* } */}
                                                </Box>)
                                        })}

                                    {_.isEmpty(listItems) && _.isEmpty(previousItemList) && !isFetching && (
                                        <Text p={5} fontSize="12px" align="center" color="gray">
                                            {`-- ${t ? t('No Record Found') : 'No Record Found'}! --`}
                                        </Text>
                                    )}
                                    {
                                        isFetching &&
                                        //    <Skeleton height={10}>
                                        //        </Skeleton>
                                        <SkeletonComponent loadingText="Loading..." />
                                    }




                                </List>
                            </Box>
                        </Box>
                    </WrapItem>
                </Wrap>
            </Flex>
        </Box>
    )
}

export default Listing
