
const CustomSwitch=(props)=>{
    const {defaultChecked,onChange} = props
//    defaultChecked=true (darkMode)
//    defaultChecked=true (lightMode)
return (
    <label htmlFor="switch" className="switch">
	{/* <span>Light</span> */}
	<span className="switch__toggle-wrap">
		<input 
            id="switch" 
            className="switch__toggle" 
            type="checkbox" 
            onChange={onChange} 
            role="switch" 
            name="switch" 
            value={defaultChecked}
            checked={defaultChecked}
        />
		<span className="switch__fill"></span>
		<span className={`switch__icon${defaultChecked ? ' dark':' light'}`}>
			<span className="switch__icon-part"></span>
			<span className="switch__icon-part"></span>
			<span className="switch__icon-part"></span>
			<span className="switch__icon-part"></span>
			<span className="switch__icon-part"></span>
			<span className="switch__icon-part"></span>
			<span className="switch__icon-part"></span>
			<span className="switch__icon-part"></span>
			<span className="switch__icon-part"></span>
		</span>
	</span>
	{/* <span>Dark</span> */}
</label>
)

}

export default CustomSwitch