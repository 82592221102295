import { useQuery, useInfiniteQuery, useMutation, useQueryClient,useQueries } from "react-query"
import {ServiceHelper} from '../../../../common/helpers/ServiceHelper'
import _ from 'lodash'
const useRecentConversation = ({ days, username, agentName }) => {
return  useQueries([
        { queryKey:'1day', queryFn: () => {
            return ServiceHelper.prepAndCall({ method: 'GET', reqPath: `train/getRecentConversation/${username}/${agentName}/${days}` }).then(res=>res.data)
         }},
        { queryKey:'2days', queryFn: () => {
            return ServiceHelper.prepAndCall({ method: 'GET', reqPath: `train/getRecentConversation/${username}/${agentName}/${days}` }).then(res=>res.data)
        }},
      ], {refetchOnWindowFocus: false })
//   return useQuery('recentConversation', () => {
//     return ServiceHelper.prepAndCall({ method: 'GET', reqPath: `/train/getRecentConversation/${username}/${agentName}/${days}` }).then(res=>res.data)
//   }, {refetchOnWindowFocus: false })
}
const useCustomEntity = ({resourceName}) => {
    const queryClient = useQueryClient()
    return useMutation(updateItem,
        {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, payload, context) => {
          queryClient.setQueryData(resourceName, context.previousData)
        },
        // Always refetch after error or success:
        onSettled: (data, error, variables, context) => {
          queryClient.invalidateQueries(resourceName)
        },
      })
}

const updateItem = ({entityUrlPaths, payload}) => {
    let reqPath = entityUrlPaths.url
    let requestOptions = ServiceHelper.prepareConfig({ method: entityUrlPaths.method || 'POST', reqPath, data: payload})
    return ServiceHelper.makeAsyncCall(requestOptions).then(res=>res.data)
}

const useConversationEntity = ({ resourceName, reqPath, method, payload, options={} }) => {
    return useQuery([resourceName, reqPath], () => {
        let reqOptions={ method: `${method || 'GET'}`, reqPath: reqPath }
        if(!_.isEmpty(payload))
            reqOptions.data=payload

        return ServiceHelper.prepAndCall(
            reqOptions,
            { staleTime: 1000 * 5 }
        ).then((res) => res.data)
    },
    options
    )
}

export {
    useRecentConversation,
    useCustomEntity,
    useConversationEntity
}
