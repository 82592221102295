import { createStyleSet } from 'botframework-webchat'
const styleSet = ({ bg, color, hideInput, bbBgColor, bbUserBgColor,height }) => {
  return createStyleSet({
    bubbleBackground: `${bbBgColor || '#f9f9f9'}`,
    bubbleBorderColor: '#f5f5f5',
    bubbleFromUserBackground: `${bbUserBgColor || '#2A8FF7'}`,
    rootHeight: height,
    // rootHeight: '70%',
    rootWidth: '100%',
    // backgroundColor: bg || '#fff',
    bubbleTextColor: '#787878',
    bubbleFromUserTextColor: 'white',
    bubbleBorderRadius: 4,
    bubbleBorderStyle: 'solid',
    bubbleBorderWidth: 1,
    bubbleFromUserBorderColor: '#f5f5f5',
    bubbleFromUserBorderRadius: 4,
    bubbleFromUserBorderStyle: 'solid',
    bubbleFromUserBorderWidth: 0,
    bubbleMaxWidth: 330,
    // accent: '#0063B1',
    backgroundColor: `${bg || '#fff'}`,
    paddingRegular: 5,
    paddingWide: 1 * 2,

    // subtle: '#fff',
    hideSendBox: hideInput || false,
    sendBoxBackground: '#FFF',
    sendBoxButtonColor: '#a5a5a7', // defaults to subtle
    sendBoxButtonColorOnDisabled: '#CCC',
    sendBoxButtonColorOnFocus: '#333',
    sendBoxButtonColorOnHover: '#333',
    sendBoxDisabledTextColor: undefined, // defaults to subtle
    sendBoxHeight: 45,
    sendBoxMaxHeight: 200,
    sendBoxTextColor: '#787878',
    sendBoxBorderBottom: undefined,
    sendBoxBorderLeft: undefined,
    sendBoxBorderRight: undefined,
    sendBoxBorderTop: 'solid 1px #f5f5f5',
    sendBoxPlaceholderColor: undefined, // defaults to subtle
    sendBoxTextWrap: false,
    failedConnectivity: '#fff',
    // adaptiveCardsParserMaxVersion: undefined,
    // cardEmphasisBackgroundColor: '#F0F0F0',
    // cardPushButtonBackgroundColor: '#FFF',
    // cardPushButtonTextColor: 'White',

    scrollToEndButtonBehavior: 'unread',
    scrollToEndButtonFontSize: '33%',
    newMessagesButtonFontSize: '33%',
    transcriptOverlayButtonBackground: 'red',
    transcriptOverlayButtonBackgroundOnDisabled: 'rgba(0, 0, 0, .6)',
    transcriptOverlayButtonBackgroundOnFocus: 'rgba(0, 0, 0, .8)',
    transcriptOverlayButtonBackgroundOnHover: 'rgba(0, 0, 0, .8)',
    transcriptOverlayButtonColor: 'White',
    transcriptOverlayButtonColorOnDisabled: 'White',
    transcriptOverlayButtonColorOnFocus: undefined,
    transcriptOverlayButtonColorOnHover: undefined,
  })
}

// After generated, you can modify the CSS rules.
// Change font family and weight.
styleSet.textContent = {
  ...styleSet.textContent,
  fontFamily: "'Comic Sans MS', 'Arial', sans-serif",
  fontWeight: 'bold',
}
export default styleSet
