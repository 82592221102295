import { Box, Flex, Skeleton, Spinner } from '@chakra-ui/react'
import AppContext from 'AppContext'
import { AlertBox } from 'containers/alert/alertBox'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Webchat } from './agentWebchat/webchat'
import useEntity from './apiHooks/agentBotConfig'
import { useConversationEntity } from './apiHooks/useRecentConversation'
import DataProcessor from './helpers/DataProcessor'
const winHeight = `${window.outerHeight}`
export const Conversation = React.memo(({ t, userData, permissions, isAgentAvailable, onShowConversation }) => {
	const { identity } = useContext(AppContext)
	const { profile, userInfo } = identity
	const [transScript, setTranscript] = useState(null)
	const [transcriptLoadedRefect, setTranscriptLoadedRefect] = useState(null)
	const [transcriptLoaded, setTranscriptLoaded] = useState(false)
	const [currentConversationId, setCurrentConversationId] = useState(null)
	const [completedConversations, setCompletedComversations] = useState(null)
	const {
		data: botConfig,
		isError,
		isFetching,
		isFetched: isBotConfig,
	} = useEntity({
		reqPath: '/botdef/getBotConfigsAgentChat',
		resourceName: 'agentBotConfig',
	})

	// const { data: loadTranscript, isFetched: scriptFetched } = useConversationEntity({
	//   reqPath: `conversations/findAndCountAll?$filter=idKey eq '${userData?.botUserSessionId}'`,
	//   resourceName: 'loadTranscript',
	// })
	const {
		data: loadTranscript,
		isFetched: scriptFetched,
		isFetching: isFetchingConversation,
		refetch: refetchTranscript,
	} = useConversationEntity({
		reqPath: `active_agents/getUserTranscriptWithBot/${userData?.botUserSessionId}`,
		resourceName: `loadTranscript_${userData?.botUserSessionId}`,
	})

	useEffect(() => {
		if (scriptFetched && loadTranscript) {
			let messages = loadTranscript?.data ? loadTranscript?.data : loadTranscript
			let transScriptFormat = messages && DataProcessor.mapTransScript({ data: messages })
			transScriptFormat = _.takeRight(transScriptFormat, 10)
			setTranscript(transScriptFormat)
		}
	}, [loadTranscript, scriptFetched])

	useEffect(() => {
		let messages = loadTranscript?.data ? loadTranscript?.data : loadTranscript
		let transScriptFormat = messages && DataProcessor.mapTransScript({ data: messages })
		setTranscriptLoadedRefect(transScriptFormat)
	},[loadTranscript, scriptFetched,refetchTranscript])

	const onLoadTranscript = useCallback(
		agentConversationId => {
			if (loadTranscript && loadTranscript.data && loadTranscript.data.length > 0) {
				let messages = loadTranscript?.data ? loadTranscript?.data : loadTranscript
				let transScriptFormat = messages && DataProcessor.mapTransScript({ data: messages })
				setCurrentConversationId(agentConversationId)
				setTranscript(transScriptFormat)
				setTimeout(() => {
					setTranscriptLoaded(prev => !prev)
				}, 2000)
			}
		},
		[loadTranscript]
	)

	useEffect(() => {
		let completedConversations = userData.idKey
			? DataProcessor.mapCompletedConversation({
					data: userData.messages,
					userInfo: userData.userInfo,
			  })
			: null
		setCompletedComversations(completedConversations)
	}, [userData])

	// if (isFetching)
	//   return (
	//     <Flex>
	//       <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
	//     </Flex>
	//   )
	if (isError)
		return (
			<Flex>
				<AlertBox alertType="error" message="Something wrong, please reload again." />
			</Flex>
		)
	let props = {
		transcriptLoaded,
		token: botConfig && botConfig.botSecret,
		user: {
			// agentName: agentData.preferred_username != '' ? agentData.preferred_username : 'Agent - ' + agentData.userId,
			// agentFullName: agentData.name ? agentData.name : agentData.preferred_username ? agentData.preferred_username : 'Agent - ' + agentData.userId,
			id: userData.agentId,
			userId: userData.agentId, //this.state.chatAgentId,
			name: userData.name ? userData.name : userData?.loginUser?.name || 'anonymous', //userData.userName, //this.state.agentFullName || this.state.agentName,
			userName: userData.userFullName || 'anonymous', //userData.userName, //this.state.agentName,
			agentFullName: userData?.loginUser?.name || userData.userFullName || userData.userName,
			agentConversationId: userData.agentBotConversationId || userData.activeAgentId || currentConversationId, //this.state.agentConversationId,
			incommingChatUserId: null, //this.state.userid,
			agentId: userData.agentId, //this.state.chatAgentId,
			agentCheck: `Agent-${userData.agentId}`, //this.state.chatAgentId,
			chatUserConversationId: userData.botUserConversationId, //this.state.chatUserConversationId,
			tenantId: userData?.loginUser?.tenantUid || userData.tenantId, //tenantId,
			loadTranscript: true, //this.state.loadTranscript
		},
		t,
		additionalData: {
			agentId: userData.id || userData.agentId || userData.endUserId, //|| userData.agentId,
			endUserId: userData.endUserId,
			botUserSessionId: userData.botUserSessionId,
			currentAgentId: userData.currentAgentId,
			currentChatAgentId: userData?.currentChatAgentId,
		},
		loginUser: userData?.loginUser,
		permissions,
		startRating: userData?.rating,
		isAgentAvailable: isAgentAvailable,
	}

	if (userData.idKey) props.user = { ...props.user, ...userData.userInfo }

	if (isFetchingConversation || isFetching)
		return (
			<Flex justifyContent={'center'} mt={'2px'}>
				<Spinner color="red.500" justifyContent={'center'} alignItems={'center'} />
			</Flex>
		)

	return (
		
		<Skeleton isLoaded={isFetchingConversation ? false : true} w="100%">
		{/* {
			console.log(isFetchingConversation,scriptFetched, transcriptLoadedRefect,transcriptLoaded,userData.idKey,transScript, 99999)
		} */}
			<Box>
				{/* <pre>transcript :: {JSON.stringify(transScript,null,2)}</pre> */}
				{isBotConfig && !transcriptLoaded && (completedConversations || transScript) && (
					<Webchat
						{...props}
						conversation={completedConversations || transScript || []}
						onlyHistory={userData.idKey ? true : false}
						onLoadTranscript={onLoadTranscript}
						refetchTranscript={refetchTranscript}
						onShowConversation={onShowConversation}
					/>
				)}

				{isBotConfig && transcriptLoaded && (
					<Webchat
						{...props}
						conversation={transcriptLoadedRefect || transScript}
						onlyHistory={userData.idKey ? true : false}
						onLoadTranscript={onLoadTranscript}
						refetchTranscript={refetchTranscript}
						onShowConversation={onShowConversation}
					/>
				)}
			</Box>
		</Skeleton>
	)
})
