import React, { useRef, useState, useContext, useEffect, useCallback } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Select,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  ButtonGroup,
} from '@chakra-ui/react'
import { withTranslation } from 'react-i18next'
import { useEntity, useUpdateEntity } from '../apiHooks/agentLiveChat'
import AppContext from 'AppContext'
import { io } from 'socket.io-client'
import { getTenantUris } from '../../../../common/helpers/utils'
import config from '../../../../config'


const ChatReassign = ({ isOpen, onClose, loginUser, permissions, agentConversationId, handleChatAssigment, chatUserConversationId, userIncomingChatRequestId, botUserSessionId, currentAgentId, chatAgentId, onShowConversation, t }) => {
  const [queueName, setQueueName] = useState(null)
  const [agentId, setAgentId] = useState(null)
  const [chatData, setChatData] = useState(null)
  const toast = useToast()
  const { identity, appData } = useContext(AppContext)
  const tenantConfig = appData?.global?.tenantSettings?.find(tc => tc.name === 'liveChat')
  const isQueueOn = tenantConfig?.properties?.agent_queue == 1 ? true : false
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const openPopover = () => setIsPopoverOpen(!isPopoverOpen)
  const closePopover = () => setIsPopoverOpen(false)
  const [currentAssociatedAgent, setCurrentAssociatedAgent] = useState(null)
  const [activeAgentUserfullName, setActiveAgentUserfullName] = useState('')
  const [availableAgents, setAvailableAgents] = useState([])

  // Function to trigger the event
  // const triggerReassignToAgentEvent = (tenantId, eventData) => {
  //   
  //   const sockets = {}
  //   const socketBaseUrl = getTenantUris().socketUrl || config?.socketUrl
  //   if (sockets[tenantId]) return true
  //   let socketUrl = tenantId ? `${socketBaseUrl}/${tenantId}` : socketBaseUrl
  //   const socket = io(socketUrl, { path: '/api/v1/ws/socket.io' })
  //   if (socket) socket.emit('REASSIGN_TO_AGENT', eventData)
  // }
  const { data: agentQueueList } = useEntity({
    reqPath: 'active_agents/getAgentQueueList',
    resourceName: 'agentQueueList',
    method: 'POST',
    payload: {
      userName: loginUser?.preferred_username || loginUser?.preferred_username,
    },
    options: {
      enabled: permissions?.reasignChat ? (isQueueOn ? true : false) : false,
    },
  })

  const { data: activeAgentList, refetch: reftechActiveAgent } = useEntity({
    reqPath: `active_agents/getActiveAgents/${queueName}`,
    resourceName: 'agentQueueList',
    options: {
      enabled: permissions?.reasignChat,
    },
  })


  const { data: activeAgentUser} = useEntity({
    reqPath: 'active_agents/activeAgentUser',
    options: {
        enabled: !!permissions.livechatUsers || !!permissions?.liveChatuserReadOnly,
        refetchOnWindowFocus: true,
    },
  })

  useEffect(() => {
		if (activeAgentList && activeAgentUser && agentId) {
			setActiveAgentUserfullName(activeAgentUser.data[agentId][0].agentName)
		}
	}, [activeAgentList, activeAgentUser, agentId, activeAgentUserfullName])

  const { mutate, data: chatAssignment, isLoading: isSumitting, isSuccess } = useUpdateEntity({ resourceName: 'chatAssignment' })

  const btnRef = useRef()

  const handleQueueChange = e => {
    const queueName = e.target?.value
    setQueueName(queueName)
    setAgentId(null)
  }
  useEffect(() => {
    let currentAgent = activeAgentList?.filter(a => a.agentDbId == chatAgentId)
    currentAgent && setCurrentAssociatedAgent(currentAgent?.agentId)
  }, [reftechActiveAgent, activeAgentList, chatAgentId])

  useEffect(() => {
    reftechActiveAgent()
  }, [queueName, reftechActiveAgent])

  const handleAgentChange = e => {
    const agentId = e.target?.value
    setAgentId(agentId)
  }

  const toggleClose = useCallback(() => {
    setQueueName(null)
    setAgentId(null)
    onClose()
    closePopover()
  }, [])

  const isAssignment = agentConversationId ? true : false

  const handleChatAssignAgent = useCallback(() => {
    closePopover()
    if (isQueueOn && (!queueName || !agentId)) {
      toast({
        title: t('Chat Reassign'),
        description: t('Please select queue name and agent Name'),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })
      return
    } else if (!agentId) {
      toast({
        title: t('Chat Reassign'),
        description: t('Please select agent Name'),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })
      return
    }

    let agentDB = activeAgentList && activeAgentList.find(ag => ag.agentId === agentId)
    if (!agentDB) return

    let chatDataObj = {
      id: agentDB.agentDbId,
      userId: agentId, //this.state.chatAgentId,
      name: agentDB.userName, //userData.userName, //this.state.agentFullName || this.state.agentName,
      userName: agentDB.userName, //userData.userName, //this.state.agentName,
      agentFullName: activeAgentUserfullName || agentDB.userName, //userData.userName, //this.state.agentFullName,
      //agentConversationId: userData.agentBotConversationId, //this.state.agentConversationId,
      agentId: agentId, //this.state.chatAgentId,
      agentCheck: `Agent-${agentDB.agentId}`, //this.state.chatAgentId,
      chatUserConversationId: chatUserConversationId, //this.state.chatUserConversationId,
      userIncomingChatRequestId: userIncomingChatRequestId,
      botUserSessionId: botUserSessionId
    }
    if (isAssignment) {
      //   mutate({
      //     entityUrlPaths: {
      //       url: `/active_agents/handOverFromBot/${agentDB.agentDbId}/${agentConversationId}/${userIncomingChatRequestId}/${botUserSessionId}/${chatUserConversationId}/${agentDB.userName}`,
      //       method: 'GET',
      //     },
      //     payload: {},
      //   })
      // }
      // else 
      mutate({
        entityUrlPaths: { url: `active_agents/reassignAgent`, method: 'POST' },
        payload: {
          user_incoming_chat_request_id: userIncomingChatRequestId,
          activeAgentDbId: agentDB.agentDbId,
          activeAgentId: agentDB.agentId || agentId,
          chatAgentId: currentAssociatedAgent || currentAgentId,
          selectedAgentUserName: agentDB.userName,
          selectAgentFullName: agentDB.userName,
          queueName: queueName,
        },
      })
      handleChatAssigment({ chatData: chatDataObj, isAssignment: true })
      onShowConversation()
      setTimeout(() => {
        setCurrentAssociatedAgent(currentAgentId || agentDB.agentId)
      }, 1000)
      toast({
        title: t('Chat Assignment'),
        description: t(`Chat has been assigned to ${chatDataObj.agentFullName}`),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })
    }

    else {
      handleChatAssigment({ chatData: chatDataObj, isAssignment: false })
      toggleClose()
      toast({
        title: t('Chat Assignment'),
        description: t(`Chat has been assigned to ${chatDataObj.agentFullName}`),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })
    }
    // setChatData(chatDataObj)
    // triggerReassignToAgentEvent('eca392c0-d918-4773-893d-185277189083',chatDataObj )

  }, [currentAgentId, activeAgentList, agentId, botUserSessionId, chatUserConversationId, isQueueOn, mutate, queueName, toast, userIncomingChatRequestId, isAssignment, handleChatAssigment, toggleClose, activeAgentUserfullName])

  useEffect(() => {
    if (chatAssignment) {
      if (!chatAssignment) return
      if (chatAssignment?.error) {
        toast({
          title: t('Failed to Assign Chat'),
          description: t(chatAssignment?.msg ? chatAssignment?.msg : 'Cannot Able to Assign Chat'),
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      } else {
        handleChatAssigment({ chatData , isAssignment: false })
        toggleClose()
        toast({
          title: t('Chat Assignment'),
          description: t('Chat has been assigned /or reassigned now'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      }
    }
  }, [chatAssignment])


  // useEffect(() => {
  //   if (activeAgentList?.length && chatAgentId) {
  //     const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  //     const agentIdbyuuid = uuidPattern.test(chatAgentId)
  //     if (agentIdbyuuid) {
  //       setAvailableAgents(activeAgentList.filter(agent => agent.agentId !== chatAgentId))
  //     } else {
  //       setAvailableAgents(activeAgentList.filter(agent => agent.agentDbId !== chatAgentId))
  //     }
  //     // let listAgent = activeAgentList.filter(agent => agent.agentId !== chatAgentId)
  //     // console.log(listAgent)
  //     // setAvailableAgents(listAgent)
  //   }
  // },[activeAgentList, chatAgentId, currentAssociatedAgent, agentQueueList, queueName])
  const btnText = isAssignment ? 'Chat Re-Assign' : 'Chat Assign'

  const fontSize = '12'
  
  console.log(activeAgentUser,activeAgentList,activeAgentUser,chatAgentId,queueName,999999)
  let AvailableAgents = activeAgentList?.filter(agent => agent.agentDbId !== chatAgentId)
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={toggleClose} finalFocusRef={btnRef}>
      <DrawerOverlay />
      <DrawerContent>
        {/* <DrawerCloseButton /> */}
        <DrawerHeader fontSize={'14'}>Select an agent to Re-assign the chat</DrawerHeader>
        <DrawerBody>
          {isQueueOn && (
            <FormControl>
              <FormLabel htmlFor="queueName"> </FormLabel>
              <Select placeholder="---Select Queue---" onChange={handleQueueChange} value={queueName ? queueName : ''} fontSize={fontSize}>
                {agentQueueList &&
                  agentQueueList.map(queue => {
                    return (
                      <option value={queue.queueName} key={queue.queueName}>
                        {queue.queueLabel}
                      </option>
                    )
                  })}
              </Select>
              {/* {<FormHelperText fontSize={fontSize}>Enter the queue name</FormHelperText>} */}
            </FormControl>
          )}
          <FormControl>
            <FormLabel htmlFor="agentId"> </FormLabel>
            <Select placeholder="---Select Available Agent---" onChange={handleAgentChange} value={agentId ? agentId : ''} fontSize={fontSize} size="sm">
              {AvailableAgents &&
                AvailableAgents.map(agent => {
                  return (
                    <option value={agent.agentId} key={agent.agentId}>
                      {agent.userName}
                    </option>
                  )
                })}
            </Select>
            {/* {<FormHelperText fontSize={fontSize}>Enter the agent name</FormHelperText>} */}
          </FormControl>
          <FormControl mt={'10px'} display={'flex'} justifyContent={'right'}>
            <Button variant="outline" mr={3} onClick={toggleClose} fontSize={fontSize} size="xs" colorScheme="green">
              Cancel
            </Button>
            <Popover isOpen={isPopoverOpen} onClose={closePopover}>
              <PopoverTrigger>
                <Button fontSize={fontSize} size="xs" colorScheme="green" onClick={openPopover}>
                  {btnText}
                </Button>
              </PopoverTrigger>
              <PopoverContent color="white" bg="blue.800" borderColor="blue.800" alignItems={'center'} fontSize={fontSize}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Confirmation!</PopoverHeader>
                <PopoverBody>Are you sure you want to assign chat?</PopoverBody>
                {/* <ButtonGroup > */}
                <Button
                  colorScheme="green"
                  title={btnText}
                  onClick={handleChatAssignAgent.bind(this)}
                  isLoading={!!isSumitting}
                  loadingText="Submitting"
                  fontSize={fontSize}
                  size="xs"
                >
                  {'Yes'}
                </Button>
                {/* </ButtonGroup> */}
              </PopoverContent>
            </Popover>
          </FormControl>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
export default withTranslation()(ChatReassign)
