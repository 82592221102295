import { produce } from 'immer'
import _ from 'lodash'
import { Logout, NotificationsComponent, ProfileComponent, SupportComponent } from './common/containers'
import { AppRoute, Apps, GroupedLink, PathRoute, Roles, RouteData } from './common/router'
import ListComponent from './containers/agentlivechat/inbox/agentContainer'
import { botSettings } from './containers/agentlivechat/settings'

// Icons
import {
	RiInboxFill,
	RiListSettingsFill,
	RiDashboard2Fill,
	RiChatCheckFill, RiSettings5Fill , RiQuestionFill
} from 'react-icons/ri'

import {
	FaBlackTie,
} from 'react-icons/fa'
import { HiOutlineUserGroup, HiOutlineWrench, HiOutlineTicket} from "react-icons/hi2"
import { BsSliders } from "react-icons/bs"
import { MdOutlineSupportAgent } from "react-icons/md"
import { TfiComments } from "react-icons/tfi"
import { FaRandom, FaFileAlt, FaUserPlus,FaBook,FaCogs,FaCog } from "react-icons/fa"
import { SlGraph } from "react-icons/sl"
import { ImTicket } from "react-icons/im"
import { AiOutlineDashboard } from "react-icons/ai"
const { asc_admin, LiveChatAgent, LiveChatSuperviser, LiveChatAdmin } = Roles

const appRoutes: AppRoute[] = []

//  ---------------------- ROUTE DEFINITIONS ----------------------

// Other Apps:
//
// Agent
//
const agentRoles = [asc_admin, LiveChatAgent, LiveChatSuperviser, LiveChatAdmin]
appRoutes.push({
	app: Apps.LiveChat,
	children: [
		{ type: 'pathRoute', isRedirect: true, isVisible: true, path: '/botmanagement',
		 title: 'Virtual Agent Mgmt', icon: HiOutlineUserGroup, roles: ['bot_analyst', 'bot_admin'] },
		{ type: 'pathRoute', isRedirect: true, isVisible: true, path: '/catalog', 
		title: 'Service Catalog', icon: HiOutlineWrench, roles: ['asc_admin'] },
		{ type: 'pathRoute', isRedirect: true, isVisible: false, path: '/approvals', 
		title: 'Requests Management', icon: HiOutlineWrench, roles: ['approver', 'requestor'] },
		{ type: 'pathRoute', isRedirect: true, isVisible: true, path: '/agent/inbox', 
		title: 'Ticket Management', icon: HiOutlineTicket, roles: ['ticket_user', 'ticket_agent', 'ticket_agent_supervisor', 'ticket_admin'] },
		{
			type: 'pathRoute',
			path: '/mychats',
			title: 'My Chat',
			icon: RiChatCheckFill,
			Component: ListComponent,
			isVisible:false,
			roles: agentRoles
		},
		{
            type: 'pathRoute', path: '/', title: 'Live Chat',className:'live-chat-link', icon: TfiComments, Component: ListComponent, 
			roles: [...agentRoles], isVisible: true, children: [
					{
				type: 'pathRoute',
				path: '/mychats',
				title: 'My Chat',
				icon: RiQuestionFill,
				Component: ListComponent,
				isVisible:true,
				roles: agentRoles
			},
			{
				type: 'pathRoute', path: '/', className:'live-chat-link', title: 'Settings', icon: RiListSettingsFill, Component: ListComponent,
				 roles: ['chat_agent_admin'], isVisible: true, children: [
					{
						type: 'pathRoute', path: '/livechat/live_chat_setting', 
						title: 'Queues', isRedirect:true, Component: ListComponent,
						roles: ['chat_agent_admin'], 
					},
					{
						type: 'pathRoute', path: '/livechat/emailTemplates', 
						title: 'Email Templates', isRedirect:true, Component: ListComponent,
						roles: ['chat_agent_admin'], 
					},
					{
						type: 'pathRoute', path: '/livechat/emailConfiguration/general', 
						title: 'Email Configuration', isRedirect:true, Component: ListComponent,
						roles: ['chat_agent_admin'], 
					},
					{
						type: 'pathRoute', path: '/livechat/unavailabilityReason', 
						title: 'Unavailability Reasons', isRedirect:true, Component: ListComponent,
						roles: ['chat_agent_admin'], 
					}


				]},
				{
					type: 'pathRoute',
					isRedirect:true,
					path: '/livechat/monitor-chats',
					title: 'Monitor Chats',
					className:'monitor-chats',
					icon: FaBook,
					Component: botSettings,
					isVisible:true,
					roles: ['chat_agent_supervisor']
				},
				
				{
					type: 'pathRoute', path: '/', title: 'Live Chat Dashboard', className:'live-chat-link', icon: FaCogs, Component: ListComponent,
					 roles: ['chat_agent_supervisor'], isVisible: true, children: [
						{
							type: 'pathRoute', path: '/livechat/LiveChatKPI', 
							title: 'Live Chat KPI', isRedirect:true, Component: ListComponent,
							roles: ['chat_agent_supervisor'], 
						},
						{
							type: 'pathRoute',  path: '/livechat/RealTimeInsights', 
							title: 'RealTime Insights', isRedirect:true, Component: ListComponent,
							roles: ['chat_agent_supervisor'], 
						},
	
	
					]},
				{
					type: 'pathRoute',
					path: '/livechat/review-chat',
					title: 'Review Chat',
					icon: AiOutlineDashboard,
					Component: botSettings,
					isRedirect:true,
					isVisible:true,
					roles: ['chat_agent_supervisor']
				},
				{
					type: 'pathRoute',
					path: '/livechat/canned-responses',
					title: 'Canned Responses',
					className:'monitor-chats',
					icon: FaBook,
					Component: botSettings,
					isRedirect:true,
					isVisible:true,
					roles: ['chat_agent_supervisor', 'chat_agent_admin', 'expert']
				},
				{
					type: 'pathRoute',
					path: '/livechat/reports',
					title: 'Reports',
					icon: SlGraph,
					Component: botSettings,
					isRedirect:true,
					isVisible:true,
					roles: ['coach',
					'tenant_admin',
					'content_author',
					'content_reviewer',
					'program_director',
					'expert',
					'chat_agent_supervisor',
					'chat_agent_admin',
					'bot_analyst',]
				},
				{
					type: 'pathRoute',
					path: '/livechat/requests',
					title: 'Requests',
					icon: FaUserPlus,
					isRedirect:true,
					Component: botSettings,
					isVisible:true,
					roles: ['coach',
					'tenant_admin',
					'content_author',
					'content_reviewer',
					'program_director',
					'expert',
					'chat_agent_supervisor',
					'chat_agent_admin',
					'bot_analyst',]
				},
			{
				type: 'pathRoute',
				path: '/settings/botSettings',
				title: 'Bot Settings',
				icon: FaCog,
				Component: botSettings,
				isVisible:true,
				roles: [LiveChatAdmin]
			}
			]
			},
		{ type: 'pathRoute', isRedirect: true, isVisible: true, path: '/learning/program', 
		title: 'Learning Mgmt', icon: FaBlackTie, roles: ['trainee', 'content_author', 'coach', 'expert'], className: 'learning' },
		{ type: 'pathRoute', isRedirect: true, isVisible: true, path: '/user-management', 
		title: 'Admin App', icon: BsSliders, roles: ['user_admin', 'tenant_admin'], className: 'admin-app' },
		{ type: 'pathRoute', isRedirect: true, isVisible: true, path: '/virtualagent', 
		title: 'Virtual Agent', icon: MdOutlineSupportAgent, roles: ['bot_user'], className: 'virtual-agent' },
	],
})

// Other Apps
//
// appRoutes.push({ app: Apps.Agent, children: [] })
appRoutes.push({ app: Apps.User, children: [] })
appRoutes.push({ app: Apps.Config, children: [] })


// These are not visually rendered, but added to the Router
const moreRoutes: RouteData[] = [
	// { type: 'frameworkRoute', path: '/notifications', Component: NotificationsComponent, roles: [] },
	// { type: 'frameworkRoute', path: '/support',       Component: SupportComponent,       roles: [] },
	// { type: 'frameworkRoute', path: '/profile',       Component: ProfileComponent,       roles: [] },
	{ type: 'frameworkRoute', path: '/logout', Component: Logout, roles: [] },
]

//
// ---------------------- END OF ROUTE DEFINITIONS ----------------------


// --------------------- DO NOT EDIT BELOW THIS LINE ---------------------
//
//  If you are only here to add a route definition,
//  The rest of this file is just processing and converting above data into
//  a format that Sidebar and RootRouter can easily consume.
//
//
const formattedAppRoutes = _.map(appRoutes, ({ app, children }) => {
	// Recursively prefix all routes
	const prefixRoutes = (prefix: any, routes: any) => {
		_.each(routes, route => {
			if (route?.path && !route?.isRedirect) {
				route.path = `${prefix}${route.path}`
				if (route.children) {
					prefixRoutes(route.path, route.children)
				}
			} else if (route.type === 'grouped' && route.path) {
				route.path = `${prefix}${route.path}`
			}
		})
	}
	prefixRoutes(app.path, children)

	// The app root path will route to the first child
	//
	const firstChild = _.find(children, c => c.type === 'pathRoute')
	const redirectPath = (firstChild && firstChild.type === 'pathRoute') ? firstChild.path : undefined

	return {
		...app,
		isApp: true,
		redirectPath,
		children,
	}
})

// Inject the groups data into all routes
//
type GroupedLinksData = { [key: string]: GroupedLink[] }
type CreateRoutesProps = {
	groupedLinksData: GroupedLinksData,
}
const createRoutes = ({ groupedLinksData }: CreateRoutesProps) => {
	if (!groupedLinksData || _.isEmpty(groupedLinksData)) {
		return null
	}
	const routes = produce(formattedAppRoutes, _appRoutes => {
		_.each(_appRoutes, appRoute => {
			const { isApp, children } = appRoute
			if (isApp) {
				let parent: string = ""
				_.each(children, route => {
					if (route && route.children) {
						_.each(route.children, childRoute => {
							if (childRoute.type === 'subHeading') {
								parent = childRoute.subheading

							} else if (childRoute.type === 'sidebarBreak') {
								parent = ""

							} else if (childRoute.type === 'grouped') {
								const { dataId, path, sublinks } = childRoute
								const groups = groupedLinksData[dataId]
								if (groups) {
									const groupItems = _.map(groups, groupItem => {
										const { id } = groupItem
										groupItem.links = groupItem.links || []
										_.each(sublinks, sublink => {
											const link: PathRoute = _.cloneDeep(sublink)
											link.path = `${path}/${id}${link.path}`
											groupItem.links.push(link)
										})

										return _.cloneDeep(groupItem)
									})

									childRoute.children = groupItems
								}

							} else if (childRoute.type === 'pathRoute') {
								childRoute.parent = parent
							}
						})
					}
					if (route.type === 'subHeading') {
						parent = route.subheading

					} else if (route.type === 'sidebarBreak') {
						parent = ""

					} else if (route.type === 'grouped') {
						const { dataId, path, sublinks } = route
						const groups = groupedLinksData[dataId]
						if (groups) {
							const groupItems = _.map(groups, groupItem => {
								const { id } = groupItem
								groupItem.links = groupItem.links || []
								_.each(sublinks, sublink => {
									const link: PathRoute = _.cloneDeep(sublink)
									link.path = `${path}/${id}${link.path}`
									groupItem.links.push(link)
								})

								return _.cloneDeep(groupItem)
							})

							route.children = groupItems
						}

					} else if (route.type === 'pathRoute') {
						route.parent = parent
					}
				})
			}
		})
	})

	const allRoutes = [
		...routes,
		...moreRoutes,
	]
	return allRoutes
}
export { createRoutes }
