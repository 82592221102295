import {
    Avatar,
    Box,
    Button,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Switch,
    Text,
    Textarea,
    useDisclosure,
    useMediaQuery,
    useToast
} from '@chakra-ui/react'
import React, { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { BiArrowFromLeft, BiArrowFromRight, BiEnvelope } from 'react-icons/bi'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import ToggleIcon from '../../../common/components/sidebar/sub-components/ToggleIcon'
import lazy from '../../_template/lazy'
import InboxComponent from './InboxComponent'
import Tabs from './tabs'
import { askPermission } from './webNotification'

import AppContext from 'AppContext'
import produce from 'immer'
import _, { debounce } from 'lodash'
import useSound from 'use-sound'
import boopSfx from '../../../assets/bell-notification.wav'
import { ThemeContext } from '../../../common/ui'
import config from '../../../config'
import socketModel from '../socket/socket.io'
import { updateItem, useEntity, useUpdateEntity } from './apiHooks/agentLiveChat'
import DataProcessor from './helpers/DataProcessor'
import InitialState from './helpers/InitialState'
import { RolesMeta } from './helpers/fnRolesMeta'

const RightPanel = lazy(() => import('./rightPanel'))
let socketGlobalInst
const AgentContainer = React.memo(props => {
    const { caseId } = useParams()
    const { theme } = useContext(ThemeContext)
    const [selectedTabId, setselectedTabId] = useState('')
    const [tabs, setTabs] = useState([])
    const [itemType, setItemType] = useState('default')
    const [useCase, setUseCase] = useState('james')
    const { t } = props
    const toast = useToast()
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const [isAgentAvailable, setIsAgentAvailable] = useState(false)
    const { identity, appData, tenant } = useContext(AppContext) 
    const { profile, userInfo } = identity
    const [incomingChat, setIncomingChat] = useState([])
    const [newIncomingChat, setNewIncomingChat] = useState([])
    const [socketInfo, setSocketInfo] = useState({ type: null })
    const [agentInfo, setAgentInfo] = useState(null)
    const [rightProfileUser, setRightProfileUser] = useState(null)
    const [showConversation,setShowConversation] = useState(false)
    const [uiFlag, setUiFlag] = useState(true)
    const [queueListSuccessBySocket, setueueListSuccessBySocket] = useState([])
    const [isTeams, setIsTeams] = useState(false)

    const [bulkList, setBulkList] = useState([]) 
    const { isOpen, onOpen, onClose } = useDisclosure()
    const permissions = RolesMeta({ userRoles: identity?.profile?.roles || [] }) 
    // const uiFeature = useMemo(() => {
    //     if (appData && appData.global && appData.global.tenantSettings) {
    //         let filterFeature = appData.global.tenantSettings.find(x => x.configType === 'uiFeature' && x.name === 'liveChat')
    //         return filterFeature.properties
    //     } else return null
    // }, [appData])
 
    const [playSound] = useSound(boopSfx)
    const [isNotify, setIsNotify] = useState(true)
    const [isNotifySound, setIsNotifySound] = useState(true)
    const onShowConversation = useCallback(()=>{
        setShowConversation(true)
    },[])
    const updateNotificationStatus = useCallback((notifyingOff, notifySoundOff) => {
        setIsNotify(notifyingOff ? false : true)
        setIsNotifySound(notifySoundOff ? false : true)
    }, [])
    const { QueryKeys } = InitialState
    //Added new socket

    const { data: agentQueueList, isSuccess: queueListSuccess } = useEntity({
        reqPath: 'active_agents/getAgentQueueList',
        resourceName: QueryKeys?.AGENT_QUEUE_LIST,
        method: 'POST',
        payload: {
            userName: profile?.preferred_username || userInfo?.preferred_username,
        },
        options: {
            enabled: !!permissions.livechatUsers || !!permissions?.liveChatuserReadOnly,
        },
    })

    //get Case Fields

    const onUseCaseChange = useCallback(uCase => {
        setUseCase(uCase)
    }, [])

    //get caseSectionField

 

    //TAB CLOSING MENU------------------------------------
    const closeAllTabs = useCallback(
        tabId => {
            let tabItems
            switch (tabId) {
                case 'all':
                    setTabs([])
                    break
                case 'current':
                    tabItems = tabs.filter(t => !t.isSelectedTab)
                    tabItems = tabItems?.map((el, i) => {
                        let elem = { ...el }
                        if (i == tabItems.length - 1) {
                            elem.isSelectedTab = true
                            elem.isTabClick = false
                            setselectedTabId(elem.name)
                        }
                        return elem
                    })
                    let newTabs = tabItems.slice().sort((a, b) => Number(b.isSelectedTab) - Number(a.isSelectedTab))
                    setTabs(newTabs)
                    break

                case 'keep_current':
                    tabItems = tabs.filter(t => t.isSelectedTab)
                    setTabs(tabItems)
                    break
                default:
                    break
            }
        },
        [tabs]
    )
    // ---------------------------------------------------

    const fnRemoveTab = useCallback(
        ({ tabId }) => {
            let tabIndex = _.findIndex(tabs, tab => tab.name == tabId)
            let newTabs = tabs.filter(tab => tab.name != tabId)
            let tabItem = tabs.find(tab => tab.name === tabId)

            let nextActiveTab = null
            if (tabId === selectedTabId && tabItem && tabItem.component != 'agentChatbot') {
                nextActiveTab = newTabs.length > 1 && newTabs[tabIndex - 1]
                if (!nextActiveTab && newTabs.length > 0) nextActiveTab = newTabs[0]
                if (!_.isEmpty(nextActiveTab)) {
                    setselectedTabId(nextActiveTab.name)
                    newTabs = produce(newTabs, draftState => {
                        let currentTab = draftState.find(current => current.name === nextActiveTab.name)
                        currentTab.isSelectedTab = true
                    })
                } else {
                    newTabs = newTabs.map((tab, index) => {
                        let tabDataVal = { ...tab }
                        if (index === 0) tabDataVal.isSelectedTab = true
                        else tab.isSelectedTab = false
                        return tabDataVal
                    })
                }
            } else {
                newTabs = newTabs.map((tab, index) => {
                    let tabDataVal = { ...tab }
                    if (index === 0) {
                        tabDataVal.isSelectedTab = true
                        setselectedTabId(tabDataVal.name)
                    } else tabDataVal.isSelectedTab = false
                    return tabDataVal
                })
            }
            setTabs(newTabs)
            return newTabs
        },
        [selectedTabId, tabs]
    )

    const queryResults = [] //useRecentConversation({ username: 'adminuser', agentName: 'adminuser', days: 10 })
    let convData = queryResults.map(query => query.data)
    const onRowSelect = useCallback(
        item => {
            let tabData
            let additionalData = {
                ...item.agentData,
                userFullName: item.userFullName,
                botUserConversationId: item.botUserConversationId || item.userBotConversationId,
                botUserSessionId: item.customerSessionId || item.userChatSessionId,
                endUserId: item.userIncomingChatRequestId || item.id,
                agentBotConversationId: item.agentBotConversationId,
                loginUser: identity?.profile,
                currentChatAgentId: item.agentId,
                currentAgentId: item.agentUid || item.id,
            }
            tabData = {
                title: item.idKey ? 'Conversation Chat' : 'Live Chat',
                name: item.idKey || item.botUserConversationId || item.userBotConversationId,
                isSelectedTab: true,
                component: 'agentChatbot',
                data: item.idKey ? item : additionalData,
                rowData: item,
                itemType: item.idKey ? 'conversationChat' : 'liveChat',
            }



            if (_.isEmpty(tabData.name)) return true

            let agentComponent = item && (item.botUserConversationId || item.idKey) && _.find(tabs, itemComponent => itemComponent.component === 'agentChatbot')
            //  agentComponent = item && item.idKey && _.find(tabs, itemComponent => itemComponent.component === 'conversationChat')
            let currentTabs = tabs
            if (agentComponent) currentTabs = fnRemoveTab({ tabId: agentComponent.name })
            setTimeout(() => {
                let isTab = _.find(currentTabs, itemTab => itemTab.name === tabData.name)
                let newTabs = []
                if (!isTab) {
                    newTabs = produce(currentTabs, draft => {
                        draft.push(tabData)
                        if (currentTabs && currentTabs.length > 0) {
                            _.map(draft, item => {
                                if (item.name === tabData.name) item.isSelectedTab = true
                                else item.isSelectedTab = false
                                return item
                            })
                        }
                    })
                } else {
                    newTabs = produce(currentTabs, draft => {
                        if (currentTabs && currentTabs.length > 0) {
                            _.map(draft, item => {
                                if (item.name === tabData.name) item.isSelectedTab = true
                                else item.isSelectedTab = false
                                return item
                            })
                        }
                    })
                }
                newTabs = newTabs.slice().sort((a, b) => Number(b.isSelectedTab) - Number(a.isSelectedTab))
                setTabs(newTabs)
                setselectedTabId(tabData.name)
                setItemType('default')
            }, 10)
        },
        [tabs, fnRemoveTab, identity]
    )

    const fnTabChange = useCallback(({ tabId, isTabClick }) => {
        if (!tabId) return
        setselectedTabId(tabId)
        setTabs(
            produce(draft => {
                _.map(draft, item => {
                    if (item.name === tabId) {
                        item.isSelectedTab = true
                        item.isTabClick = isTabClick
                    } else item.isSelectedTab = false

                    return item
                })
            })
        )
    }, [])
    const openToast = useCallback(
        ({title,description,status}) => {
            toast({
                title : t ? t (title) : title || 'Live Chat',
                description : t ? t (description) : description || 'Updated Successfully',
                status: status || 'success',
                duration: 3000,
                position: "top-right",
                isClosable: true,
            })
        },
        [toast,t],
    )
 

    const onItemTypeChange = useCallback(iType => {
        setItemType(iType)
    }, [])
    const mobileCss = isMobile
        ? {
              position: 'fixed',
              top: '0',
              bottom: '0',
              marginTop: '0',
              zIndex: 2,
              height: '100%',
              right: '0px',
              width: 'calc(100% - 76px)',
          }
        : {}

    const handleBackToList = useCallback(() => {
        let tabItems = tabs
        if (tabItems.length > 0) setTabs([])
    }, [tabs])

    const fnNotifyAndSound = useCallback(() => {
        isNotify && askPermission(true)
        isNotifySound && playSound()
    }, [isNotify, isNotifySound, playSound])

    // socket Implementations start //

    useEffect(() => {
        if (socketInfo && socketInfo.type && socketInfo.chatInfo) {
            const { type, chatInfo, queueList } = socketInfo
            let newIncomingusers = []
            switch (type) {
                case 'disconnect':
                    fnRemoveTab({ tabId: chatInfo.botUserConversationId })
                    newIncomingusers = incomingChat.filter(icu => icu.id != chatInfo.id)
                    setIncomingChat(newIncomingusers)
                    openToast({
                        description: t(`${incomingChat.username || 'Chat User'} has been disconnected`),
                        title: t('User Disconnected'),
                        status: 'info',
                    })
                    break
                case 'incomingUserRemove':
                    fnRemoveTab({
                        tabId: chatInfo.bot_user_conversation_id || chatInfo.botUserConversationId,
                    })
                    newIncomingusers = incomingChat.filter(icu => icu.id != chatInfo.id)
                    setIncomingChat(newIncomingusers)
                    break

                default:
                    break
            }
            setSocketInfo({ type: null })
        }
    }, [socketInfo, fnRemoveTab, openToast, incomingChat, fnNotifyAndSound,t])


    const { data: incomingUsers, refetch: incomingRefetch } = useEntity({
        reqPath: 'active_agents/getIncomingUsers',
        resourceName: QueryKeys?.GET_INCOMING_USER,
        options: {
            enabled: !!permissions.livechatUsers || !!permissions?.liveChatuserReadOnly,
            refetchOnWindowFocus: true
        },
    })

    const { data: activeAgentUser, refetch: activeAgentUserRefetch } = useEntity({
        reqPath: 'active_agents/activeAgentUser',
        resourceName: QueryKeys?.GET_ACTIVE_AGENT,
        options: {
            enabled: !!permissions.livechatUsers || !!permissions?.liveChatuserReadOnly,
            refetchOnWindowFocus: true,
        },
    })

    useEffect(() => {
        let incomingUsersList =
            !_.isEmpty(incomingUsers) && incomingUsers.data
                ? incomingUsers.data.filter(inc => {
                      if (agentInfo && isAgentAvailable && inc.agentId != agentInfo.id && !_.isEmpty(inc.agentBotConversationId)) return false
                      else return true
                  })
                : []
        let activeAgentUserList = DataProcessor.setActiveAgentUserData({
            data: activeAgentUser?.data,
        })
        activeAgentUserList = activeAgentUserList.filter(inc => {
            if (permissions.agentAvailable) {
                if (agentInfo && inc.agentId == agentInfo.agentId && inc.agentBotConversationId) return true
                else return false
            } else if (permissions.liveChatuserReadOnly) return true
            else return false
        })
        incomingUsersList = _.concat(incomingUsersList, activeAgentUserList)

        incomingUsersList = DataProcessor.formatIncomingUsers({
            data: incomingUsersList,
            queueList: agentQueueList || [],
        })
        Array.isArray(incomingUsersList) && setIncomingChat(incomingUsersList)
        if(incomingUsersList?.length==0){
            setTabs([])
        }
    }, [incomingUsers, agentQueueList, agentInfo, isAgentAvailable, activeAgentUser])

    const incomingUserHook = useCallback(
        newData => {
            
            
            let isExistChat = incomingChat?.find(ic => ic?.id == newData?.id)
            if (!isExistChat) {
                let nextChat =
                    !_.isEmpty(newData) &&
                    DataProcessor.formatIncomingUsers({
                        data: newData,
                        queueList: agentQueueList || [],
                    })
                if (nextChat && Array.isArray(nextChat)) {
                    setNewIncomingChat([...nextChat])
                    fnNotifyAndSound() 

                    openToast({
                        description: `Incoming Chat Request ${newData.userFullName}`,
                        title: 'User Incoming Chat',
                        status: 'success',
                    })
                    // setNewIncomingChat(nextChat)
                }
                // else if(nextChat && nextChat?.id){
                //   let items = incomingChat.push(nextChat)
                //   setIncomingChat(items)
                //   fnNotifyAndSound()
                // }
            }
        },
        [fnNotifyAndSound, incomingChat]
    )

    useEffect(() => {
        if (newIncomingChat && newIncomingChat.length > 0) {
            let chatItem = newIncomingChat[0]
            let isExistChat = incomingChat?.find(ic => ic?.id == chatItem?.id)
            if (!isExistChat) {
                setIncomingChat([...incomingChat, ...newIncomingChat])
                setNewIncomingChat([])
            }
        }
    }, [newIncomingChat, incomingChat, agentQueueList])

    const connectUserHook = useCallback(
        newData => {
            
            if (agentInfo && agentInfo.id !== newData.agentId) {
                setSocketInfo(prevState => {
                    return {
                        ...prevState,
                        type: 'disconnect',
                        chatInfo: newData,
                    }
                })
            }
            setTimeout(() => {
                incomingRefetch()
                activeAgentUserRefetch()
            }, 2000)
        },
        [incomingRefetch, agentInfo, activeAgentUserRefetch]
    )

    const reassignUserHook = useCallback(
        newData => {
            
            let aId = newData?.data?.agentId || newData.agentId

            if (agentInfo && agentInfo.id == aId) {
                setSocketInfo(prevState => {
                    return {
                        ...prevState,
                        type: 'disconnect',
                        chatInfo: {
                            botUserConversationId: newData?.data?.userBotConversationId,
                        },
                    }
                }) 

                if (agentInfo && agentInfo.id !== newData.agentId && !permissions.liveChatuserReadOnly && newData.agentId === agentInfo.sub) {
                    openToast({
                        description: `Chat is assigned to you ${newData.name}`,
                        title: 'Chat Reassign',
                        status: 'success',
                    })
                }
            }
            setTimeout(() => {
                incomingRefetch()
                activeAgentUserRefetch()
            }, 2000)
        },
        [incomingRefetch, agentInfo, activeAgentUserRefetch]
    )

    const disconnectUserHook = useCallback(newData => {
        

        // setSocketInfo(prevState => {
        //   return {
        //     ...prevState,
        //     type: 'disconnect',
        //     chatInfo: newData,
        //   }
        // })
        fnRemoveTab({ tabId: newData.botUserConversationId })
        let currentIndex = incomingChat.indexOf(icu => (icu.id = newData.id))
        incomingChat.splice(currentIndex, 1)
        setIncomingChat(incomingChat)
        if (currentIndex > -1)
            openToast({
                description: `Chat User ${newData.agentId} has been disconnected`,
                title: 'User Disconnected',
                status: 'info',
            })
    }, [])

    const removeUserHook = useCallback(newData => {
        

        // setSocketInfo(prevState => {
        //   return {
        //     ...prevState,
        //     type: 'incomingUserRemove',
        //     chatInfo: newData,
        //   }
        // })
        fnRemoveTab({
            tabId: newData.bot_user_conversation_id || newData.botUserConversationId,
        })
        let newIncomingusers = incomingChat.filter(icu => icu.id != newData.id)
        setIncomingChat(newIncomingusers)
        openToast({
            description: `${newData.userFullName || newData.name || "User"} is been removed`,
            title: 'Removed Incoming Users',
            status: 'error',
        })
    }, [])

    const agentAvailabilityHook = useCallback(
        newData => {
            if (newData && newData.agentId && permissions.liveChatuserReadOnly) {
                openToast({
                    description: `${newData.userName ? newData.userName : 'Agent'} has come ONLINE`,
                    title: 'Agent is available',
                    status: 'info',
                })
            }
        }
    ,[agentInfo, incomingUsers,userInfo])

    const agentUnavailabilityHook = useCallback(
        newData => {

            if (newData && newData.agentId && permissions.liveChatuserReadOnly) {
                openToast({
                    description: `${newData.userName ? newData.userName : 'Agent'} has been OFFLINE`,
                    title: 'Agent is Unavailable',
                    status: 'info',
                })
            }

        }
    ,[agentInfo])

    const userChatUpdateHook = useCallback(
        newData => {
            
        }
    ,[userInfo])

    const idleagentHook = useCallback(
        newData => {
            
        }
    ,[])

    // const disconnectSocketHook = useCallback(
    //     newData => {
    //         toast({
    //             title: (`Socket Connection Lost ${newData}`),
    //             description: ('Lost connection to the server, reloading the page'),
    //             status: 'error',
    //             duration: 3000,
    //             isClosable: true,
    //             position: 'top-right',
    //         })
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 2000)
    //     }
    // ,[])

    const disconnectSocketHook = useCallback(
        debounce(
            newData => {
                toast({
                    title: (`Socket Connection Lost ${newData}`),
                    description: ('Lost connection to the server, reloading the page'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right',
                })
                setTimeout(() => {
                    window.location.reload()
                }, 4000)
            },
            3000, 
            { leading: true, trailing: false } 
        ), []
    )
    const registerSocket = useMutation(
        QueryKeys?.REGISTERALLSOCKET,
        updateItem,
        {
            onSuccess: (data) => {
                setueueListSuccessBySocket(data)
            }
        }

    )
    const {
        mutateAsync,
    } = registerSocket

    const registerAllSocket = async (username) => {
        let py = { username }
        await mutateAsync({
            payload: py,
            entityUrlPaths: {
                url: `active_agents/registerAllSocket`,
                method: "POST",
            },
        })
    }

    useEffect(() => {
        registerAllSocket(profile.preferred_username)
    },[profile])
    useEffect(() => {
        if (queueListSuccess) {
            if(showConversation){
                setTabs([])
                setShowConversation(false)
            }
            
            if (!socketGlobalInst) {
                socketGlobalInst = new socketModel(
                    profile?.tenantUid,
                    queueListSuccessBySocket,
                    agentQueueList,
                    incomingUserHook,
                    connectUserHook,
                    disconnectUserHook,
                    reassignUserHook,
                    removeUserHook,
                    agentAvailabilityHook,
                    agentUnavailabilityHook,
                    userChatUpdateHook, 
                    idleagentHook,
                    disconnectSocketHook
                )
            }
        }
    }, [agentQueueList, profile, queueListSuccess, incomingUserHook, connectUserHook, disconnectUserHook, reassignUserHook, removeUserHook, agentAvailabilityHook, agentUnavailabilityHook, userChatUpdateHook, idleagentHook, disconnectSocketHook, showConversation])


    const setIncomingUsersChat = useCallback(list => {
        setIncomingChat(list)
    }, [])

    useEffect(() => {
        let currentTab = tabs && tabs.length > 0 && tabs.find(tab => tab.isSelectedTab)
        if (currentTab) {
            let profileUser = DataProcessor.setCurrentUser({ data: currentTab })
            setRightProfileUser(profileUser)
        }
        setTimeout(() => {
            let activeTabs = tabs && tabs.filter(t => t.itemType !== 'liveChat')
            localStorage.setItem('activeTabs', JSON.stringify(activeTabs))
        }, 100)
    }, [tabs])

    useEffect(() => {
        isNotify && askPermission()
        let selectedTab = caseId
            ? [
                  {
                      title: caseId,
                      name: caseId,
                      isSelectedTab: true,
                      component: 'ticketDetail',
                      rowData: {},
                      itemType: 'ticketDetail',
                  },
              ]
            : []
        let activeTabs = JSON.parse(localStorage.getItem('activeTabs'))
        let isCaseEsixt = activeTabs && activeTabs.find(t => t.name === caseId)
        if (!isCaseEsixt) activeTabs = activeTabs ? [...activeTabs, ...selectedTab] : selectedTab
        if (selectedTab?.length > 0)
            activeTabs = activeTabs?.map(item => {
                if (item.name === caseId) item.isSelectedTab = true
                else item.isSelectedTab = false
                return item
            })
        activeTabs && setTabs(activeTabs)
    }, [])

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const queryParameters = Object.fromEntries(urlSearchParams.entries())
        const isTeamsParam = queryParameters.isTeams === 'true'
    
        setIsTeams(isTeamsParam)
      }, [])
 
    const onCheckboxChange = useCallback(
        (item, isBool) => {
            if (isBool && item) {
                if (Array.isArray(item)) setBulkList(item)
                else setBulkList(prevState => [...prevState, item])
            } else if (item) {
                if (Array.isArray(item)) setBulkList([])
                else {
                    const removeToList = bulkList.filter(x => x.id != item.id)
                    setBulkList(removeToList || [])
                }
            }
        },
        [bulkList]
    )
 

    const uiFlagChange = useCallback(
        evt => {
            setUiFlag(prev => !prev)
            if (!evt.target.checked && tenant && tenant.domain) {
                let redirectUrl = `https://${tenant.domain}/livechat`
                window.location.href = redirectUrl
            }
        },
        [tenant]
    )

    const [toggleExpended, setToggleExpended] = useState(true)
    const [toggleRightExpended, setToggleRightExpended] = useState(false)
    const [dynamicWidth, setDynamicWidth] = useState(0)
    const [dynamicRightWidth, setDynamicRightWidth] = useState(0)
    const handleToggleChange = useCallback(() => {
        if (toggleExpended) {
            setDynamicWidth(25)
            setToggleExpended(false)
        } else {
            setDynamicWidth(0)
            setToggleExpended(true)
        }
    }, [toggleExpended])
    const handleToggleRightChange = useCallback(() => {
        if (!toggleRightExpended) {
            setToggleRightExpended(true)
            setDynamicRightWidth(20)
        } else {
            setToggleRightExpended(false)
            setDynamicRightWidth(0)
        }
    }, [toggleRightExpended])
    const [resizeWidth, setResizeWith] = useState(0)
    const handleExpendCollapse = useCallback(() => {
        if (!toggleExpended && 30 - dynamicWidth >= 5) {
            setDynamicWidth(0)
            setToggleExpended(true)
        }
        let width = document.querySelector('.react-draggable')?.style?.width
        setResizeWith(width)
    }, [dynamicWidth, toggleExpended])

    const transitionTime = '0.3s'
    let actualDynamicWidth = dynamicWidth == 0 ? dynamicWidth + resizeWidth : dynamicWidth
    if (!toggleExpended) actualDynamicWidth = actualDynamicWidth - 5
    if (!toggleRightExpended) actualDynamicWidth = actualDynamicWidth - 5

    return (
        <Box>
            {/* <Box p={1} mb={1} mt={1} fontSize="13px" color="gray" d="flex" justifyContent={'space-between'} alignItems={'center'}>
                <Box></Box>
                <Box d="flex" justifyContent={'space-between'}>
                    {!isTeams &&(permissions.livechatUsers || permissions.liveChatuserReadOnly) && (
                        <Box mr={3}>
                            {t ? t('Switch to Preview') : 'Switch to Preview'} <Switch isChecked={uiFlag} value={!uiFlag} onChange={uiFlagChange} size="sm" />
                        </Box>
                    )}
                    <Box mr={3}>
                        <Feedback tenant={tenant} profile={profile} userInfo={userInfo} t={t} />
                    </Box>
                </Box>
            </Box> */}

            <Flex display={{ sm: 'block', md: 'flex' }} justifyContent="space-between">
                {/* Left side Panels for Ticket Details/Conversations/CreateTicket/AgentChat */}
                <InboxComponent
                    onCheckboxChange={onCheckboxChange}
                    onRowSelect={onRowSelect}
                    fnRemoveTab={fnRemoveTab}
                    t={t}
                    permissions={permissions}
                    agentAvailability={(availInfo, agentInfo) => {
                        setIsAgentAvailable(availInfo)
                        setAgentInfo(agentInfo)
                    }}
                    incomingChat={incomingChat}
                    newIncomingChat={newIncomingChat}
                    agentQueueList={agentQueueList}
                    setIncomingUsersChat={setIncomingUsersChat}
                    isMobile={isMobile} 
                    updateNotificationStatus={updateNotificationStatus}
                    isNotify={isNotify}
                    isNotifySound={isNotifySound}
                    dynamicWidth={dynamicWidth}
                    toggleExpended={toggleExpended}
                    transitionTime={transitionTime}
                    handleToggleChange={handleToggleChange}
                    handleExpendCollapse={handleExpendCollapse}
                />
                {/* Left side Panels for Ticket Details/Conversations/CreateTicket/AgentChat */}

                {/* Right Side Panel For TicketCreate/etc */}

                        <Suspense
                    fallback={
                        <Box p={6} w={{ base: 'calc(100%)', lg: `${75 + actualDynamicWidth}%` }} height={'calc(100vh - 40px)'}>
                            {/* <SkeletonText mt='5' noOfLines={15} spacing='5' /> */}
                        </Box>
                    }>
                    {tabs && tabs.length === 0 && !isMobile && (
                        <Box
                            bg={theme.bg2}
                            w={{ base: 'calc(100%)', lg: `${100 + actualDynamicWidth}%` }}
                            display="flex"
                            marginTop={{ base: '20px', md: '0px' }}
                            ml={{ sm: '0px', md: '10px' }}
                            overflow="hidden"
                            borderRadius="10px"
                            height={'calc(100vh - 40px)'}
                            style={mobileCss}
                            color="grey">
                            <Box d="flex" w="100%" flexDirection={'column'}>
                                <Box justifyContent={'center'} alignItems="center" h="100%" w="100%" d="flex" flexDirection={'column'}>
                                    <Image w="150px" m="0px auto" src={`${config?.cdnUri}/cdn/agentuipreview.png`} alt="Rezolve.ai" />
                                    <Text textAlign={'center'} pr="25px">
                                        {t('Select an item to view')}
                                    </Text>
                                </Box>
                            </Box>
                            {/* conditions for Blank Screens */}

                            
                        </Box>
                    )}

 
                    {/* Right Side Panel For TicketCreate/etc */}

                    {/* Right side Panels for Ticket Details/Conversations Open */}
                    {tabs && tabs.length > 0 && bulkList && bulkList.length == 0 && (
                        <Flex w={{ base: 'calc(100%)', lg: `${90 - actualDynamicWidth + dynamicRightWidth}%` }} minW={'30%'}>
                            <Box
                                bg={theme.bg2}
                                w={'100%'}
                                display="flex"
                                marginTop={{ base: '20px', md: '0px' }}
                                ml={{ sm: '0px', md: '10px' }}
                                flexDirection={'column'}
                                overflow="hidden"
                                borderRadius="10px"
                                height={'calc(100vh - 40px)'}
                                style={mobileCss}
                                zIndex={2}>
                                {/* <Box w="60%"> */}
                                <Tabs
                                    message={'agent chat'}
                                    t={t}
                                    onItemTypeChange={onItemTypeChange}
                                    onUseCaseChange={onUseCaseChange}
                                    convData={convData}
                                    tabs={tabs}
                                    fnTabChange={fnTabChange}
                                    fnRemoveTab={fnRemoveTab}
                                    permissions={permissions}  
                                    isAgentAvailable={isAgentAvailable}
                                    handleBackToList={handleBackToList}
                                    isMobile={isMobile}
                                    onRowSelect={onRowSelect}
                                    profileUser={rightProfileUser}
                                    closeAllTabs={closeAllTabs}
                                    onShowConversation={onShowConversation}
                                />
                                {/* </Box> */}
                            </Box>
                            {/* </Rnd> */}
                        </Flex>
                    )}

                    {tabs && tabs.length > 0 && bulkList && bulkList.length == 0 && (
                        <Flex w={{ base: 'calc(100%)', lg: `${25 - dynamicRightWidth}%` }} minW={`${toggleRightExpended ? 5 : 25}%`} zIndex={1}>
                            <Box
                                bg={theme.bg2}
                                display={'flex'}
                                flexDirection="column"
                                overflow={'auto'}
                                borderRadius="10px"
                                p="2"
                                height={'calc(100vh - 40px)'}
                                marginTop={{ base: '20px', md: '0px' }}
                                ml={{ sm: '0px', md: '10px' }}
                                position="relative"
                                w={'100%'}>
                                {!isMobile && (
                                    <Box onClick={handleToggleRightChange} ml={'-12px'} mt={'-3px'}>
                                        <ToggleIcon
                                            size={32}
                                            isSidebarOpen={toggleRightExpended}
                                            transitionTime={transitionTime}
                                            color={theme.activeColor}
                                            bg={theme.bg2}
                                        />
                                    </Box>
                                )}
                                {!toggleRightExpended ? (
                                    <RightPanel
                                        profileUser={rightProfileUser}
                                        userName={profile?.preferred_username || userInfo?.preferred_username}
                                        onRowSelect={onRowSelect}
                                        tenantId={profile?.tenantUid || null}
                                        permissions={permissions}
                                        t={t}
                                    />
                                ) : (
                                    <Avatar name={rightProfileUser?.username} size="xs" />
                                )}
                            </Box>
                        </Flex>
                    )}
                </Suspense>
                {/* Right side Panels for Ticket Details/Conversations Open */}
            </Flex>
        </Box>
    )
})

 

 

export default withTranslation()(AgentContainer)

const Feedback = ({ tenant, profile, userInfo, t }) => {
    const initRef = React.useRef()
    const [feedbackInput, setFeedbackInput] = useState('')
    const toast = useToast()
    const inputRef = useRef()

    let feedbackAction = useUpdateEntity({ resourceName: 'feedbackPost' })
    const { mutate, isSuccess, isLoading, data, reset } = feedbackAction

    useEffect(() => {
        if (isSuccess) {
            toast({
                title: t ? t(`Feedback`) : 'Feedback',
                description: t(`Your Feedback is Submitted Successfully!`),
                status: `success`,
                duration: 5000,
                position: 'top-right',
                isClosable: true,
            })
            reset()
            setFeedbackInput('')
            inputRef.current.value = ''
        }
    }, [isSuccess, reset, toast])

    const feedbackChange = useCallback(e => {
        let inputVal = e.target.value
        setFeedbackInput(inputVal)
    }, [])

    const feedbackSubmit = useCallback(
        onClose => {
            let payload = {
                subject: `Livechat Agent UI - Feedback - ${profile?.tenantId} by ${profile?.preferred_username || profile?.name}`,
                feedback: feedbackInput,
                tenantName: profile?.tenantId || null,
                domain: tenant?.domain || null,
                user: profile?.preferred_username || profile?.name,
            }
            mutate({
                entityUrlPaths: {
                    url: `feedback`,
                    method: `POST`,
                },
                payload: payload,
            })
            setTimeout(() => {
                onClose()
            }, 1000)
        },
        [feedbackInput, profile, tenant, mutate]
    )

    return (
        <Popover initialFocusRef={initRef}>
            {({ isOpen, onClose }) => (
                <>
                    <PopoverTrigger>
                        <Button borderRadius={'15px'} size="xs" variant="outline" leftIcon={<BiEnvelope />} colorScheme="blue">
                            {t('Feedback')}
                        </Button>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent mr={5} fontSize="12px" color="gray">
                            <PopoverArrow />
                            <PopoverHeader>{t ? t('Please Share Your Feedback') : 'Please Share Your Feedback'}</PopoverHeader>
                            <PopoverCloseButton />
                            <PopoverBody>
                                <Textarea
                                    ref={inputRef}
                                    fontSize="12px"
                                    placeholder={t ? t('Enter Your Feedback') : 'Enter Your Feedback'}
                                    onChange={feedbackChange}
                                    resize="none"
                                />
                            </PopoverBody>
                            <PopoverFooter d="flex" justifyContent={'space-between'}>
                                <Box></Box>
                                <Box>
                                    <Button m={1} borderRadius={15} variant="outline" onClick={onClose} size="xs">
                                        {t ? t('Cancel') : 'Cancel'}
                                    </Button>
                                    <Button
                                        m={1}
                                        borderRadius={15}
                                        variant="outline"
                                        colorScheme="green"
                                        size="xs"
                                        isLoading={isLoading}
                                        loadingText="Submitting.."
                                        onClick={feedbackSubmit.bind(this, onClose)}
                                        isDisabled={feedbackInput.trim().length == 0 ? true : false}>
                                        {t ? t('Submit') : 'Submit'}
                                    </Button>
                                </Box>
                            </PopoverFooter>
                        </PopoverContent>
                    </Portal>
                </>
            )}
        </Popover>
    )
}

// Image Slider for Help Content

const Slider = ({ isOpen, onOpen, onClose }) => {
    const [slideIndex, setSlideIndex] = useState(1)
    const dataSlider = useMemo(
        () => [
            `agent-ui-step-1.jpg`,
            `agent-ui-step-2.jpg`,
            `agent-ui-step-3.jpg`,
            `agent-ui-step-4.jpg`,
            `agent-ui-step-5.jpg`,
            `agent-ui-step-6.jpg`,
            `agent-ui-step-7.jpg`,
            `agent-ui-step-8.jpg`,
            `agent-ui-step-9.jpg`,
        ],
        []
    )

    const nextSlide = useCallback(() => {
        if (slideIndex !== dataSlider.length) {
            setSlideIndex(slideIndex + 1)
        } else if (slideIndex === dataSlider.length) {
            setSlideIndex(1)
        }
    }, [slideIndex, dataSlider])

    const prevSlide = useCallback(() => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
        } else if (slideIndex === 1) {
            setSlideIndex(dataSlider.length)
        }
    }, [slideIndex, dataSlider])

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} size="full">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton
                        zIndex={9}
                        borderRadius={15}
                        bg="#ccc"
                        _hover={{
                            background: '#eaeaea',
                            color: 'teal.500',
                        }}
                    />
                    <ModalBody>
                        <Box className="container-slider">
                            {dataSlider.map((elem, index) => {
                                return (
                                    <Box key={index} className={slideIndex === index + 1 ? 'slide active-anim' : 'slide'}>
                                        <img alt={`pic-${index}`} title={elem} src={`${config?.cdnUri}/cdn/${elem}`} />
                                    </Box>
                                )
                            })}
                            <Button onClick={nextSlide} className={'btn-slide prev'}>
                                <BiArrowFromLeft />
                            </Button>

                            <Button onClick={prevSlide} className={'btn-slide next'}>
                                <BiArrowFromRight />
                            </Button>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Portal>
    )
}
