import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Flex,
    Heading,
    useColorMode,
    useColorModeValue,
    useMediaQuery,
    Box
} from '@chakra-ui/react'
import {
    ThemeContext,
} from './common/ui'
import { withTranslation } from 'react-i18next'
import Sidebar    from './common/components/sidebar'
import { RootRouter } from './common/router'
import { userSettingHooks } from "./common"
import {Header,Footer} from './common/components/_template/'
import AppContext from "AppContext"
import {useSelector} from 'react-redux'
const {useEntity}  = userSettingHooks
const MainLayout = (props) => {
    const { config, lightTheme, darkTheme, routes, identity,t,tenant } = props
    const sideBarExpanded=useSelector(state=>state.sidebar.isExpanded)

    const [isMobile] = useMediaQuery("(max-width: 768px)")
    const { colorMode, toggleColorMode: toggleDarkMode } = useColorMode()
    const theme = useColorModeValue(lightTheme, darkTheme)
    const isDarkMode = useMemo(() => (colorMode === 'dark'), [colorMode])
    const [isThemeUpdated, setIsThemeUpdated] = useState(false)

    const renderComponent = useCallback(({ Component, title }) => (
        <Flex direction="column" bg={theme.bg1} w="100%">
            {/* <Flex gridColumnGap={2}>
                <Heading size="lg" color={theme?.headingColor || theme.fg1}>{title}</Heading>
            </Flex> */}
             { Component ? <Component /> : <></>}
        </Flex>
    ), [theme])
    const userId = identity?.profile?.userId || identity?.profile?.sub //sub for Teams pkg
    const {data: userSetting, isFetched: isFetchUserSetting}  = useEntity({reqPath: `UserSetting?$filter=userId eq '${userId}'`})
    useEffect(()=>{
        if(!isThemeUpdated && isFetchUserSetting){
        let userSettingData = userSetting && userSetting.find(item=>item.entity==="theme")
        if(userSettingData && userSettingData.activeTheme==="Dark" && !isDarkMode)
         toggleDarkMode()
        else if(userSettingData && userSettingData.activeTheme==="Light" && isDarkMode)
         toggleDarkMode()
         setIsThemeUpdated(true)
        }
    }, [isFetchUserSetting, toggleDarkMode, isThemeUpdated, userSetting, isDarkMode])

    return (
        <ThemeContext.Provider value={{ isDarkMode, theme, toggleDarkMode }}>    
        <Header theme={theme} isSwitchClassicLiveChat={true} identity={identity} t={t} tenant={tenant} config={config} isExpanded={sideBarExpanded}
        {...props}
        />
        <Flex h="calc(100vh - 90px)" bg={theme.bg1}>
            <Sidebar t={t} theme={isDarkMode? darkTheme : lightTheme} appRoutes={routes} maxWidth="240px" isExpanded={isMobile? true: false} AppContext={AppContext} />
            <Box className="mainLayoutcontainer" flex={1} bg={theme.bg1} width="calc(100% - 226px)" maxH={"100vh"}>
                <Box p={3} bg={theme.bg1}>
                <RootRouter config={config} identity={identity} routes={routes} render={renderComponent} />
                </Box>
            </Box>
        </Flex>
        <Footer theme={theme}/>
        </ThemeContext.Provider>
    )
}

export default withTranslation()(MainLayout) 