const UserSettingMeta = Object.freeze({
  filterEntity: 'TICKETING_FILTER',
})
const FilterOperator = Object.freeze({
  eq: 'IS',
  ne:' NOT IN'
})
const FilterOperatorForText = Object.freeze({
  eq:'EQUAL TO',
  CONTAINS: 'LIKE'
})
export { UserSettingMeta, FilterOperator, FilterOperatorForText }
