import _ from "lodash"
import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from "react-query"
// import { ServiceHelper } from "../../../../common/helpers/ServiceHelper"
import { ServiceHelper } from "../common/helpers/ServiceHelper"

const useEntity = ({ resourceName, reqPath, method, payload, options={}, headers={} }) => {
    return useQuery([resourceName, reqPath], () => {
        let reqOptions={ method: `${method || 'GET'}`, reqPath: reqPath }
        if(!_.isEmpty(payload))
            reqOptions.data=payload
            reqOptions.headers={...headers, clientApp:'tickets'}
        return ServiceHelper.prepAndAsyncCall(
            reqOptions,
            // { staleTime: 1000 * 3 }
        ).then((res) => res.data)
    },
    options
    )
}

const useEntityAsync = ({ resourceName, reqPath, method, payload, options={}, headers={} }) => {
    return useQuery([resourceName, reqPath], () => {
        let reqOptions={ method: `${method || 'GET'}`, reqPath: reqPath }
        if(!_.isEmpty(payload))
            reqOptions.data=payload
            reqOptions.headers={...headers, clientApp:'tickets'}
        return ServiceHelper.prepAndCall(
            reqOptions,
            // { staleTime: 1000 * 3 }
        ).then((res) => res.data)
    },
    options
    )
}
const updateItem = async ({entityUrlPaths, payload, usecase="james", itemtype='default'}) => {
    let reqPath = entityUrlPaths.url
    let headers = {clientApp:'tickets', usecase, itemtype }
    if (itemtype) headers.itemtype = itemtype
    let requestOptions = ServiceHelper.prepareConfig(
        { 
        method: entityUrlPaths.method || 'POST', 
        reqPath,
        data: payload,
        headers
    }
        )
    return ServiceHelper.makeAsyncCall(requestOptions).then(res=>res.data)
}

const useUpdateEntity = ({resourceName, successCb}) => {
    const queryClient = useQueryClient()
    return useMutation(updateItem,
        {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, payload, context) => {
          queryClient.setQueryData(resourceName, context.previousData)
        },
        // Always refetch after error or success:
        onSettled: (data, error, variables, context) => {
          queryClient.invalidateQueries(resourceName,data.caseId, { refetchActive: true})
        },
        onSuccess: (data) => {
            successCb && successCb(data)
        }
      })
}

const useEntityScroll=async({pageParam=0,queryKey})=>{
    let reqPath = queryKey[3]
    let filter=queryKey[1] || null
    let top = queryKey[2] || 15
    let clientApp = queryKey[4]
    let useCase = queryKey[5]
    let textFilter=queryKey[6]
    let orderBy=queryKey[7]
    let oDataFilter = queryKey[8]
    let orderByJsonField =  queryKey[9] 
    let req=reqPath? reqPath: `ticketing/CaseValue`
    let customFilter = null

    if(filter && filter!='')
        customFilter=filter

    if(textFilter && textFilter!='')
        customFilter=textFilter
        let paginate=`$top=${top}&$skip=${pageParam}`
    if(orderBy && orderBy?.indexOf('data_priority')!=-1){
        let order = orderBy?.split(' ')
        orderByJsonField ={orderBy: `case lower(data->>'priority') when 'critical' then 5 when 'high' then 4 when 'medium' then 3 when 'low' then 2  when 'very low' then 1 when 'verylow' then 1 else 2 end`, order: order && order.length>1 ? order[1] : 'DESC'}
        orderBy=""
    }
   
    if(customFilter && customFilter!="" && oDataFilter){
        req=`${req}${customFilter}&${orderBy && orderBy!="" ? orderBy+'&':''}`
    }
    else{
        req=`${req}${customFilter}`
        paginate=`&limit=${top}&offset=${pageParam}`
    }
    req=`${req}${paginate}`

    return ServiceHelper.prepAndAsyncCall(
        { method: "GET", reqPath: req, headers:{clientapp:clientApp || null, usecase:useCase || null, orderByJsonField: orderByJsonField ? JSON.stringify(orderByJsonField): null} }
    ).then(res=>res.data)
}

const useListScrollEntity = ({key,limit, filter, options={}, reqPath,clientApp,useCase,textFilter,orderBy,oDataFilter, orderByJsonField}) => {
    return useInfiniteQuery([key,filter, limit, reqPath, clientApp, useCase,textFilter,orderBy,oDataFilter, orderByJsonField], useEntityScroll, {
        ...options,
        getNextPageParam: (lastPage, allPages) => {
            const {count} = lastPage
            let currentCount = allPages.length*limit
            if (count > currentCount) return currentCount
            else return undefined
        },
        // select: (data) => ({
        //     pages: [...data.pages].reverse(),
        //     pageParams: [...data.pageParams].reverse(),
        // })
    })
}
const uploadedFile = ({entityUrlPaths, payload}) => {
    let reqPath = entityUrlPaths.url
    let requestOptions = ServiceHelper.prepareConfig({ method: entityUrlPaths.method || 'POST', reqPath, data: payload,headers:{clientApp:'tickets'}})
    return ServiceHelper.makeAsyncCall(requestOptions).then(res=>res.data)
}

const UploadFile = ({resourceName}) => {
    const queryClient = useQueryClient()
    return useMutation(uploadedFile,
        {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, payload, context) => {
          queryClient.setQueryData(resourceName, context.previousData)
        },
        // Always refetch after error or success:
        onSettled: (data, error, variables, context) => {
          queryClient.invalidateQueries(resourceName)
        },
      })
}

const useEntityConversationScroll=async({pageParam=0,queryKey})=>{
    let filter=queryKey[1] || null
    let top = queryKey[2] || 15
    let req=`conversations/findAndCountAll`

    if(filter && filter!="") 
        req=`${req}${filter}&`
    
    let paginate=`$top=${top}&$skip=${pageParam}`
    req=`${req}${paginate}`
    let res =  await ServiceHelper.prepAndCall(
        { method: "GET", reqPath: req }
    )
    return res.data 
}

const useConversationScrollEntity = ({key,limit, filter, options={}}) => {
    return useInfiniteQuery([key,filter, limit,  { userInfo: {name: "harish"} }], useEntityConversationScroll, {
        ...options,
        getNextPageParam: (lastPage, allPages) => {
            const {count} = lastPage
            let currentCount = allPages.length*limit
            if (count > currentCount) return currentCount
            else return undefined
        },
        // select: (data) => ({
        //     pages: [...data.pages].reverse(),
        //     pageParams: [...data.pageParams].reverse(),
        // })
    })
}


export {
    useEntity,
    useEntityScroll,
    useListScrollEntity,
    useUpdateEntity,
    useConversationScrollEntity,
    UploadFile,
    updateItem,
    uploadedFile,
    useEntityAsync
}
