import _, { isEmpty } from 'lodash'
import moment from 'moment'
import { parse } from 'node-html-parser'
const DataProcessor = {}
const dateFormat = 'MMMM Do YYYY, h:mm A'

const millisToMinutesAndSeconds = millis => {
	let minutes = Math.floor(millis / 60000)
	let seconds = ((millis % 60000) / 1000).toFixed(0)
	return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

const extraFilters = [
	{ name: 'reportedFor', items: ['firstName', 'lastName', 'email'] },
	{ name: 'reporter', items: ['firstName', 'lastName', 'email'] },
]

DataProcessor.formatLivechatListData = ({ data, type, updatedRow, resetWidth = 40, profile, ticketFields = null, t, colorMode,queueList }) => {
	let list = []
	let priority = ticketFields?.find(f => f.fieldName === 'priority')?.fieldProperties
	let priorityOptions = priority?.options?.enums ?? priority?.options
	colorMode = colorMode?.colorMode || colorMode
	switch (type) {
		case 'completedConversations':
			data &&
				!_.isEmpty(data) &&
				_.map(data, item => {
					const { rows } = item
					rows &&
						!_.isEmpty(rows) &&
						_.map(rows, (item, i) => {
							let uName = _.get(item.userInfo || item.user_info, 'userFullName')
							let queue = _.find(queueList, o => o.queueName === item.queue)
							let obj = {
								type: 'chat',
								title: item?.caseId ?? '',
								avatarName: `${uName}`,
								userName: uName,
								updateFlag: {},
								desc: item.conversation?.source ? `${item.conversation?.source}` : '',
								dateTime:
									moment
										.utc(item?.createdAt || item?.createdat)
										.local()
										.format(dateFormat) ?? '',
								createdAt: item?.createdAt || item?.createdat,
								rowItem: item,
								badges: [
									{
										type: 'queue',
										title: queue?.queueLabel ?? 'Default',
										style: {
											colorScheme: 'green',
											variant: 'solid',
										},
									},
									{
										type: 'Livechat',
										label: 'Duration',
										title: millisToMinutesAndSeconds(item.duration),
										style: {
											colorScheme: 'blue',
											variant: 'outline',
										},
										maxLen: 10,
									},
								],
								//avatar: i % 3 && "https://bit.ly/dan-abramov",
							}
							list.push(obj)
						})
				})
			break

		default:
			break
	}

	return list
}

DataProcessor.filterFields = ({ data, t }) => {
	let fields = []
	if (data) {
		const { list } = data
		fields =
			list &&
			_.isArray(list) &&
			_.filter(
				list,
				item => (item?.fieldProperties?.isSearchable || item?.fieldProperties?.fieldType === 'date') && item.fieldName !== 'actRespSla' && item.fieldName !== 'actResolSla'
			)
		fields =
			_.isArray(fields) &&
			_.map(fields, draftState => {
				draftState.label = draftState?.fieldProperties?.label ?? null
				draftState.name = draftState?.fieldProperties?.name ?? null
				return draftState
			})
	}

	return fields
}

DataProcessor.sortFields = ({ data, t }) => {
	let fields = []
	if (data) {
		const { list } = data
		fields = list && _.isArray(list) && _.filter(list, item => item?.fieldProperties?.isSortable && item.fieldName !== 'actRespSla' && item.fieldName !== 'actResolSla')
		fields =
			_.isArray(fields) &&
			_.map(fields, draftState => {
				draftState.label = draftState?.fieldProperties?.label ?? null
				draftState.name = draftState?.fieldProperties?.name ?? null
				return draftState
			})
	}

	return fields
}

DataProcessor.filterByText = ({ data, text, t }) => {
	if (text && !_.isEmpty(text)) {
		text = _.toLower(text)
		return _.filter(data, item => {
			if (
				_.includes(_.toLower(item.title), text) ||
				_.includes(_.toLower(item?.rowItem?.data?.queue), text) ||
				_.includes(_.toLower(item.desc), text) ||
				_.includes(_.toLower(item.dateTime), text) ||
				_.includes(_.toLower(item.name), text) ||
				_.includes(_.toLower(item.email), text)
			)
				return item
		})
	} else return data
}

DataProcessor.handleCount = ({ listing, completedConversations, t }) => {
	listing.tabs =
		listing &&
		listing.tabs &&
		_.map(listing.tabs, item => {
			if (item.name && completedConversations && item.name === 'completedConversations')
				item.count = completedConversations?.pages && completedConversations.pages.find(x => x?.count)?.count
			return item
		})
	listing.count = {
		completedConversations: completedConversations?.pages && completedConversations.pages.find(x => x?.count)?.count,
	}
	return listing
}

DataProcessor.inboxFilterCount = ({ data, tabList }) => {
	let ret = []
	data &&
		Array.isArray(data) &&
		data.length > 0 &&
		data.forEach((item, idx) => {
			let itm = item
			let findTab = tabList && Array.isArray(tabList) && tabList.find(x => x.name === item.name)
			if (findTab) {
				itm.count = findTab.count
			}
			itm && ret.push(itm)
		})
	return ret
}

DataProcessor.formatIncomingUsers = ({ data, queueList }) => {
	let list = []
	data &&
		Array.isArray(data) &&
		_.map(data, item => {
			let queue = _.find(queueList, o => o.queueName === item.queueName)
			let obj = {
				id: item.id || item.userIncomingChatRequestId,
				title: item?.userFullName ?? 'Title',
				avatarName: item?.userFullName,
				userName: item?.userFullName,
				desc: 'New Session ' + item.incrementer,
				dateTime: moment.utc(item?.created).local().format(dateFormat) ?? '',
				createdAt: item?.created,
				updateFlag: {},
				showPulse: item?.agentBotConversationId ? false : true,
				rowItem: item,
				badges: [
					{
						type: 'queue',
						title: queue?.queueLabel ?? 'Default',
						style: {
							colorScheme: 'green',
							variant: 'solid',
						},
					},
					{
						type: 'LiveChat',
						title: item?.agentBotConversationId ? 'O' : 'I',
						style: {
							colorScheme: 'red',
							variant: 'outline',
						},
					},
				],
			}
			list.push(obj)
		})

	if (data && typeof data === 'object' && data.id) {
		let queue = _.find(queueList, o => o.queueName === data.queueName)
		list.push({
			id: data.id || data.userIncomingChatRequestId,
			title: data?.userFullName ?? 'Title1',
			avatarName: data?.userFullName,
			userName: data?.userFullName,
			desc: 'New Session ' + data.incrementer,
			dateTime: moment.utc(data?.created).local().format(dateFormat) ?? '',
			createdAt: data?.created,
			rowItem: data,
			badges: [
				{
					type: 'queue',
					title: queue?.queueLabel ?? 'Default',
					style: {
						colorScheme: 'green',
						variant: 'solid',
					},
				},
				{
					type: 'Livechat',
					title: 'I',
					style: {
						colorScheme: 'red',
						variant: 'outline',
					},
				},
			],
		})
	}

	return list
}

DataProcessor.mapTransScript = ({ data }) => {
	let list = []

	data &&
		Array.isArray(data) &&
		_.map(data, c => {
			let obj = {
				type: 'message',
				id: Math.random(),
				text: c.text?.startsWith('#') ? c.text?.replace(/[#]/g, '&#35;') : c.text,
				// from: {
				//     id: Math.random(),
				//     // id: c.userInfo && c.userInfo.id ? c.userInfo.id : botUserConversationId,
				//     name: c.sentBy === c.bot ? c.bot : c.userInfo && c.userInfo.name ? c.userInfo.name : c.sentBy,
				//     role: c.sentBy === 'user' || (c.sentBy == c.userFullName && !c.userInfo.agentCheck) ? 'bot' : 'user',
				//     sent: c.userInfo.role === 'user' && c.userInfo.agentCheck ? 'agent' : c.userInfo.role,
				// },
				from: {
					id: Math.random(),
					name: c?.bot?.toLowerCase().indexOf(c?.userInfo?.name?.toLowerCase() != -1) //c.sentBy === c.bot ||
						? c.userInfo?.name
						: c.userInfo && c.userInfo.name
						? c.userInfo.name
						: c.sentBy,

					// role:
					//     c.bot === c.userInfo?.name && c.userInfo?.role === 'user'
					//         ? 'bot'
					//         : c.userInfo?.role === 'user' || c.sentBy === 'user' || c.userInfo?.agentCheck
					//         ? 'user'
					//         : 'bot',
					role: c.bot === c.userInfo?.name && c.userInfo?.role === 'user' ? 'user' : c.userInfo?.role === 'user' || c.sentBy === 'user' || c.userInfo?.agentCheck ? 'bot' : 'user',
				},
				attachments:
					c.attachments && c.attachments.length > 0
						? c.attachments.map(x => {
								if (x.contentType === 'application/vnd.microsoft.keyboard') x.contentType = 'application/vnd.microsoft.card.hero'
								//   x.content?.buttons?.map(x => (x.disabled = true))

								if (x.contentType === 'application/vnd.microsoft.card.hero') {
									if (!x.content.text) x.content.text = ''
									//   if (x.content.text.indexOf('You are connected to') != -1)
									if (x.content.buttons) {
										x.content = Object.assign({}, x.content)
										//   x.content.buttons.map(b => (b.disabled = true))
										x.content.text = `${x.content.text}\n\n* ${x.content?.buttons?.map(x => x.title || x.value)?.join('\n* ')}`
										x.content.buttons = null
									}
								}
								return x
						  })
						: [],
				timestamp: c.startTimestamp,
			}
			list.push(obj)
		})
	list = list.sort((a, b) => {
		return new Date(a.timestamp) - new Date(b.timestamp)
	})
	return list
}
DataProcessor.mapCompletedConversation = ({ data, userInfo }) => {
	let list = []
	let user, bot
	data &&
		Array.isArray(data) &&
		_.map(data, c => {
			if (!user && (c.sentBy == 'User' || userInfo?.role === 'user')) user = userInfo?.name
			if (!bot && userInfo.role === 'bot') bot = userInfo?.name
			let obj = {
				type: 'message',
				id: Math.random(),
				text: c.text?.startsWith('#') ? c.text?.replace(/[#]/g, '&#35;') : c.text,
				from: {
					id: Math.random(),
					name: userInfo?.name || c.sentBy,
					role: c.userInfo?.role || (c.sentBy == 'User' ? 'user' : userInfo.name === user ? 'user' : 'bot'),
				},
				attachments:
					c.attachments && c.attachments.length > 0
						? c.attachments.map(y => {
								let x = Object.assign({}, y)
								if (x.contentType === 'application/vnd.microsoft.keyboard') x.contentType = 'application/vnd.microsoft.card.hero'
								if (x.contentType === 'application/vnd.microsoft.card.hero') {
									if (!x.content.text) x.content.text = ''
									if (x.content.buttons) {
										x.content = Object.assign({}, x.content)
										//   x.content.buttons.map(b => (b.disabled = true))
										x.content.text = `${x.content.text}\n\n* ${x.content?.buttons?.map(x => x.title || x.value)?.join('\n* ')}`
										x.content.buttons = null
									}
								}
								return x
						  })
						: [],
				timestamp: c.time,
			}
			list.push(obj)
		})
	return list
}

DataProcessor.setSelectConfig = ({ fieldProps, value }) => {
	let obj = {}
	if (fieldProps && fieldProps.options && !fieldProps.options.apiUrl && fieldProps.options.enums) {
		obj.options = fieldProps.options.enums
		if (fieldProps?.options?.default)
			obj.default = [
				{
					label: fieldProps.options?.default?.label || fieldProps?.options?.default.value,
					value: fieldProps.options?.default?.value || fieldProps.options?.default.label,
				},
			]
		else if (fieldProps?.default)
			obj.default = [
				{
					label: fieldProps.default?.label || fieldProps.default?.value,
					value: fieldProps.default?.value || fieldProps.default?.label,
				},
			]
	} else if (fieldProps && fieldProps.options && fieldProps.options.apiUrl) {
		obj.api = {
			url: fieldProps.options.apiUrl,
			listMap: fieldProps.options.listMap,
			dependency: fieldProps.options?.dependency ?? null,
		}
	}
	let optionItem = fieldProps?.options?.enums?.find(o => o.value === value)
	if (value) obj.default = [{ label: optionItem?.label || value, value: value }]
	return obj
}
DataProcessor.setSelectList = ({ list, listMap, prevList }) => {
	let mappedList = []
	if (!_.isEmpty(listMap) && !_.isEmpty(list)) {
		const { key, value } = listMap
		_.forEach(list, item => {
			let obj = {
				label: item[value],
				value: item[key],
			}

			mappedList.push(obj)
		})
	}
	mappedList = _.uniqBy(mappedList, o => o.value)
	return mappedList
}

DataProcessor.isFieldRequired = ({ fieldProps }) => {
	let obj = { required: false, errMsg: null }
	if (fieldProps && fieldProps.required) {
		obj.required = true
		obj.errMsg = fieldProps && fieldProps.errorMessage ? fieldProps.errorMessage : `${fieldProps.label} is Required!`
	}
	return obj
}
DataProcessor.validateInvalidFields = ({ value, reqFields }) => {
	let list = []
	value &&
		reqFields &&
		Array.isArray(reqFields) &&
		reqFields.forEach(item => {
			let itemVal = value[item]
			if (item === 'description') itemVal = itemVal && itemVal.trim().replace(/<[^/>][^>]*><\/[^>]+>/, '')

			if (isEmpty(itemVal) || itemVal === '') list.push(item)
		})

	return list
}

DataProcessor.getItemType = ({ caseValueData }) => {
	let itemType = 'default'
	itemType = caseValueData && caseValueData.lookupProps && caseValueData.lookupProps.itemType ? caseValueData.lookupProps.itemType : 'default'
	return itemType
}

DataProcessor.getUseCase = ({ caseValueData }) => {
	let useCase = 'james'
	useCase = caseValueData && caseValueData.lookupProps && caseValueData.lookupProps.usecase ? caseValueData.lookupProps.usecase : 'james'
	return useCase
}

DataProcessor.setScreenPositions = ({ meta, col, type }) => {
	let itemList = { sectionName: '', list: [] }
	Array.isArray(meta) &&
		_.forEach(meta, x => {
			itemList.sectionName = x.name || ''
			x &&
				x.items &&
				Array.isArray(x.items) &&
				_.forEach(x.items, y => {
					if (y.showIn && y.showIn[type]) {
						let showConfig = y.showIn[type]
						if (!_.isEmpty(showConfig) && showConfig.screenCol && showConfig.screenCol == col) {
							if (showConfig.position) y.position = showConfig.position
							if (showConfig.readOnlyOnEdit) y.readOnlyOnEdit = showConfig.readOnlyOnEdit || false
							itemList.list.push(y)
						}
					}
				})
		})
	itemList.list = itemList.list && _.sortBy(itemList.list, [o => o?.position])
	return itemList
}
DataProcessor.setActiveAgentUserData = ({ data }) => {
	let retList = []
	if (data && typeof data === 'object') {
		Object.keys(data).forEach(key => {
			retList = _.concat(retList, data[key])
		})
		retList = retList.filter(x => x.incominguserstatus > 0)
	}

	return retList
}

DataProcessor.setCurrentUser = ({ data }) => {
	let profileUser = null
	let rowData = data && data.data ? data.data : data && data.rowData ? data.rowData : null
	if (rowData) {
		if (rowData.caseId && rowData.data && rowData.data.reportedFor) profileUser = rowData.data.reportedFor
		else if (rowData.userInfo) profileUser = rowData.userInfo
		else if (rowData) profileUser = rowData
	}
	return profileUser
}

DataProcessor.setPopOver = ({ data, t }) => {
	if (!data) return

	return (
		<>
			<p>
				<span>{t ? t('Submitted By') : 'Submitted By'}</span> :{' '}
				{`${data?.data?.reporter?.firstName} ${data?.data?.reporter?.lastName}` ?? data?.data?.reporter?.username ?? data?.data?.reporter?.email}
			</p>
			<p>
				<span>{t ? t('Reported For') : 'Reported For'}</span> :{' '}
				{`${data?.data?.reportedFor?.firstName} ${data?.data?.reportedFor?.lastName}` ?? data?.data?.reportedFor?.username ?? data?.data?.reportedFor?.email}
			</p>
		</>
	)
}

DataProcessor.setCreateBtn = ({ data, userTeams, t }) => {
	let list = []
	let obj = {}
	if (!data) return
	if (data.teams) {
		const { teams } = data
		const commonTeams = teams.filter(team => userTeams?.includes(team?.value))
		commonTeams.map(item => {
			obj = {
				name: `${item.value}`,
				itemType: item.itemType,
				label: `${t('Create')} ${item.label} ${t('Ticket')}`,
				btnStyle: {
					colorScheme: 'twitter',
					variant: 'solid',
					size: 'xs',
				},
			}
			list.push(obj)
		})
		return list
	} else return
}

DataProcessor.setBulkActionPayload = ({ tenantId, action = null, list = [], assignTicketTo = null }) => {
	let obj = {}
	if (tenantId && action && action.name && list && list.length > 0) {
		list = list.map(x => x?.caseId || x?.rowItem?.caseId || x?.title)
		obj.caseIds = list
		obj.tenantId = tenantId
		if (action.name === 'assign') obj.assignTicketTo = assignTicketTo
		else obj.moveToState = action.moveToState
	}
	return obj
}
DataProcessor.queryFilter = ({ meta, fields }) => {
	let output = ''
	if (meta && meta.length > 0) {
		_.forEach(meta, (item, i) => {
			if (!item) return true
			let fieldMeta = fields && _.find(fields, f => f.fieldName === item.name)
			if (!fieldMeta) return true
			if (Array.isArray(item.value) && item.value.length > 0) {
				if (item && item.Op && item.Op === 'ne') item.cond = 'and'

				let choices = item.value.map(c => {
					return `${fieldMeta?.name} ${c && c.opt ? c.opt : item?.Op ? item.Op : 'eq'} ${(typeof c.value === 'string' && "'" + c.value + "'") || c.value}`
				})
				let joinCond = item && item.cond ? ` ${item.cond} ` : ` or `
				output += `(${choices.join(joinCond)})${i < meta.length - 1 ? ' and ' : ''}`
			} else {
				let extraFilter = extraFilters && extraFilters.find(o => o.name === item.name)
				if (extraFilter && extraFilter.items) {
					output += `(substringof('${item.value}', ${fieldMeta?.name})) or `
					extraFilter.items.forEach((eFilter, i) => {
						let fieldPath = `data_${item.name}_${eFilter}`
						output += `(substringof('${item.value}', ${fieldPath}))${i < extraFilter.items.length - 1 ? ' or ' : ''}`
					})
				} else if (fieldMeta && fieldMeta?.fieldProperties && fieldMeta?.fieldProperties.fieldType === 'date') {
					const dates = item?.value.split('~')
					if (dates && dates.length == 2) {
						let startDate = dates[0]
						let endDate = dates[1]
						output += `${fieldMeta.fieldName} ge '${moment.utc(startDate).format('YYYY-MM-DD')}' and ${fieldMeta.fieldName} le '${moment
							.utc(endDate)
							.add(1, 'days')
							.format('YYYY-MM-DD')}'${i < meta.length - 1 ? ' and ' : ''}`
					}
				} else if (item.Op == 'eq') output += `${fieldMeta?.name} ${item.Op} '${item?.value?.trim()}'${i < meta.length - 1 ? ' and ' : ''}`
				else output += `(substringof('${item.value}', ${fieldMeta?.name}))${i < meta.length - 1 ? ' and ' : ''}`
			}
		})
	}
	output = `${output}&$orderby=updatedAt desc`
	return output
}

DataProcessor.renderImageInDesc = ({ description, attachments }) => {
	try {
		const root = parse(description.replace('<a href="" target="_self">', '<a href="" target="_blank">'))
		let images = root.getElementsByTagName('img')
		let div = root.getElementsByTagName('div')
		let links = root.getElementsByTagName('a')
		let flag = div?.length && div.some(d => d.getAttribute('id') === 'OwaReferenceAttachmentFileName2')
		for (let i = 0; i < images.length; i++) {
			if (
				flag &&
				!images[i]?.getAttribute('src')?.includes('ticket-docs') &&
				(images[i]?.getAttribute('originalsrc') ||
					images[i]?.getAttribute('src')?.includes('cid') ||
					images[i]?.getAttribute('src')?.includes('dummy') ||
					images[i]?.getAttribute('src')?.includes('https'))
			) {
				images[i].remove()
			} else {
				if (attachments?.length > 0) {
					let imgUrl = attachments?.find(data => data?.docName?.toLowerCase() == (images[i]?.getAttribute('alt')?.toLowerCase() || images[i]?.getAttribute('src')?.toLowerCase()))
					if (imgUrl && imgUrl.doc && imgUrl.docName) {
						let docName = imgUrl?.docName?.split('.')?.pop()
						let imgExts = ['jpeg', 'jpg', 'png', 'gif', 'bmp?=', 'bmp']
						if (imgExts && imgExts.includes(docName.toLowerCase())) {
							images[i].setAttribute('style', 'width: 100%; height: 100%; ')
							if (flag) images[i].setAttribute('style', 'width: 100%; height: 100%; pointer-events: none;')
							images[i].setAttribute('src', imgUrl && imgUrl.doc)
						} else {
							images[i].remove()
						}
					}
				}
			}
		}
		if (links && links.length > 0) {
			for (let i = 0; i < links.length; i++) {
				if (flag && links[i]?.getAttribute('href')?.includes('sharepoint.com')) {
					let images = links[i]?.querySelectorAll('img')
					if (images.length) links[i].setAttribute('style', 'pointer-events: none;')
					for (let i = 0; i < images.length; i++) {
						if (
							(flag && images[i]?.getAttribute('originalsrc')) ||
							images[i]?.getAttribute('src')?.includes('cid') ||
							images[i]?.getAttribute('src')?.includes('dummy') ||
							(images[i]?.getAttribute('src')?.includes('https') && !images[i]?.getAttribute('src')?.includes('ticket-docs'))
						) {
							images[i].remove()
						}
					}
				} else {
					if (attachments?.length > 0) {
						let im =
							links[i]?.getAttribute('alt')?.toLowerCase() ||
							links[i]?.getAttribute('title')?.toLowerCase() ||
							links[i]?.getAttribute('href')?.toLowerCase() ||
							links[i]?.innerText?.toLowerCase()
						let linkUrl = attachments?.find(data => data?.docName?.toLowerCase() == im)
						if (linkUrl && linkUrl.doc) {
							links[i].setAttribute('style', 'pointer-events: all;')
							links[i].setAttribute('href', linkUrl && linkUrl.doc)
						}
					}
				}
			}
		}
		return (description = root?.toString() || '<p></p>')
	} catch (e) {
		return <p></p>
	}
}

DataProcessor.renderImageInEmailDesc = ({ description, attachments }) => {
	try {
		const root = parse(description.replace('<a href="" target="_self">', '<a href="" target="_blank">'))
		let images = root.getElementsByTagName('img')
		let div = root.getElementsByTagName('div')
		let links = root.getElementsByTagName('a')
		let flag = div?.length && div.some(d => d.getAttribute('id') === 'OwaReferenceAttachmentFileName2')
		let c = attachments.length
		for (let i = 0; i < images.length; i++) {
			if (attachments[c]?.docName && !attachments[c]?.docName.includes('Original_') && attachments[c]?.doc) {
				images[i].setAttribute('src', attachments[c].doc)
			} else {
				if (attachments?.length > 0) {
					let imgUrl = attachments?.find(data => data?.docName?.toLowerCase() == (images[i]?.getAttribute('alt')?.toLowerCase() || images[i]?.getAttribute('src')?.toLowerCase()))
					if (imgUrl && imgUrl.doc && imgUrl.docName) {
						let docName = imgUrl?.docName?.split('.')?.pop()
						let imgExts = ['jpeg', 'jpg', 'png', 'gif', 'bmp?=', 'bmp']
						if (imgExts && imgExts.includes(docName.toLowerCase())) {
							images[i].setAttribute('style', 'width: 100%; height: 100%; ')
							if (flag) images[i].setAttribute('style', 'width: 100%; height: 100%; pointer-events: none;')
							images[i].setAttribute('src', imgUrl && imgUrl.doc)
						} else {
							images[i].remove()
						}
					}
				}
			}
		}
		if (links && links.length > 0) {
			for (let i = 0; i < links.length; i++) {
				if (flag && links[i]?.getAttribute('href')?.includes('sharepoint.com')) {
					let images = links[i]?.querySelectorAll('img')
					if (images.length) links[i].setAttribute('style', 'pointer-events: none;')
					for (let i = 0; i < images.length; i++) {
						if (
							(flag && images[i]?.getAttribute('originalsrc')) ||
							images[i]?.getAttribute('src')?.includes('cid') ||
							images[i]?.getAttribute('src')?.includes('dummy') ||
							(images[i]?.getAttribute('src')?.includes('https') && !images[i]?.getAttribute('src')?.includes('ticket-docs'))
						) {
							images[i].remove()
						}
					}
				} else {
					if (attachments?.length > 0) {
						let im =
							links[i]?.getAttribute('alt')?.toLowerCase() ||
							links[i]?.getAttribute('title')?.toLowerCase() ||
							links[i]?.getAttribute('href')?.toLowerCase() ||
							links[i]?.innerText?.toLowerCase()
						let linkUrl = attachments?.find(data => data?.docName?.toLowerCase() == im)
						if (linkUrl && linkUrl.doc) {
							links[i].setAttribute('style', 'pointer-events: all;')
							links[i].setAttribute('href', linkUrl && linkUrl.doc)
						}
					}
				}
			}
		}
		return (description = root?.toString() || '<p></p>')
	} catch (e) {
		return <p></p>
	}
}
DataProcessor.convertCustomImgLink = ({ value }) => {
	let convertToTags = value
	convertToTags = value.replace(/<picture>(.*?)<\/picture>/g, (matchedString, link) => {
		return `<a href="${link}" target="_blank" alt="${link}" title="${link}" ref="noreferer"><img src="${link}" alt="${link}" title=${link}/></a>`
	})

	convertToTags = convertToTags.replace(/<iLink>(.*?)<\/iLink>/g, (matchedString, link) => {
		return `<a href="${link}" target="_blank" alt="${link}" title="${link}" ref="noreferer">${link}</a>`
	})
	return convertToTags
}

DataProcessor.secondsToHms = ({ timestamp, currentStatus }) => {
	timestamp = Number(timestamp)
	var h = Math.floor(timestamp / 3600)
	var m = Math.floor((timestamp % 3600) / 60)
	var s = Math.floor((timestamp % 3600) % 60)

	var hDisplay = h > 0 ? h : '00'
	var mDisplay = m > 0 ? m : '00'
	var sDisplay = s > 0 ? s : '00'
	if (currentStatus === 'Closed' || currentStatus === 'Resolved') {
		return `${hDisplay} hours ${mDisplay} minutes`
	} else {
		return `${hDisplay}` + ':' + ('0' + `${mDisplay}`).slice(-2) + ':' + ('0' + `${sDisplay}`).slice(-2)
	}
}
DataProcessor.overrideCssInline = ({ data }) => {
	let res = data
	if (res && res.length > 0) {
		res = res?.replace(/background-color: ?(?:#[a-f\d]{3,6}|rgb\([ \d,]+\)|[a-z]+);/gi, '') || ''
		res = (res && res?.replace(/background: ?(?:#[a-f\d]{3,6}|rgb\([ \d,]+\)|[a-z]+);/gi, '')) || ''
	}
	return res
	// let res = `${data}`.replace(/background-color\:[^;]?/g, '')
	// let res2 = res.replace(
	//     /\brgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/g,
	//     function ($0, $1, $2, $3) {
	//         return ""
	//     })
	// return res2.replace(
	//     /(#[0-9A-F]{6})|(#[0-9A-F]{3})/gi,
	//     function (m, r, g, b) {
	//         return ""
	//     })
}
export default DataProcessor
