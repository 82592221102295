import React from 'react'
import { Alert, AlertIcon, Stack } from '@chakra-ui/react'

export const AlertBox = (alertStatus, message) => {
  let status = 'info'
  switch (alertStatus) {
    case 'error':
      status = 'error'
      break
    case 'success':
      status = 'success'
      break
    case 'warning':
      status = 'warning'
      break
    default:
      status = 'info'
      break
  }
  return (
    <Stack spacing={3}>
      <Alert status={status}>
        <AlertIcon />
        {message}
      </Alert>
    </Stack>
  )
}
