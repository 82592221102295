 
const InitialState = {}
InitialState.defaultListConfig=({UseColorModeValue,permission, t})=>{
    const listingConfig = {
        listLimit:15,
        search: true,
        filter: true,
        sort: true,
        activeTab:'completedConversations',
        tabs:[ 
        {
            title:t? t('Conversations'): 'Conversations',
            name:'completedConversations',
            display:permission && (permission?.livechatUsers || permission?.liveChatuserReadOnly),
            count:0,
            countStyle:{
                bg:UseColorModeValue('blue.500','blue.200')
            },
            style:{
                color:"grey" 
               }
        },
        {
            title:t? t('Filter Results'): 'Filter Results',
            name:'queryFilterData',
            display:permission && permission?.ticketList,
            count:0,
            countStyle:{
                bg:UseColorModeValue('blue.500','blue.200')
            },
            style:{
                color:"grey" 
               }
        }
    ],
        filterFields: null,
    }
    return listingConfig
}

InitialState.defaultFilters=({identity})=>{
    const {profile} = identity
    let username=profile && profile.preferred_username
    let filter={
        completedConversations:`?username='${username}'&orderby=createdAt desc`,
    }
    return filter
}

InitialState.intialAvailability={
    metrics: {
        agentName: "",
        isAvailable: true,
        reason: false,
        time: "",
    },
    agentId: "",
    isActive: 1,
    userName: "",
}

 

 

InitialState.QueryKeys={
    COMPLETED_CONVERSATION:'completedConversations',
    AGENT_QUEUE_LIST:'agentQueueList',
    GET_ACTIVE_AGENT:'getActiveAgentUser',
    GET_INCOMING_USER:'getIncomingUsers',
    QUERY_FILTER_DATA:'queryFilterData',
    QUEUE_TICKET:'queueTicket',
    GET_USER_INFO:'getUserInfo',
    GET_USER_ATTRIBUTE:'getUserAttribute',
    REGISTERALLSOCKET: 'registerAllSocket',
}

InitialState.InboxFilterList=[
    {name:'queue',label:'All Tickets',count:0, isDefault:true, wasTab:true},
    {name:'queryFilterData',label:'Filter Result',count:0, isDefault:true, wasTab:true},
]

export default InitialState