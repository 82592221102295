import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useMutation } from 'react-query'
import AppContext from 'AppContext'
import { useEntity, updateItem } from '../../../../services/commonBase.services.js'
import { FormControl, FormLabel, Text, IconButton, Input, HStack, Spinner, useToast, Box, Spacer } from '@chakra-ui/react'
import { Panel } from 'common/ui.js'
import { FaSave } from 'react-icons/fa'
import styled from '@emotion/styled'

const BotSettings = props => {
	const { identity } = useContext(AppContext)
	const [fields, setFields] = useState({
		removeWaitingUserTime: {
			name: 'removeWaitingUserTime',
			type: 'number',
			value: '',
			placeholder: 'Enter The Waiting Time to pick by agent',
			label: 'Agent Pick Time (sec)',
		},
		agentNotifyInterval: {
			name: 'agentNotifyInterval',
			type: 'number',
			value: '',
			placeholder: 'Enter The Waiting Time Teams Interval',
			label: 'Repeat Teams Notification (sec)',
		},
		notifyingForAgent: {
			name: 'notifyingForAgent',
			type: 'text',
			value: '',
			placeholder: 'Enter the Waiting Message',
			label: 'Notifying For Agent Message',
		},
		// connectMessage: {
		// 	name: 'connectMessage',
		// 	type: 'text',
		// 	value: '',
		// 	placeholder: 'Enter the Connect Message',
		// 	label: 'Connect Message',
		//
		// },
		// disconnectMessage: {
		// 	name: 'disconnectMessage',
		// 	type: 'text',
		// 	value: '',
		// 	placeholder: 'Enter the Disconnect Message',
		// 	label: 'Disconnect Message',
		//
		// },
		// missedChat: {
		// 	name: 'missedChat',
		// 	type: 'text',
		// 	value: '',
		// 	placeholder: 'Enter the Missed Chat Message',
		// 	label: 'Missed Chat',
		//
		// },
		// agentUnavailable: {
		// 	name: 'agentUnavailable',
		// 	type: 'text',
		// 	value: '',
		// 	placeholder: 'Enter the Agent Unavailable Message',
		// 	label: 'Agent Unavailable',
		// },
	})

	const toast = useToast()

	const handleChange = (fieldName, value) => {
		setFields(prevFields => ({
			...prevFields,
			[fieldName]: {
				...prevFields[fieldName],
				value,
			},
		}))
	}

	const handleSave = fieldName => {
		if (identity) {
			const data = fields[fieldName].data
			data.tenantId = identity.profile.tenantUid
			data.updatedBy = identity.profile.preferred_username || identity.profile.email
			data.updatedAt = Date.now()
			if (data && data.hasOwnProperty('fullName')) {
				data.properties = parseInt(fields[fieldName].value) * 1000
				onSubmitBotConfig(data)
			} else {
				data.value = fields[fieldName].value
				onSubmitBotMessage(data)
			}
		}
		setFields(prevFields => ({
			...prevFields,
			[fieldName]: {
				...prevFields[fieldName],
			},
		}))
	}
	const { data: botMessage } = useEntity({
		reqPath: "botMessage/findAndCountAll?$filter=lang eq 'en' and status eq 'Published' and key eq 'notifyingForAgent'",
		method: 'GET',
	})
	const { data: botconfig } = useEntity({
		reqPath: "botConfig?$filter=status eq 'Published' and fullName eq 'agentNotifyInterval' or fullName eq 'removeWaitingUserTime'",
		method: 'GET',
	})

	useEffect(() => {
		if (botconfig && botMessage) {
			const updatedFields = { ...fields }
			for (const field of Object.values(fields)) {
				if (field.name === 'removeWaitingUserTime') {
					const config = botconfig.find(item => item.fullName === field.name)
					if (config) {
						updatedFields[field.name].value = config.properties / 1000
						updatedFields[field.name].data = config
					}
				}
				if (field.name === 'agentNotifyInterval') {
					const config = botconfig.find(item => item.fullName === field.name)
					if (config) {
						updatedFields[field.name].value = config.properties / 1000
						updatedFields[field.name].data = config
					}
				}
				if (field.name === 'notifyingForAgent') {
					const config = botMessage.rows.find(item => item.key === field.name)
					if (config) {
						updatedFields[field.name].value = config.value
						updatedFields[field.name].data = config
					}
				}
			}
			setFields(updatedFields)
		}
	}, [botconfig, botMessage])

	let onSubmitBotConfig = async data => {
		await mutateBotConfig({
			entityUrlPaths: {
				url: 'botConfig/publish',
				method: 'POST',
			},
			payload: data,
		})
	}

	let onSubmitBotMessage = async data => {
		await mutateBotMessage({
			entityUrlPaths: {
				url: 'botMessage/upsert',
				method: 'POST',
			},
			payload: data,
		})
	}

	let onSubmitBotConfigPostReq = useMutation(updateItem, {
		onSuccess: data => {
			
			openToast({
				description: `Agent Time updated sucessfully`,
				title: 'Bot Config',
				status: 'success',
			})
		},
		onError: (err, data) => {
			openToast({
				description: `Failed to update the config ${err}`,
				title: 'Error while updating',
				status: 'error',
			})
		},
	})

	let onSubmitBotMessagePostReq = useMutation(updateItem, {
		onSuccess: data => {
			openToast({
				description: `Agent Message updated sucessfully`,
				title: 'Bot Message',
				status: 'success',
			})
		},
		onError: (err, data) => {
			openToast({
				description: `Failed to update the message ${err}`,
				title: 'Error while updating',
				status: 'error',
			})
		},
	})

	const openToast = useCallback(
		({ title, description, status }) => {
			toast({
				title: title || 'Live Chat',
				description: description || 'Request Progressing',
				status: status || 'info',
				duration: 3000,
				position: 'top-right',
				isClosable: true,
			})
		},
		[toast]
	)
	const LoaderStyled = styled.div`
		position: relative;
		.child {
			position: absolute;
			top: 50%;
			left: 50%;
		}
	`
	const { mutateAsync: mutateBotConfig, isLoading: loadingBotConfig } = onSubmitBotConfigPostReq
	const { mutateAsync: mutateBotMessage, isLoading: loadingBotMessage } = onSubmitBotMessagePostReq

	return (
		<Panel mt="8" title ='Bot Settings'>
			<Box className="bot_settings">
				<FormControl>
					<FormLabel mb="25px"></FormLabel>
					{Object.values(fields).map((field, idx) => {
						const { name, label, type, value, placeholder } = field

						return (
							<>
								<HStack spacing="24px" mb="8" key={idx}>
									<Text width="20%" as="b" fontSize="12">
										{label}
									</Text>
									<Input type={type} size="sm" width="100%" onChange={e => handleChange(name, e.target.value)} value={value} placeholder={placeholder} />
									<IconButton icon={<FaSave />} onClick={() => handleSave(name, value)} size="sm" aria-label="Save" />
									{loadingBotConfig ||
										(loadingBotMessage ? (
											<Spinner
												thickness="4px"
												speed="0.65s"
												emptyColor="gray.200"
												color="blue.500"
												size="sm"
												position="absolute"
												top="50%"
												left="50%"
												transform="translate(-50%, -50%)"
											/>
										) : (
											<></>
										))}
									<Spacer />
								</HStack>
							</>
						)
					})}
				</FormControl>
			</Box>
		</Panel>
	)
}

export default BotSettings
