import { HStack, Tooltip, useToast } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { BsFillVolumeDownFill, BsFillVolumeMuteFill, BsFillBellFill, BsFillBellSlashFill } from 'react-icons/bs'
const NotificationBellValume = ({ notificationVolumeOff, notificationMute, handleNotificationVolumnOff, handleNotificationMute,t }) => {
  const toast = useToast()
  const handleNotificationVolumnTurnOff = () => {
    handleNotificationVolumnOff()
    let descText = notificationVolumeOff ? 'You have turn ON notification volume' : 'You have turn Off notification volume'
    toast({
      title: t('Notification'),
      description: t(descText),
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top-right',
    })
  }

  const handleNotificationTurnMute = () => {
    handleNotificationMute()
    let descText = notificationMute ? 'You have Unmuted notification' : 'You have muted notification'
    toast({
      title: t('Notification'),
      description: t(descText),
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top-right',
    })
  }

  return (
    <>
      <Tooltip hasArrow label={notificationVolumeOff ? t?t('volume Off'):'volume Off': t?t('volume On'):'volume On'} bg="teal">
        <HStack onClick={handleNotificationVolumnTurnOff} cursor={'pointer'}>
          {notificationVolumeOff ? <BsFillVolumeMuteFill fontSize={'24'} color="teal" /> : <BsFillVolumeDownFill fontSize={'24'} color="teal" />}
        </HStack>
      </Tooltip>
      <Tooltip hasArrow label={notificationMute ? t?t('Notification Muted'):'Notification Muted' : t?t('Notification On'):'Notification On'} bg="teal">
        <HStack onClick={handleNotificationTurnMute} cursor={'pointer'}>
          {notificationMute ? <BsFillBellSlashFill fontSize={'16'} color="teal" /> : <BsFillBellFill fontSize={'16'} color="teal" />}
        </HStack>
      </Tooltip>
    </>
  ) 
}
export default NotificationBellValume
