// import { FaRegEye } from "react-icons/fa"
import { BiCopy, BiX, BiSave, BiSearchAlt2,BiArrowFromBottom,BiCollapse,BiPlusCircle, BiChevronsUp, BiChevronUp, BiTransferAlt, BiChevronDown, BiChevronsDown,
	// BiRadioCircleMarked,
	// BiDotsHorizontalRounded
 }
	 from "react-icons/bi"


const actions = [
	{
		iconName: BiSave,
		isAllowed: true
	},
	{
		iconName: BiCopy,
		isAllowed: false
	},
	{
		iconName: BiArrowFromBottom,
		isAllowed: false
	},
	{
		iconName: BiCollapse,
		isAllowed: false
	},
	{
		iconName: BiSearchAlt2,
		isAllowed: false
	},
	{
		iconName: BiPlusCircle,
		isAllowed: false
	},
	// {
	// 	iconName: BiRadioCircleMarked,
	// 	isAllowed: false
	// },
	// {
	// 	iconName: FaRegEye,
	// 	isAllowed: false
	// },
	{
		iconName: BiX,
		isAllowed: false
	},
	// {
	// 	iconName: BiDotsHorizontalRounded,
	// 	isAllowed: true
	// },

]

export {
    actions
}

export const fromHTML =
  "<p>We <strong>hope</strong> this letter finds you <i>well</i>.</p><p>I just wanted to send you a short message to invite you to our open house next month. I will update you next week on sales activity in your neighbourhood for this month.</p><p>Since you also signed up for listing updates, you will receive a separate letter with the latest and greatest new listings on the market based on the criteria you selected.</p><p>&nbsp;</p><p>List:</p><ul><li>een</li><li>twee</li><li>drie</li></ul>"
export const toHTML =
  "<p>We <strong>hope</strong> that this letter finds you <b>well</b>.</p><p>I just wanted to send you a short message to invite you to our open house <b>next month</b>. I will update you next week on sales activity in your neighbourhood for this month.</p><p>Since you also signed up for listing updates, you will receive a separate letter with the latest and greatest new listings on the market based on the criteria you selected.</p><p>&nbsp;</p><p>List:</p><ul><li>een</li><li>twee</li><li>drie</li><li>extra</li></ul>"

export const priorityStyles = {
	critical: {
		icon: BiChevronsUp,
		style: {
			color: '#E33843',
			background: 'rgba(227, 56, 67, 0.20)',
		}
	},
	high: {
		icon: BiChevronUp,
		style: {
			color: '#E33843',
			background: 'rgba(227, 56, 67, 0.20)',
		}
	},
	medium: {
		icon: BiTransferAlt,
		style: {
			color: '#E79B04',
			background: 'rgba(254, 186, 49, 0.20)',
		}
	},
	low: {
		icon: BiChevronDown,
		style: {
			color: '#1ABC9C',
			background: 'rgba(26, 188, 156, 0.20)',
		}
	},
	verylow: {
		icon: BiChevronsDown,
		style: {
			color: '#1ABC9C',
			background: 'rgba(26, 188, 156, 0.20)',
		}
	},
}

export const statusStyles = {
	Submitted: {
		style: {
			background: 'rgba(65,121,164, .20)',
			color: '#41A1A4'
		}
	},
	Assigned: {
		style: {
			background: 'rgba(255,173,9, .15)',
			color: '#FFAD09'
		}
	},
	InProgress: {
		style: {
			background: 'rgba(65,121,164, .20)',
			color: '#41A1A4'
		}
	},
	Closed: {
		style: {
			color: '#2AC769',
			background: 'rgba(42,199,05, .15)',
		}
	},
	Resolved: {
		style: {
			color: '#2AC769',
			background: 'rgba(42,199,05, .15)',
		}
	},
	Reopened: {
		style: {
			color: '#E33843',
			background: 'rgba(227,56,64, .15)',
		}
	},
}