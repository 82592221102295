import React, { useEffect } from 'react'
import { Menu, MenuList, MenuItem } from '@chakra-ui/react'
import useEntity from '../apiHooks/agentBotConfig'
import _ from 'lodash'
const AutoSuggestion = ({ searchText, handleSendText, userInfo }) => {
  const lang = window && window.localStorage && window.localStorage.getItem('i18nextLng')

  const {
    data: cannedResp,
    isError,
    isFetching,
    isFetched: isFetchedCanned,
  } = useEntity({ reqPath: `CannedResponses?$filter=lang eq '${lang && lang.slice(0, 2)}' and status eq 'Published'`, resourceName: 'cannedResp' })

  const handleCannedTextChange = item => {
    handleSendText({ suggestedText: item })
  }
  let isHasKey = searchText && searchText.includes('#') ? true : false
  let tempValue = searchText && searchText.substring(searchText.lastIndexOf('#') + 1)
  let cannedData = cannedResp && cannedResp.map(c => c.properties?.text)
  cannedData = _.isEmpty(tempValue) ? cannedData : _.filter(cannedData, item => _.includes(_.toLower(item), _.toLower(tempValue)))
  let replaceDoubleBraces = (str, result) => {
    if (_.isEmpty(str)) return str
    return str.replace(/{{(.+?)}}/g, (_, g1) => result[g1] || g1)
  }

  cannedData =
    cannedData &&
    cannedData.map(item => {
      if (!userInfo || !userInfo.userName) return true
      let fullName = userInfo?.userName.split(' ')
      let sendText = item
      let userData = {}
      if (fullName && fullName.length > 0) {
        userData.firstName = fullName[0]
      }
      if (fullName && fullName.length > 1) {
        userData.lastName = fullName[1]
      }
      sendText = replaceDoubleBraces(sendText, userData)
      return sendText
    })

    useEffect(()=>{
      if (isHasKey && cannedData && cannedData.length === 1) {
        setTimeout(() => handleCannedTextChange(cannedData[0]), 1000)
      }
    },[isHasKey,cannedData])
    
  return (
      <Menu
        isOpen={isHasKey && cannedData && cannedData.length > 0 ? true : false}
        isLazy="true" matchWidth={'true'} padding={'5'} computePositionOnMount="true"
      >
        <MenuList
         height={'280px'}
         bottom="92px"
         overflow="auto"
         zIndex="9999"
         width="245px"
         fontSize={'12'}
        //  marginTop={'233px'}
         marginLeft={'33px'}
         bgColor={'#E2E8F0'}
         position={'fixed'}
        >
          {cannedData &&
            cannedData.map((item, index) => (
              <MenuItem key={index} onClick={handleCannedTextChange.bind(this, item)}>
                {item}
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
  )
}

export default AutoSuggestion
