import React, { useMemo, useState, useEffect, useCallback } from 'react'
import {
	Text,
	Switch,
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	useColorModeValue,
	Select,
	useToast,
} from '@chakra-ui/react'
import { useEntity, useUpdateEntity } from '../apiHooks/agentLiveChat'
import moment from 'moment'
import _ from 'lodash'
import InitialState from '../helpers/InitialState'
const INITIAL_AVAILABILITY = InitialState.intialAvailability
const AgentAvailability = props => {
	const modelTextHeading = `{t?t("Are you sure you want to change your availability"):"Are you sure you want to change your availability" }?`
	const {
		identity: { profile },
		incomingChatList,
		t,
	} = props
	const toast = useToast()
	const [flag, setFlag] = useState(false)
	const [availability, setAvailability] = useState(INITIAL_AVAILABILITY)
	const [agentInfo, setAgentInfo] = useState({})
	const [modalContent, setModalContent] = useState(modelTextHeading)
	const [modalSubmit, setModalSubmit] = useState(true)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { mutate, data: availabilityUpdate } = useUpdateEntity({ resourceName: 'AgentAvailability' })
	const { data: agentAvailability } = useEntity({
		reqPath: 'active_agents/getAgentAvailability',
		resourceName: 'agentAvailability',
		method: 'POST',
		payload: { agentId: profile.userId || profile.sub || null },
	})
	useEffect(() => {
		agentAvailability?.data && setAgentInfo(agentAvailability.data)
	}, [agentAvailability])
	useEffect(() => {
		availabilityUpdate?.data && setAgentInfo(availabilityUpdate.data)
	}, [availabilityUpdate])
	useEffect(() => {
		if (agentInfo.isActive) {
			props.handleAgentData(agentInfo)
			props.agentAvailability(true, agentInfo)
			setFlag(true)
			let canLogout = {
				logout: false,
				message: 'Your Availability is On, cannot Logout untill you turned Off your Availablity!',
				title: 'Invalid Logout',
			}
			localStorage.setItem('canLogout', JSON.stringify(canLogout))
		} else {
			setFlag(false)
			localStorage.removeItem('canLogout')
			props.agentAvailability(false)
		}
	}, [agentInfo, props])

	useEffect(() => {
		const { preferred_username, userId, sub } = profile
		setAvailability(prevState => ({
			...prevState,
			userName: preferred_username,
			agentId: userId || sub,
			metrics: {
				...prevState.metrics,
				agentName: preferred_username,
			},
		}))
	}, [profile])
	const onSubmit = useCallback(() => {
		if (!availability.agentId) availability.agentId = profile.userId || profile.sub
		mutate({
			entityUrlPaths: { url: `active_agents/setAgentAvailability`, method: 'POST' },
			payload: availability,
		})
		onClose()
	}, [mutate, onClose, availability])

	const onToggle = useCallback(() => {
		if (props.agentQueueList?.data?.length < 1 || props.agentQueueList?.length < 1) {
			toast({
				title: t ? t('Queue Not Available') : 'Queue Not Available',
				description: t ? t('You are currently not part of any queue') : 'You are currently not part of any queue',
				status: 'info',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
			return false
		}
		setModalContent(modelTextHeading)
		const nextAvailability = !flag
		if (nextAvailability) {
			setAvailability(prevState => ({
				...prevState,
				isActive: 1,
				metrics: {
					...prevState.metrics,
					isAvailable: true,
					reason: false,
					time: moment().format('YYYY-MM-DDTHH:mm:ss'),
				},
			}))
			setModalSubmit(true)
		} else if (!nextAvailability && agentAvailability && incomingChatList && incomingChatList.length > 0) {
			let activeChats = _.filter(incomingChatList, ic => ic.rowItem?.agentUid === agentAvailability.data?.agentId)
			if (activeChats && activeChats.length > 0) {
				setModalContent(`Your Have ${activeChats.length} Active chats on going, Please disconnect and try again.`)
				setModalSubmit(false)
			}
		} else setModalSubmit(true)

		onOpen()
	}, [flag, onOpen, incomingChatList, modelTextHeading, agentAvailability, t, props.agentQueueList])

	const onReasonChange = useCallback(reason => {
		setAvailability(prevState => ({
			...prevState,
			isActive: 0,
			metrics: {
				...prevState.metrics,
				isAvailable: false,
				reason,
				time: moment().format('YYYY-MM-DDTHH:mm:ss'),
			},
		}))
	}, [])
	return (
		<Box d="flex" justifyContent="right">
			<Text color="teal" fontSize="12px">
				{t ? t('Make yourself available') : 'Make yourself available'}
			</Text>
			<Switch ml={1} size="sm" isChecked={flag} onChange={onToggle} colorScheme="green" />
			{isOpen && (
				<AvailabilityModal
					onReasonChange={onReasonChange}
					onSubmit={onSubmit}
					isOpen={isOpen}
					onClose={onClose}
					onOpen={onOpen}
					content={modalContent}
					modalSubmit={modalSubmit}
					flag={flag}
					t={t}
				/>
			)}
		</Box>
	)
}

const AvailabilityModal = props => {
	const { isOpen, onClose, onSubmit, onReasonChange, flag, content, modalSubmit, t } = props
	const nextFlag = !flag
	const [reason, setReason] = useState(null)
	useEffect(() => {
		!_.isEmpty(reason) && onReasonChange(reason)
	}, [reason, onReasonChange])

	const onChange = useCallback(evt => {
		if (evt.target.value && evt.target.value != '') setReason(evt.target.value)
		else setReason(null)
	}, [])
	const { data: unavailabilityReason } = useEntity({
		reqPath: 'UnavailabilityReason/findAndCountWithAgent',
		resourceName: 'UnavailabilityReason',
		method: 'GET',
	})
	const btnColor = useColorModeValue('blue', 'blue.800')
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader p={2} align="center" borderBottom="thin solid #eaeaea">
						{t ? t('Availability') : 'Availability'}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{nextFlag && (
							<Text fontSize="14px" align="center">
								{t ? t('Are you sure you want to change your availability ?') : 'Are you sure you want to change your availability ?'}
							</Text>
						)}

						{!nextFlag && modalSubmit && (
							<Text fontSize="12px" align="center">
								{t ? t('Reason for Non Availability') : 'Reason for Non Availability'}
							</Text>
						)}
						{!nextFlag && modalSubmit && (
							<Select size="xs" placeholder={t ? t('Select Your Reason for Non Availability') : 'Select Your Reason for Non Availability'} onChange={onChange}>
								{unavailabilityReason?.length ? (
									unavailabilityReason?.map(list => (
										<option key={list.id} value={list.reason}>
											{list.reason}
										</option>
									))
								) : (
									<></>
								)}
							</Select>
						)}
						{!nextFlag && !modalSubmit && (
							<Text fontSize="12px" align="center">
								{t ? t(content) : content}
							</Text>
						)}
					</ModalBody>

					<ModalFooter>
						<Button size="sm" colorScheme={useColorModeValue('red', 'red.800')} mr={3} onClick={onClose}>
							{modalSubmit ? t('No') : t('Close')}
						</Button>
						{modalSubmit && (
							<Button size="sm" onClick={onSubmit} isDisabled={reason != null ? false : nextFlag ? false : true} colorScheme={btnColor}>
								{t ? t('Yes') : 'Yes'}
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default AgentAvailability
