import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
type IPropsType = {
  t: any,
  next: any,
  card: any
}
export const ActivityMiddleware = ({ t, next, card }: IPropsType) => {
  const {
    activity: { text, attachments, name, type, from, timestamp, channelData },
  } = card
  if (type === 'message' && (text || attachments)) {
    if (from.role !== 'user' && text?.startsWith('#')) {
      card.activity.text = text?.replace(/[#]/g, '&#35;')
    }
    let botNameWithTime =
      (from.name && from.sent === 'agent') || (from.role === 'user' && channelData)
        ? from.name || t('You')
        : from.name && from.role === 'user'
        ? t('You')
        : `${from.name === 'Anonymous user' || from.name === 'You' ? t('User') : from.name} ${t(`at`)} ${timestamp && new Date(timestamp).toLocaleTimeString()}`
    return (children: any) => {
      return (
        <Box
          className={
            from.sent === 'agent' || (from.role === 'user' && channelData)
              ? 'from-agent-message'
              : from.sent === 'bot'
              ? 'from-bot-message'
              : 'from-user-message'
          }
        >
          {next(card)(children)}
          <Flex
            className={from.role === 'bot' ? 'from-bot-botname' : 'from-user-botname'}
            justifyContent={from.role === 'bot' ? 'left' : 'right'}
            alignItems="center"
            p="2"
          >
            <Box>
              <Text fontSize="11px" color="#787878">{botNameWithTime}</Text>
            </Box>
          </Flex>
        </Box>
      )
    }
  } else return next(card)
}
