import React, { useState,useRef } from "react"
import { IconButton } from "@chakra-ui/react"
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  HamburgerIcon,
} from "@chakra-ui/icons"
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react"
import { Flex, Box } from "@chakra-ui/react"
// -------------------------------------------------------------------------------------------------

const TabWrapper = ({ children, slider,closeAllTabs,t}) => {
  
// tabId coming from agentContainer
const handleCloseTabs  =(tabId)=>{
  closeAllTabs(tabId)
}

  return (
    <Flex>
      {/* LEFT ARROW BUTTON */}
      <IconButton
        colorScheme="teal"
        variant="outline"
        size="sm"
        border={"none"}
        icon={<ArrowBackIcon />}
        onClick={() => {slider("left")}}
        margin= "0.5rem"
      />
      {/* TABWRAPPER ENCLOSED  TABS IN TABS.JSX , WHICH IS NOW USED AS CHILDREN OF THIS COMPONENT */}
      {children}

      {/* RIGHT ARROW BUTTON */}
      
      <IconButton
        colorScheme="teal"
        variant="outline"
        size="sm"
        border={"none"}
        icon={<ArrowForwardIcon />}
        onClick={() => {slider("right")}}
        margin= "0.5rem"
      />
      {/* MENU ICON */}
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          border={"none"}
          size="sm"
          margin= "0.5rem"
        />
        {/* increased z index of this as menu below it was overlapping it  */}
        <MenuList zIndex={1000}>
          {/* menu items functioning on onclick parameters coming from agentContainers */}
          <MenuItem onClick={handleCloseTabs.bind(this,"all")} fontSize={'12'}>{t?t("Close all tabs"):"Close all tabs"}</MenuItem> 
          <MenuItem onClick={handleCloseTabs.bind(this,"current")} fontSize={'12'}>{t?t("Close Current tab"):"Close Current tab"}</MenuItem>
          <MenuItem onClick={handleCloseTabs.bind(this,"keep_current")} fontSize={'12'}>{t?t("Close all but keep current tab"):"Close all but keep current tab"}</MenuItem>
          {/* <MenuItem>Close all tabs (expect unsaved tabs)</MenuItem> */}
        </MenuList>
      </Menu>
    </Flex>
  )
}

export default TabWrapper
