import { Roles } from '../../../../common/router'
const RolesMeta = ({ userRoles }) => {
  let outPermissions
  let permissions = {
    agent: {
      valumeBellIcon: false,
      agentAvailable: false,
      ticketList: false,
      livechatUsers: false,
    },
    agentSuperviser: {
      reasignChat: false,
      liveChatuserReadOnly: false,
    },
    ticketAgent: {
      ticketFilter: false,
    },
  }
  if (!userRoles || userRoles.length == 0) {
    outPermissions = { ...permissions.agent, ...permissions.agentSuperviser, ...permissions.ticketAgent }
    return outPermissions
  }

  if (userRoles.indexOf(Roles.LiveChatAgent) != -1) {
    permissions.agent.valumeBellIcon = true
    permissions.agent.agentAvailable = true
    permissions.agent.livechatUsers = true
    permissions.agent.agentFilter = true
  }
  if (userRoles.indexOf(Roles.LiveChatSuperviser) != -1) {
    permissions.agentSuperviser = { ...permissions.agent }
    permissions.agentSuperviser.liveChatuserReadOnly = true
    permissions.agentSuperviser.reasignChat = true
    permissions.agentSuperviser.agentAvailable = false
    permissions.agentSuperviser.valumeBellIcon = false
  }
  if (userRoles.indexOf(Roles.TicketAgent) != -1) {
    permissions.ticketAgent.ticketFilter = true
    permissions.ticketAgent.ticketList = true
  }
  if (userRoles.indexOf(Roles.TicketUser)!= -1) {
    permissions.ticketAgent.ticketFilter = true
    permissions.ticketAgent.ticketUserList = true
  }
  if(userRoles.indexOf(Roles.TicketSuperviser)!= -1){
    permissions.ticketAgent.ticketFilter = true
    permissions.ticketAgent.ticketList = true
    permissions.ticketAgent.ticketUserList = false
  }

  outPermissions = { ...permissions.agent, ...permissions.agentSuperviser, ...permissions.ticketAgent }
  return outPermissions
}
export { RolesMeta }
