import LogoMark from '../../../../assets/RezoleLogoMark.png'
import boopSfx from '../../../../assets/bell-notification.wav'
var url = window.location.href
var arr = url.split('/')
var domain = arr[0] + '//' + arr[2]
const askPermission = (isNotify = false) => {
  if (!('Notification' in window)) {
    return
  }
  if (Notification.permission === 'granted' && isNotify) {
    showNotification()
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(permission => {
      //   if (permission === 'granted') showNotification()
    })
  }
}

const showNotification = () => {
  let logoUrl = LogoMark.substring(1)
  let soundUrl = boopSfx.substring(1)
  var options = {
    body: 'Incoming Live chat request, Please review.',
    icon: `${domain}/agentlivechat${logoUrl}`,
    image: `${domain}/agentlivechat${logoUrl}`,
    vibrate: [100, 50, 100],
    sound: `${domain}/agentlivechat${soundUrl}`,
  }
  const notification = new Notification('Incoming Request', options)
  // setTimeout(notification.close.bind(notification), 7000)
}

export { askPermission, showNotification }
