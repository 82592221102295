import React, { useContext, useState, useCallback, useEffect, useMemo, useRef } from 'react'
import {
    Flex,
    Box,
    Wrap, WrapItem,
    Switch,
    useToast,
    Portal,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    Button,
    useDisclosure,
    Icon,
    Tooltip,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverHeader,
    PopoverCloseButton,
    PopoverBody,
    PopoverFooter,
    Textarea,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    MenuDivider,
    Avatar,
    Text,
    Link as ChakraLink,
    Image,
    Divider
} from '@chakra-ui/react'
import {
    NavLink
} from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { BiArrowFromLeft, BiArrowFromRight, BiEnvelope, BiHelpCircle, BiReset, BiStopCircle, BiTrash, BiUserCheck, BiSun, BiMoon } from 'react-icons/bi'
// import Logo from '../../assets/RezolveLogo-White.svg'
// import DarkLogo from '../../assets/logo.png'
import AppContext from 'AppContext'
import {
    ThemeContext
} from '../../../common/ui'
import { userSettingHooks } from '../../../common'
import CustomSwitch from './CustomSwitch'
const LogoMarkLight = 'https://as-cdn.azureedge.net/cdn/Rezolve-Monogram-Light.png'
const LogoMarkDark = 'https://as-cdn.azureedge.net/cdn/Rezolve-Monogram-Dark.png'
const Logo = 'https://as-cdn.azureedge.net/cdn/Rezolve-Logo-Light.png'
const DarkLogo = 'https://as-cdn.azureedge.net/cdn/Rezolve-Logo-Dark.png'
const Header = (props) => {
    const { identity, t, tenant, config,isExpanded,useUpdateEntity,permissions,isFeedback=false,isSwitchClassicLiveChat=false, isSwitchClassicTicket=false } = props
   
    const { profile } = identity
    const { isDarkMode, theme, toggleDarkMode } = useContext(ThemeContext)
    const { appData } = useContext(AppContext)
    const [toggleValue, setToggleValue] = useState(isDarkMode)
    const { useUpdateUserSetting } = userSettingHooks
    const { mutate, isSuccess } = useUpdateUserSetting()
    useEffect(()=>{
        setToggleValue(isDarkMode)
    },[isDarkMode])
    const onToggle = useCallback(() => {
        let payload = { tenantId: profile?.tenantUid, activeTheme: isDarkMode ? 'Light' : 'Dark', userId: profile?.userId, status: 'Published', entity: 'theme', filterName: 'theme' }
        mutate({ payload })
        toggleDarkMode()
        setToggleValue(!toggleValue)
    }, [toggleDarkMode, toggleValue, mutate, identity, isDarkMode])
    const toast = useToast()
    
    const uiFeature = useMemo(() => {
        if (appData && appData.global && appData.global.tenantSettings) {
            let filterFeature = appData.global.tenantSettings.find(x => x.configType === 'uiFeature' && x.name === 'tickets')
            return filterFeature.properties
        } else return null
    }, [appData])

    useEffect(() => {
        isSuccess && toast({
            title: t ? t("Theme updated") : ("Theme updated"),
            description: t ? t("Theme mark as active.") : ("Theme mark as active."),
            status: 'success',
            isClosable: true,
            position: "top-right"
        })
    }, [isSuccess, toast, t])

    const [uiFlag, setUiFlag] = useState(false)
    const uiFlagChange = useCallback(
        evt => {
            setUiFlag(prev => !prev)
            if (evt.target.checked && tenant && tenant.domain) {
                let redirectUrl = `https://${tenant.domain}/tickets/my-tickets`
                setTimeout(() => {
                    window.location = redirectUrl
                    localStorage.setItem('routeName','tickets')
                    localStorage.removeItem('chakra-ui-color-mode')
                }, 100)
            }
        },
        [tenant]
    )
    const [uiFlagLiveChat, setUiFlagLiveChat] = useState(false)
    const uiFlagChangeLiveChat = useCallback(
        evt => {
            setUiFlagLiveChat(prev => !prev)
            if (evt.target.checked && tenant && tenant.domain) {
                let redirectUrl = `https://${tenant.domain}/livechat/my-chats`
                setTimeout(() => {
                    window.location = redirectUrl
                    localStorage.setItem('routeName','liveChat')
                    localStorage.removeItem('chakra-ui-color-mode')
                }, 100)
            }
        },
        [tenant]
    )
    

    const { isOpen, onOpen, onClose } = useDisclosure()
    const LogoImg=useMemo(()=>{
        if(isExpanded){
            return theme?.isLight ? Logo : DarkLogo
        }
        else{
            return theme?.isLight ? LogoMarkLight : LogoMarkDark
        }
    },[theme,isExpanded])
    return (
        <Flex fontSize="13px"  bg={theme.isLight ? theme.bg2 : theme.bg1}
            color={theme.isLight ? theme.fg2 : theme.fg1}
            justifyContent="space-between"
            position="sticky"
            top={0}
            zIndex={99}
            boxShadow='sm'
            height="44px"
            className={`header${isExpanded?'':' collapse'}`}
        >
            <Box pl={'6px'} display="flex" className={`header-inner${isExpanded?'':' collapse'}`}>
                <Box p={2} pt={'10px'}  className={`header-logo${isExpanded?'':' collapse'}`}>
                    <ChakraLink to="/" as={NavLink}>
                        <Image
                            src={LogoImg} 
                            w={isExpanded ? "68%" : "100%"} 
                            mt={theme?.isLight ? 'inherit' : 3} 
                            maxHeight={'60px'}
                            overflow="hidden"
                        />
                    </ChakraLink>
                </Box>
            <Box>
            </Box>
    

           
            </Box>
           
            <Box p={2} className='header-section'>
                <CustomSwitch
defaultChecked={toggleValue}
data-testid='toggleTheme'
onChange={onToggle}
/>
                <Wrap>
                {isSwitchClassicTicket && (permissions.ticketList || permissions.ticketUserList) && uiFeature?.newUi?.toggleUi === 'true' && (
                    <WrapItem fontFamily={'Inter'}
                    fontSize={'13px'} alignItems='center' gap='2' pr='2' 
                    color={theme.isLight ? '#111827' : theme.fg1}
                    >
                        {t ? t('Switch to Classic') : 'Switch to Classic'}
                        <Switch
                            id='switchClassic'
                            ml={1}
                            size="sm"
                            isChecked={uiFlag}
                            value={!uiFlag}
                            onChange={uiFlagChange}
                        />
                    </WrapItem>
                )}

{isSwitchClassicLiveChat && (permissions.livechatUsers || permissions.liveChatuserReadOnly) && (
                    <WrapItem fontFamily={'Inter'}
                    fontSize={'13px'} alignItems='center' gap='2' pr='2' 
                    color={theme.isLight ? '#111827' : theme.fg1}
                    >
                        {t ? t('Switch to Classic') : 'Switch to Classic'}
                        <Switch
                            id='switchClassic'
                            ml={1}
                            size="sm"
                            isChecked={uiFlagLiveChat}
                            value={!uiFlagLiveChat}
                            onChange={uiFlagChangeLiveChat}
                        />
                    </WrapItem>
                )}

                    {isFeedback && (<WrapItem>
                        <Tooltip label={t ? t('Feedback') : 'Feedback'} shouldWrapChildren>
                            <Feedback 
                            {...props}
                            tenant={tenant} profile={identity?.profile || {}} userInfo={identity?.userInfo || {}} t={t} />
                            {/* <Icon as={BiEnvelope} boxSize={5} data-testid="Bihelp" onClick={onOpen} cursor="pointer"/> */}
                        </Tooltip>
                    </WrapItem>)}

                    {(permissions.ticketList || permissions.ticketUserList) && (permissions?.showHelpButton) && (
                    <WrapItem color="gray.500">
                        <Tooltip label='Help' shouldWrapChildren>
                            <Icon as={BiHelpCircle} boxSize={6} data-testid="Bihelp" onClick={onOpen} cursor="pointer" />
                        </Tooltip>
                        <Slider isOpen={isOpen} onOpen={onOpen} onClose={onClose} config={config} />
                    </WrapItem>
                    )}
                  <WrapItem>
                        <Menu>
                            <MenuButton
                                transition='all 0.2s'
                            >
                                <Box
                                    ml={1}
                                    display="flex"
                                    justifyContent={'space-between'}
                                    fontSize="12px"
                                    color={'#6c7280'}
                                >
                                    <Box>
                                        <Avatar name={`${profile?.name || profile?.preferred_username}`} size="xs" />
                                    </Box>
                                    <Box pl={1} pt={1}>{profile?.name || profile?.preferred_username}</Box>
                                    <Box pt={'3px'}>    <ChevronDownIcon boxSize={5} /></Box>

                                </Box>
                            </MenuButton>
                            <MenuList fontSize="14px">
                                <MenuItem>
                                    <ChakraLink as={ReactRouterLink} _hover={{textDecoration:'none'}} to='/logout'>
                                        {t?t('Logout '): 'Logout '}
                                    </ChakraLink>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </WrapItem>
                </Wrap>

            </Box>
        </Flex>
    )
}

export default Header



const Slider = ({ isOpen, onOpen, onClose, config }) => {
    const [slideIndex, setSlideIndex] = useState(1)
    const dataSlider = useMemo(
        () => [
            `agent-ui-step-1.jpg`,
            `agent-ui-step-2.jpg`,
            `agent-ui-step-3.jpg`,
            `agent-ui-step-4.jpg`,
            `agent-ui-step-5.jpg`,
            `agent-ui-step-6.jpg`,
            `agent-ui-step-7.jpg`,
            `agent-ui-step-8.jpg`,
            `agent-ui-step-9.jpg`,
        ],
        []
    )

    const nextSlide = useCallback(() => {
        if (slideIndex !== dataSlider.length) {
            setSlideIndex(slideIndex + 1)
        } else if (slideIndex === dataSlider.length) {
            setSlideIndex(1)
        }
    }, [slideIndex, dataSlider])

    const prevSlide = useCallback(() => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
        } else if (slideIndex === 1) {
            setSlideIndex(dataSlider.length)
        }
    }, [slideIndex, dataSlider])

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} size="full">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton
                        zIndex={9}
                        borderRadius={15}
                        bg="#ccc"
                        _hover={{
                            background: '#eaeaea',
                            color: 'teal.500',
                        }}
                    />
                    <ModalBody>
                        <Box className="container-slider">
                            {dataSlider.map((elem, index) => {
                                return (
                                    <Box key={index} className={slideIndex === index + 1 ? 'slide active-anim' : 'slide'}>
                                        <img alt={`pic-${index}`} title={elem} src={`${config?.cdnUri}/cdn/${elem}`} />
                                    </Box>
                                )
                            })}
                            <Button onClick={nextSlide} className={'btn-slide prev'} data-testid="nextSlide">
                                <BiArrowFromLeft />
                            </Button>

                            <Button onClick={prevSlide} className={'btn-slide next'} data-testid="prevSlide">
                                <BiArrowFromRight />
                            </Button>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Portal>
    )
}



const Feedback = ({ tenant, profile, userInfo, t, useUpdateEntity }) => {
    const initRef = React.useRef()
    const [feedbackInput, setFeedbackInput] = useState('')
    const toast = useToast()
    const inputRef = useRef()

    let feedbackAction = useUpdateEntity({ resourceName: 'feedbackPost' })
    const { mutate, isSuccess, isLoading, data, reset } = feedbackAction

    useEffect(() => {
        if (isSuccess) {
            toast({
                title: t ? t(`Feedback`) : 'Feedback',
                description: t(`Your Feedback is Submitted Successfully!`),
                status: `success`,
                duration: 5000,
                position: 'top-right',
                isClosable: true,
            })
            reset()
            setFeedbackInput('')
            inputRef.current.value = ''
        }
    }, [isSuccess, reset, toast, t])

    const feedbackChange = useCallback(e => {
        let inputVal = e.target.value
        setFeedbackInput(inputVal)
    }, [])

    const feedbackSubmit = useCallback(
        onClose => {
            let payload = {
                subject: `Agent UI - Feedback - ${profile?.tenantId} by ${profile?.preferred_username || profile?.name}`,
                feedback: feedbackInput,
                tenantName: profile?.tenantId || null,
                domain: tenant?.domain || null,
                user: profile?.preferred_username || profile?.name,
            }
            mutate({
                entityUrlPaths: {
                    url: `feedback`,
                    method: `POST`,
                },
                payload: payload,
            })
            setTimeout(() => {
                onClose()
            }, 1000)
        },
        [feedbackInput, profile, tenant, mutate]
    )

    return (
        <Popover initialFocusRef={initRef}>
            {({ isOpen, onClose }) => (
                <>
                    <PopoverTrigger>
                        <Button fontFamily='Inter' fontWeight="normal" borderRadius={'15px'} size="xs" variant="outline" leftIcon={<BiEnvelope />} colorScheme="blue" data-testid="feedback">
                            {t('Feedback')}
                        </Button>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent mr={5} mt={'6px'} fontSize="12px" color="gray">
                            <PopoverArrow />
                            <PopoverHeader>{t ? t('Please Share Your Feedback') : 'Please Share Your Feedback'}</PopoverHeader>
                            <PopoverCloseButton />
                            <PopoverBody>
                                <Textarea
                                    ref={inputRef}
                                    fontSize="12px"
                                    placeholder={t ? t('Enter Your Feedback') : 'Enter Your Feedback'}
                                    onChange={feedbackChange}
                                    resize="none"
                                    data-testid="yourFeedback"
                                />
                            </PopoverBody>
                            <PopoverFooter d="flex" justifyContent={'space-between'}>
                                <Box></Box>
                                <Box>
                                    <Button m={1} borderRadius={15} variant="outline" onClick={() => {
                                        onClose()
                                        reset()
                                    }} size="xs" data-testid="popupCancel">
                                        {t ? t('Cancel') : 'Cancel'}
                                    </Button>
                                    <Button
                                        data-testid="popupSubmit"
                                        m={1}
                                        borderRadius={15}
                                        variant="outline"
                                        colorScheme="green"
                                        size="xs"
                                        isLoading={isLoading}
                                        loadingText="Submitting.."
                                        onClick={feedbackSubmit.bind(this, onClose)}
                                        isDisabled={feedbackInput.trim().length == 0 ? true : false}>
                                        {t ? t('Submit') : 'Submit'}
                                    </Button>
                                </Box>
                            </PopoverFooter>
                        </PopoverContent>
                    </Portal>
                </>
            )}
        </Popover>
    )
}